<template>
    <q-dialog ref="dialog" @hide="onDialogHide">
        <q-card class="q-dialog-plugin" style="width: 700px;max-width: 80vw;">
            <q-card-section>
                <div class="row items-center q-col-gutter-x-sm">
                    <div class="col text-h6">
                        {{ titre }}
                    </div>
                    <div class="col-auto">
                        <q-btn icon="close" @click="onCancelClick" flat round dense />
                    </div>
                </div>
            </q-card-section>
            <q-card-section>
                <div v-if="peutAjouter" class="row items-center q-col-gutter-x-sm q-mb-md">
                    <div class="col">
                        <div class="row items-center q-col-gutter-x-sm">
                            <div class="col">
                                <q-file
                                    v-model="inputs"
                                    label="Cliquez ici pour sélectionner vos fichiers OU glissez vos fichiers ici"
                                    :disable="isLoadingAjouter"
                                    :max-files="maxFiles"
                                    outlined
                                    use-chips
                                    multiple
                                    dense
                                />
                            </div>
                            <div v-if="peutGetTypes" class="col-3">
                                <q-select
                                    v-model="idType"
                                    label="Type"
                                    option-value="id"
                                    option-label="nom"
                                    :options="types"
                                    :loading="isLoadingTypes"
                                    :disable="isLoadingAjouter"
                                    outlined
                                    hide-bottom-space
                                    emit-value
                                    map-options
                                    dense
                                    options-dense
                                />
                            </div>
                        </div>
                        <div v-if="avecDescription" class="row q-mt-sm">
                            <div class="col">
                                <q-input v-model="description" label="Description (facultatif)" outlined hide-bottom-space dense />
                            </div>
                        </div>
                    </div>
                    <div class="col-auto">
                        <q-btn icon="save" @click="ajouter" :loading="isLoadingAjouter" :disable="isLoadingTypes || !inputs.length || (peutGetTypes && !idType)" flat round dense>
                            <q-tooltip>Enregistrer les fichiers sélectionnés</q-tooltip>
                        </q-btn>
                    </div>
                </div>
                <template v-if="peutGet">
                    <q-list v-if="isLoadingFichiers" bordered separator dense>
                        <q-item v-for="n in 3" :key="n">
                            <q-item-section avatar>
                                <q-skeleton type="circle" size="1.7em" animation="fade" />
                            </q-item-section>
                            <q-item-section>
                                <q-item-label>
                                    <q-skeleton type="text" animation="fade" width="30%" />
                                </q-item-label>
                                <q-item-label caption>
                                    <q-skeleton type="text" animation="fade" width="10%" />
                                </q-item-label>
                            </q-item-section>
                            <q-item-section side>
                                <div class="row items-center q-col-gutter-x-sm">
                                    <div v-if="peutSupprimer" class="col-auto">
                                        <q-skeleton type="circle" size="1.7em" animation="fade" />
                                    </div>
                                    <div class="col-auto">
                                        <q-skeleton type="circle" size="1.7em" animation="fade" />
                                    </div>
                                </div>
                            </q-item-section>
                        </q-item>
                    </q-list>
                    <div v-else-if="!fichiers.length" class="text-italic">Aucun fichiers</div>
                    <q-list v-else bordered separator dense>
                        <e-fichier-item v-for="f in fichiers" :key="f.key" :fichier="f" :fct-supprimer="fctSupprimer" @on-fichier-supprime="onFichierSupprime(f)" />
                    </q-list>
                </template>
            </q-card-section>
        </q-card>
    </q-dialog>
</template>

<script>
import EFichierItem from "./EFichierItem.vue";

export default {
    name: "e-dialog-fichiers",
    props: {
        titre: String,
        maxFiles: {
            type: Number,
            default: 10
        },
        avecDescription: {
            type: Boolean,
            default: false
        },
        fctGet: Function,
        fctGetTypes: Function,
        fctAjouter: Function,
        fctSupprimer: Function
    },
    components: {
        EFichierItem
    },
    emits: ["ok", "hide"],
    data() {
        return {
            isLoadingFichiers: false,
            fichiers: [],
            isLoadingTypes: false,
            idType: null,
            types: [],
            isLoadingAjouter: false,
            inputs: [],
            description: null
        };
    },
    computed: {
        peutGet() {
            return typeof this.fctGet === "function";
        },
        peutGetTypes() {
            return typeof this.fctGetTypes === "function";
        },
        peutAjouter() {
            return typeof this.fctAjouter === "function";
        },
        peutSupprimer() {
            return typeof this.fctSupprimer === "function";
        }
    },
    methods: {
        show() {
            this.$refs.dialog.show();
        },
        hide() {
            this.$refs.dialog.hide();
        },
        onDialogHide() {
            this.$emit("hide");
        },
        onOKClick() {
            this.$emit("ok");
            this.hide();
        },
        onCancelClick() {
            this.hide();
        },
        onFichierSupprime(fichier) {
            this.fichiers = this.fichiers.filter((x) => x.key !== fichier.key);
        },
        async get() {
            this.isLoadingFichiers = true;

            try {
                this.fichiers = await this.fctGet();
            } catch (error) {
                this.$q.notify({ type: "negative", message: "Une erreur est survenue lors du chargement des fichiers." });
            } finally {
                this.isLoadingFichiers = false;
            }
        },
        async getTypes() {
            this.isLoadingTypes = true;

            try {
                this.types = await this.fctGetTypes();
            } catch (error) {
                this.$q.notify({ type: "negative", message: "Une erreur est survenue lors du chargement des types de fichier." });
            } finally {
                this.isLoadingTypes = false;
            }
        },
        async ajouter() {
            this.isLoadingAjouter = true;

            try {
                const fichiersAjoutes = await this.$api.fichiers.upload(this.inputs),
                    results = await this.fctAjouter(fichiersAjoutes, this.idType, (this.description || "").trim() || null);

                this.inputs = [];

                if (this.peutGet) {
                    this.idType = null;
                    this.description = null;

                    this.$q.notify({ type: "positive", message: "Fichier(s) enregistré(s) avec succès." });
                    this.get();
                } else {
                    this.$emit("ok", { fichierResult: fichiersAjoutes, fctAjouterResult: results, idType: this.idType || null, description: (this.description || "").trim() || null });
                    this.hide();
                }
            } catch (error) {
                this.$q.notify({ type: "negative", message: "Une erreur est survenue lors de l'enregistrement des fichiers." });
            } finally {
                this.isLoadingAjouter = false;
            }
        }
    },
    mounted() {
        if (this.peutGet) {
            this.get();
        }
        if (this.peutAjouter && this.peutGetTypes) {
            this.getTypes();
        }
    }
};
</script>

<style scoped>
.q-item__section--avatar {
    min-width: inherit;
}
.q-item__label + .q-item__label {
    margin-top: 0;
}
</style>
