export default (api) => ({
    getTypesObjectif: () => api.post("api/generiques/stored-procedure/getTypesObjectif").then((r) => r.data[0]),
    getVendeursAvecObjectifsAnnee: ({ idSite = null, annee = null, actifSeulement = 0 } = {}) =>
        api.post("api/generiques/stored-procedure/GetVendeursAvecObjectifsAnnee", { idSite, annee, actifSeulement }).then((r) => r.data[0]),
    getObjectifAnnuelEmploye: ({ idEmploye = null, annee = null } = {}) => api.post("api/generiques/scalar-function/GetObjectifAnnuelEmploye", { idEmploye, annee }).then((r) => r.data),
    getObjectifsSite: ({ idSite = null, idService = null, idTypeObjectif = null, annee = null, idCompagnie = null, formatterMontant = true } = {}) =>
        api
            .post("api/generiques/stored-procedure/GetObjectifsSite", { idSite, idService, idTypeObjectif, annee, idCompagnie, formatterMontant })
            .then((r) => ({ objectifs: r.data[0], totaux: r.data[1][0] })),
    getObjectifsEmployeAnnee: ({ idEmploye = null, idPosteEmploye = null, idTypeObjectif = null, annee = null, formatterMontant = true } = {}) =>
        api.post("api/generiques/stored-procedure/GetObjectifsEmployeAnnee", { idEmploye, idPosteEmploye, idTypeObjectif, annee, formatterMontant }).then((r) => r.data[0]),
    getObjectifsEmployesSiteMois: ({ idSite = null, mois = null, annee = null, idTypeObjectif = null } = {}) =>
        api.post("api/generiques/stored-procedure/GetObjectifsEmployesSiteMois", { idSite, mois, annee, idTypeObjectif }).then((r) => r.data[0]),
    modifierObjectifVenteMoyenneEmploye: ({ idEmploye = null, annee = null, valeurMoyenneVente = null } = {}) =>
        api.post("api/generiques/stored-procedure/ModifierObjectifVenteMoyenneEmploye", { idEmploye, annee, valeurMoyenneVente }),
    modifierObjectifMoisEmploye: ({ idEmploye = null, idPosteEmploye = null, idTypeObjectif = null, annee = null, mois = null, valeurObjectif = null } = {}) =>
        api.post("api/generiques/stored-procedure/ModifierObjectifMoisEmploye", { idEmploye, idPosteEmploye, idTypeObjectif, annee, mois, valeurObjectif }),
    ajoutObjectifSite: ({ idObjectifSite = null, objectif = null, objectifDollard = null } = {}) =>
        api.post("api/generiques/stored-procedure/AjoutObjectifSite", { idObjectifSite, objectif, objectifDollard })
});
