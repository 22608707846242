<template>
    <div class="row q-pa-md q-col-gutter-md">
        <q-input :model-value="noContrat" dense filled label="Numéro de contrat" class="col-6" @update:model-value="changeNoContrat" @keyup.enter="onKeyupEnter">
            <template v-slot:prepend>
                <q-icon name="tag" size="sm"></q-icon>
            </template>
        </q-input>

        <q-input :model-value="prenom" dense filled label="Prénom du client" class="col-6" @update:model-value="changePrenom" @keyup.enter="onKeyupEnter" />

        <q-input :model-value="noModele" dense filled label="Numéro de modèle" class="col-6" @update:model-value="changeNoModele" @keyup.enter="onKeyupEnter">
            <template v-slot:prepend>
                <q-icon name="tag" size="sm"></q-icon>
            </template>
        </q-input>

        <q-input :model-value="nom" dense filled label="Nom du client" class="col-6" @update:model-value="changeNom" @keyup.enter="onKeyupEnter" />

        <q-input :model-value="noUnite" dense filled label="Numéro d'unité" class="col-6" @update:model-value="changeNoUnite" @keyup.enter="onKeyupEnter">
            <template v-slot:prepend>
                <q-icon name="tag" size="sm"></q-icon>
            </template>
        </q-input>

        <q-input :model-value="vendeur" dense filled label="Vendeur" class="col-6" @update:model-value="changeVendeur" @keyup.enter="onKeyupEnter" />

        <q-input :model-value="bureau" dense filled label="Bureau" class="col-6" @update:model-value="changeBureau" @keyup.enter="onKeyupEnter" />

        <q-input :model-value="approbateur" dense filled label="Approbateur" class="col-6" @update:model-value="changeApprobateur" @keyup.enter="onKeyupEnter">
            <template v-slot:prepend>
                <q-icon name="done" size="sm"></q-icon>
            </template>
        </q-input>

        <q-input :model-value="dessinateur" dense filled label="Dessinateur" class="col-12" @update:model-value="changeDessinateur" @keyup.enter="onKeyupEnter">
            <template v-slot:prepend>
                <q-icon name="architecture" size="sm"></q-icon>
            </template>
        </q-input>

        <q-input
            :model-value="autreIntervenant"
            dense
            filled
            label="Autre intervenant ( ex. nom d'un ICQ )"
            class="col-12"
            @update:model-value="changeAutreIntervenant"
            @keyup.enter="onKeyupEnter"
        />

        <q-input :model-value="adresse" dense filled label="Adresse" class="col-12" @update:model-value="changeAdresse" @keyup.enter="onKeyupEnter">
            <template v-slot:prepend>
                <q-icon name="place" size="sm"></q-icon>
            </template>
        </q-input>

        <q-input :model-value="notesPlan" dense filled label="Notes de plan" class="col-12" @update:model-value="changeNotesPlan" @keyup.enter="onKeyupEnter">
            <template v-slot:prepend>
                <q-icon name="notes" size="sm"></q-icon>
            </template>
        </q-input>

        <TagList
            label="Caractéristiques du plan"
            buttonText="Afficher les caractéristiques"
            triggerEvent="ouvrirCaracteristiquesPlan"
            :caracteristiques-plan="caracteristiquesPlan"
            class="col-12"
            @ouvrir-caracteristiques-plan="$emit('ouvrirCaracteristiquesPlan')"
            @update:caracteristiquesPlan="changeCaracteristiquesPlan"
        >
        </TagList>
    </div>
</template>

<script>
import TagList from "./TagList.vue";

export default {
    name: "e-recherche-avancee-contrat",
    components: { TagList },
    props: {
        noContrat: String,
        prenom: String,
        noModele: String,
        nom: String,
        noUnite: String,
        vendeur: String,
        bureau: String,
        approbateur: String,
        dessinateur: String,
        autreIntervenant: String,
        adresse: String,
        notesPlan: String,
        caracteristiquesPlan: Array
    },
    emits: [
        "update:noContrat",
        "update:prenom",
        "update:noModele",
        "update:nom",
        "update:noUnite",
        "update:vendeur",
        "update:bureau",
        "update:approbateur",
        "update:dessinateur",
        "update:autreIntervenant",
        "update:adresse",
        "update:notesPlan",
        "update:caracteristiquesPlan",
        "keyup.enter",
        "ouvrirCaracteristiquesPlan"
    ],
    methods: {
        changeNoContrat(noContrat) {
            this.$emit("update:noContrat", noContrat.trim());
        },
        changePrenom(prenom) {
            this.$emit("update:prenom", prenom.trim());
        },
        changeNoModele(noModele) {
            this.$emit("update:noModele", noModele.trim());
        },
        changeNom(nom) {
            this.$emit("update:nom", nom.trim());
        },
        changeNoUnite(noUnite) {
            this.$emit("update:noUnite", noUnite.trim());
        },
        changeVendeur(vendeur) {
            this.$emit("update:vendeur", vendeur.trim());
        },
        changeBureau(bureau) {
            this.$emit("update:bureau", bureau.trim());
        },
        changeApprobateur(approbateur) {
            this.$emit("update:approbateur", approbateur.trim());
        },
        changeDessinateur(dessinateur) {
            this.$emit("update:dessinateur", dessinateur.trim());
        },
        changeAutreIntervenant(autreIntervenant) {
            this.$emit("update:autreIntervenant", autreIntervenant.trim());
        },
        changeAdresse(adresse) {
            this.$emit("update:adresse", adresse.trim());
        },
        changeNotesPlan(notesPlan) {
            this.$emit("update:notesPlan", notesPlan.trim());
        },
        changeCaracteristiquesPlan(caracteristiquesPlan) {
            this.$emit("update:caracteristiquesPlan", caracteristiquesPlan);
        },
        onKeyupEnter() {
            this.$emit("keyup.enter");
        }
    }
};
</script>
