export default (api) => ({
    getTachesUsagerAgenda: ({ dateDebut = null, dateFin = null, estEnRetard = null } = {}) =>
        api.post("api/generiques/stored-procedure/GetTachesUsagerAgenda", { dateDebut, dateFin, estEnRetard }).then((r) => r.data[0]),
    supprimerTachesJournalieresUsager: ({ idTacheJournaliereUsager = null } = {}) =>
        api.post("api/generiques/stored-procedure/SupprimerTachesJournalieresUsager", { idTacheJournaliereUsager }),
    ajouterTacheUsagerAgenda: ({
        nom = null,
        dateTacheDe = null,
        dateTacheA = null,
        journeeEntiere = false,
        idTypeTacheJournaliere = null,
        idTacheJournalierePreDefinie = null,
        idGroupeProjetPropose = null,
        idContrat = null,
        description = null,
        idSuivi = null,
        envoyerCourriel = false
    } = {}) =>
        api
            .post("api/generiques/stored-procedure/AjouterTacheUsagerAgenda", {
                nom,
                dateTacheDe,
                dateTacheA,
                journeeEntiere,
                idTypeTacheJournaliere,
                idTacheJournalierePreDefinie,
                idGroupeProjetPropose,
                idContrat,
                description,
                idSuivi,
                envoyerCourriel
            })
            .then((r) => r.data[0][0].idTache),
    getTypesTachesJournalieres: () => api.post("api/generiques/stored-procedure/GetTypesTachesJournalieresV2").then((r) => r.data[0]),
    rechercherInviteTacheJournaliere: ({ texte = null, type = 1 } = {}) =>
        api.post("api/generiques/stored-procedure/RechercherInviteTacheJournaliereV2", { texte, type }).then((r) => r.data[0]),
    ajoutInviteTacheJournaliere: ({ idTacheJournaliereUsager = null, idUsager = null, courriel = null, idCompteur = null, idCompagnieProspect = null } = {}) =>
        api.post("api/generiques/stored-procedure/AjoutInviteTacheJournaliere", { idTacheJournaliereUsager, idUsager, courriel, idCompteur, idCompagnieProspect }),
    getInvitesTacheJournaliere: ({ idTacheJournaliereUsager = null } = {}) =>
        api.post("api/generiques/stored-procedure/GetInvitesTacheJournaliereV2", { idTacheJournaliereUsager }).then((r) => r.data[0]),
    modifierTacheJournaliereUsager: ({ idTacheJournaliereUsager = null, nom = null, description = null, idTypeTacheJournaliere = null } = {}) =>
        api.post("api/generiques/stored-procedure/ModifierTacheJournaliereUsagerV2", { idTacheJournaliereUsager, nom, description, idTypeTacheJournaliere }),
    coucherTachesJournalieresUsager: ({ idTacheJournaliereUsager = null } = {}) =>
        api.post("api/generiques/stored-procedure/CoucherTachesJournalieresUsager", { idTacheJournaliereUsager }),
    getAgendaUsager: () => api.post("api/generiques/stored-procedure/GetAgendaUsager").then((r) => r.data[0]),
    estVendeurBloque: ({ idCompagnieUsager = null } = {}) => api.post("api/generiques/scalar-function/EstVendeurBloqueERP", { idCompagnieUsager }).then((r) => r.data)
});
