<template>
    <q-dialog ref="dialog" @hide="onDialogHide" transition-show="none" transition-hide="none">
        <q-card class="q-dialog-plugin" style="width: 90%; max-width: 90vw; max-height: 95vh">
            <q-inner-loading :showing="isLoading" class="z-top">
                <q-spinner size="50px" />
            </q-inner-loading>

            <div class="flex flex-center">
                <q-btn icon="date_range" @click="isDateDialogOpen = true" flat rounded dense style="width: 20vw" label="Choisir une semaine"> </q-btn>
            </div>

            <div class="q-pa-md">
                <full-calendar ref="calendar" :options="calendarOptions">
                    <template #eventContent="arg">
                        <div class="column overflow-hidden full-height cursor-pointer" :class="{ 'text-strike': arg.event.extendedProps.isCompleted }" @click="onEventClick(arg)">
                            <div class="col-auto text-xsmall">{{ arg.timeText }}</div>
                            <div class="col text-small">{{ arg.event.extendedProps.nomProspect ? arg.event.extendedProps.nomProspect + " : " : "" }}{{ arg.event.title }}</div>
                        </div>
                    </template>
                </full-calendar>
            </div>
        </q-card>
        <q-dialog v-model="isDateDialogOpen" transition-show="scale" transition-hide="scale">
            <q-card>
                <q-card-section>
                    <q-date v-model="selectedDate" mask="YYYY-MM-DD" :default-year-month="defaultYearMonth" @update:model-value="onDateSelected" today-btn />
                </q-card-section>
                <q-card-actions align="right">
                    <q-btn flat label="Fermer" @click="isDateDialogOpen = false" />
                </q-card-actions>
            </q-card>
        </q-dialog>
    </q-dialog>
</template>

<script>
import { date } from "quasar";
const { formatDate } = date;

import FullCalendar from "@fullcalendar/vue3";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

export default {
    name: "e-dialog-agenda-usager",
    components: {
        FullCalendar
    },
    emits: ["ok", "hide"],
    props: {
        etatGroupe: Object
    },
    data() {
        return {
            isLoading: false,
            selectedDate: new Date().toISOString().substring(0, 10), // Initialiser avec la date actuelle
            defaultYearMonth: new Date().toISOString().substring(0, 7).replace("-", "/"), // Initialiser avec l'année et le mois actuels
            isDateDialogOpen: false,
            calendarOptions: {
                plugins: [timeGridPlugin, interactionPlugin],
                initialView: "timeGridWeek",
                validRange: () => {
                    let auj = new Date(),
                        end = null;

                    switch (this.etatGroupe.nom) {
                        case "0-3m":
                            end = new Date().setMonth(new Date().getMonth() + 3);
                            break;
                        case "3-6m":
                            end = new Date().setMonth(new Date().getMonth() + 6);
                            break;
                        case "6-12m":
                            end = new Date().setMonth(new Date().getMonth() + 12);
                            break;
                        case "12m+":
                            end = new Date().setMonth(new Date().getMonth() + 12);
                            break;
                    }

                    return { start: auj, end };
                },
                allDaySlot: false,
                slotMinTime: "07:00:00",
                slotMaxTime: "21:00:00",
                locale: "fr-ca",
                nowIndicator: true,
                height: "calc(90vh - 32px)",
                slotDuration: "00:15:00",
                buttonText: {
                    today: "Aujourd'hui"
                },
                dateClick: this.onDateClick,
                events: []
            }
        };
    },
    methods: {
        show() {
            this.$refs.dialog.show();
        },
        hide() {
            this.$refs.dialog.hide();
        },
        onDialogHide() {
            this.$emit("hide");
        },
        async getEvents() {
            this.isLoading = true;

            try {
                this.calendarOptions.events = await this.$api.taches.getAgendaUsager();
            } catch (error) {
                this.$q.notify({ type: "negative", message: "Une erreur est survenue lors du chargement des tâches." });
            } finally {
                this.isLoading = false;
            }
        },
        onDateClick(arg) {
            if (arg.date <= new Date()) {
                this.$q.notify({ type: "negative", message: "Vous ne pouvez pas sélectionner une date dans le passé." });
            } else {
                this.$emit("ok", arg.date);
                this.hide();
            }
        },
        onEventClick(arg) {
            this.isDateDialogOpen = false;
            this.$q.dialog({
                message: `
                    <div><b>Tâche: </b>${arg.event.title}</div>
                    <div><b>Début: </b>${formatDate(arg.event.start, "YYYY-MM-DD HH:mm")}</div>
                    ${arg.event.end ? `<div><b>Fin: </b>${formatDate(arg.event.end, "YYYY-MM-DD HH:mm")}</div>` : ""}
                    ${arg.event.extendedProps.nomProspect ? `<div><b>Client(s): </b>${arg.event.extendedProps.nomProspect}</div>` : ""}
                    ${arg.event.extendedProps.numContrat ? `<div><b>Contrat: </b>#${arg.event.extendedProps.numContrat}</div>` : ""}
                `,
                html: true,
                style: "width: auto",
                ok: false
            });
        },
        onDateSelected(date) {
            this.selectedDate = date;
            let calendarApi = this.$refs.calendar.getApi();

            calendarApi.gotoDate(this.selectedDate);
            this.isDateDialogOpen = false;
        }
    },
    mounted() {
        this.getEvents();
    }
};
</script>
