<template>
    <q-field stack-label readonly filled class="col-12" :label="label">
        <template v-slot:prepend>
            <q-icon name="label" size="sm"></q-icon>
        </template>

        <template v-slot:append>
            <q-btn color="primary" @click="$emit(triggerEvent)">{{ buttonText }}</q-btn>
        </template>

        <template v-slot:control>
            <div>
                <div class="tag" v-for="(tag, index) in tagsChoisis" :key="index">
                    {{ tag.Nom }}
                    <button class="small-delete" @click="supprimer(index)">x</button>
                </div>
            </div>
        </template>
    </q-field>
</template>

<script>
export default {
    name: "TagList",
    props: {
        label: String,
        buttonText: String,
        triggerEvent: String,
        caracteristiquesPlan: Array
    },
    emits: ["ouvrirCaracteristiquesPlan", "update:caracteristiquesPlan"],
    methods: {
        supprimer(index) {
            var nouvellesCaracteristiques = this.caracteristiquesPlan.slice();
            nouvellesCaracteristiques.splice(index, 1);
            this.tagsChoisis = nouvellesCaracteristiques;
        }
    },
    computed: {
        tagsChoisis: {
            get() {
                return this.caracteristiquesPlan;
            },
            set(value) {
                this.$emit("update:caracteristiquesPlan", value);
            }
        }
    }
};
</script>

<style scoped>
.tag {
    display: inline-block;
    height: 27px;
    background-color: rgb(50, 115, 220);
    color: white;
    margin: 2px;
    padding: 1px;
    border: thin solid black;
    border-radius: 5%;
}
.small-delete {
    border-radius: 15%;
    font-size: 8px;
}
</style>
