<template>
    <router-view />
</template>

<script>
export default {
    name: "App"
};
</script>

<style>
/* QTable sticky header pour le fixed header */
.q-table-sticky-header .q-table__top,
.q-table-sticky-header .q-table__bottom,
.q-table-sticky-header thead tr:first-child th {
    background-color: #fff;
}
.q-table-sticky-header thead tr th {
    position: sticky;
    z-index: 1;
}
.q-table-sticky-header thead tr:first-child th {
    top: 0;
}
.q-table-sticky-header thead tr:last-child:not(:first-child) th {
    top: 48px;
}
.q-table-sticky-header.q-table--dense thead tr:last-child:not(:first-child) th {
    top: 28px;
}

/* Pour les colonnes avec width défini */
.q-table-ellipsis table {
    table-layout: fixed;
}
.q-table-ellipsis tr:first-child th:not(.no-ellipsis):not(.q-table--col-auto-width),
.q-table-ellipsis tr td:not(.no-ellipsis):not(.q-table--col-auto-width) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.q-table-ellipsis tr:first-child th.sortable:hover:not(.no-ellipsis):not(.q-table--col-auto-width),
.q-table-ellipsis tr:first-child th.sortable.sorted:not(.no-ellipsis):not(.q-table--col-auto-width) {
    padding-right: 22px;
}
.q-table-ellipsis tr:first-child th.sortable:not(.no-ellipsis):not(.q-table--col-auto-width) .q-table__sort-icon {
    position: absolute;
    right: 8px;
    top: 7px;
    margin: 0;
}

/* Élément seulement affiché lors que le curseur est sur le parent */
tr:not(:hover) .action-hover-only,
div.q-item:not(:hover) .action-hover-only,
div.q-tree__node-header:not(:hover) .action-hover-only {
    opacity: 0;
}

/* Classe pour appliquer un background gris à un q-tree */
.tree-header-background-grey .q-tree__node-header {
    background-color: rgb(248, 248, 248);
}

/* Petit texte */
.text-small {
    font-size: 12px !important;
    line-height: initial !important;
}
.text-xsmall {
    font-size: 10px !important;
    line-height: initial !important;
}

/* Permet de réduire l'espace entre les icones et le texte dans les contextes menus */
.q-menu .q-item__section--avatar {
    min-width: inherit;
}

/* Permet de masquer les boutons à la fin d'un input type number */
input[type="number"].hide-step-buttons::-webkit-outer-spin-button,
input[type="number"].hide-step-buttons::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type="number"].hide-step-buttons {
    -moz-appearance: textfield;
}

/* Borders */
.border-all {
    border: 1px solid rgba(0, 0, 0, 0.12);
}
.border.left {
    border-left: 1px solid rgba(0, 0, 0, 0.12);
}
.border.right {
    border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.border.top {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.border.bottom {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

/* Classe pour flasher un élément */
.blink {
    animation: blinker 2s linear infinite;
}
@keyframes blinker {
    50% {
        opacity: 0.1;
    }
}

/* Classe pour souligner du texte */
.text-underline {
    text-decoration: underline !important;
}

/* Classe pour afficher un curseur pour drag un élément */
.cursor-drag {
    cursor: grab !important;
}
.cursor-drag:active {
    cursor: grabbing !important;
}

.q-dialog-size-sm {
    width: 50% !important;
    max-width: 50vw !important;
}

.q-dialog-size-md {
    width: 60% !important;
    max-width: 60vw !important;
}

.q-dialog-size-lg {
    width: 70% !important;
    max-width: 70vw !important;
}

.q-dialog-size-xl {
    width: 80% !important;
    max-width: 80vw !important;
}

/* Pour activer les break-line dans une dialog de quasar sans custom component */
.q-dialog-pre-line > .q-dialog__message {
    white-space: pre-line;
}
</style>
