export default (api) => ({
    getCommandeBudgetNonComptabilise: ({ idCompagnie = null, st = null } = {}) =>
        api
            .post("api/generiques/stored-procedure/GetCommandeBudgetNonComptabiliseV2", {
                idCompagnie,
                st
            })
            .then((r) => r.data[0]),
    enregistrerCommandeMaximum: ({ idCommande = null, numCommandeMaximum = null, dateCommandeMaximum = null } = {}) =>
        api.post("api/generiques/stored-procedure/AjoutNumCommandeMaximum", {
            idCommande,
            numCommandeMaximum,
            dateCommandeMaximum
        }),
    getModificationCommandeBudget: ({ idCommande = null } = {}) =>
        api
            .post("api/generiques/stored-procedure/GetModificationCommandeBudgetV2", {
                idCommande
            })
            .then((r) => r.data[0]),
    getInfoFournisseur: ({ idFournisseur = null } = {}) =>
        api
            .post("api/generiques/stored-procedure/GetInfoFournisseurV2", {
                idFournisseur
            })
            .then((r) => r.data[0]),
    confirmeModificationCommandeMaximum: ({ idCommande = null, dateModificationMaximum = null } = {}) =>
        api.post("api/generiques/stored-procedure/ConfirmeModificationCommandeMaximum", {
            idCommande,
            dateModificationMaximum
        }),
    confirmeAnnulerCommandeMaximum: ({ idCommande = null, dateAnnulationMaximum = null } = {}) =>
        api.post("api/generiques/stored-procedure/ConfirmeAnnulerCommandeMaximum", {
            idCommande,
            dateAnnulationMaximum
        }),
    importationPaiesConseillers: ({ code = null, dateVersement = null, montant = null } = {}) =>
        api.post("api/generiques/stored-procedure/ImportationPaiesConseillers", {
            code,
            dateVersement,
            montant
        }),
    getSommaireApprobation: ({ idContrat = null } = {}) =>
        api
            .post("api/generiques/stored-procedure/GetSommaireApprobationV2", {
                idContrat
            })
            .then((r) => r.data[0]),
    getNbHeureUsineProjet: ({ idContrat = null } = {}) =>
        api
            .post("api/generiques/scalar-function/getNbHeureUsineProjetV2", {
                idContrat
            })
            .then((r) => r.data),
    getFacturesPromosContrat: ({ idContrat = null } = {}) =>
        api
            .post("api/generiques/stored-procedure/GetFacturesPromosContratV2", {
                idContrat
            })
            .then((r) => r.data[0]),
    getSoumissionsContrat: ({ idContrat = null } = {}) =>
        api
            .post("api/generiques/stored-procedure/GetSoumissionsContratV2", {
                idContrat
            })
            .then((r) => r.data[0]),
    getFactureApresLivraison: ({ idContrat = null } = {}) =>
        api
            .post("api/generiques/stored-procedure/GetFactureApresLivraisonV2", {
                idContrat
            })
            .then((r) => r.data[0]),
    getDetailsFacturePromo: ({ idFacture = null } = {}) =>
        api
            .post("api/generiques/stored-procedure/GetDetailsFacturePromoV2", {
                idFacture
            })
            .then((r) => r.data[0]),
    getDetailsFacture: ({ idFacture = null } = {}) =>
        api
            .post("api/generiques/stored-procedure/GetDetailsFactureV2", {
                idFacture
            })
            .then((r) => r.data[0]),
    GetDetailFactureLivraison: ({ idFacture = null } = {}) =>
        api
            .post("api/generiques/stored-procedure/GetDetailFactureLivraisonV2", {
                idFacture
            })
            .then((r) => r.data[0]),
    getCommandesTcost: () => api.post("api/generiques/stored-procedure/GetCommandesTcost").then((r) => r.data[0]),
    getRequetesEtiquette: ({
        idCompagnie = null,
        afficherDate = null,
        valDate = null,
        noContrat = null,
        clients = null,
        cond = null,
        vendeur = null,
        langueContrat = null,
        genreClients = null
    } = {}) =>
        api
            .post("api/generiques/stored-procedure/getRequetesEtiquette", {
                idCompagnie,
                afficherDate,
                valDate,
                noContrat,
                clients,
                cond,
                vendeur,
                langueContrat,
                genreClients
            })
            .then((r) => r.data[0]),
    getAdresseResidentielEtiquette: ({ idContrat = null } = {}) =>
        api
            .post("api/generiques/stored-procedure/getAdresseResidentielEtiquette", {
                idContrat
            })
            .then((r) => r.data[0][0]),
    getAdresseConstructionEtiquette: ({ idContrat = null, idTypeAdresse = 7 } = {}) =>
        api
            .post("api/generiques/stored-procedure/getAdresseConstructionEtiquette", {
                idContrat,
                idTypeAdresse
            })
            .then((r) => r.data[0][0]),
    getCategoriesBudgetsLivraison: ({ idContrat = null } = {}) =>
        api
            .post("api/generiques/stored-procedure/GetCategoriesBudgetsLivraison", {
                idContrat
            })
            .then((r) => r.data[0])
});
