<template>
    <q-dialog ref="dialog" @hide="onDialogHide">
        <q-card class="q-dialog-plugin" style="width: 900px;max-width: 90vw;">
            <q-card-section>
                <div class="text-h6">Contrats sans Inspecteur</div>
            </q-card-section>
            <q-card-section>
                <div class="text-caption">Veuillez sélectionner un Inspecteur pour les contrats ci-dessous. Vous pourrez ensuite poursuivre dans l'ERP.</div>
                <q-table
                    color="primary"
                    class="q-table-sticky-header"
                    row-key="idContrat"
                    :columns="columns"
                    :rows="contrats"
                    :rows-per-page-options="[0]"
                    :pagination-label="(firstRowIndex, endRowIndex, totalRowsNumber) => `${totalRowsNumber} contrat(s) sans Inspecteur`"
                    :loading="isLoading"
                    hide-no-data
                    dense
                    @row-click="onRowClick"
                />
            </q-card-section>
        </q-card>
        <q-dialog v-model="dialogEmployes" persistent>
            <q-card>
                <q-card-section>
                    <div class="text-h6">Sélectionnez un Inspecteur</div>
                </q-card-section>

                <q-card-section class="q-pt-none">
                    <q-select v-model="employeSelectionne" label="Employés" :options="employes" option-label="nom" option-value="idEmploye" flat dense options-dense />
                </q-card-section>

                <q-card-actions align="right">
                    <q-btn color="primary" label="Annuler" flat v-close-popup />
                    <q-btn color="primary" label="Enregistrer" :disable="!employeSelectionne" @click="ajouterResponsableContrat" flat v-close-popup />
                </q-card-actions>
            </q-card>
        </q-dialog>
    </q-dialog>
</template>

<script>
export default {
    name: "e-dialog-contrats-sans-icq",
    emits: ["ok", "hide"],
    data() {
        return {
            isLoading: true,
            columns: [
                { name: "numContrat", field: "numContrat", label: "#", align: "left" },
                { name: "vendeur", field: "vendeur", label: "Vendeur", align: "left" },
                { name: "dateSignature", field: "dateSignature", label: "Date de signature", align: "left" },
                { name: "adresseConstruction", field: "adresseConstruction", label: "Adresse de construction", align: "left" }
            ],
            contrats: [],
            dialogEmployes: false,
            employeSelectionne: null,
            employes: [],
			contratSelectionne: null
        };
    },
    methods: {
        show() {
            this.$refs.dialog.show();
        },
        hide() {
            this.$refs.dialog.hide();
        },
        onDialogHide() {
            this.$emit("hide");
        },
        onRowClick(evt, row) {
			this.contratSelectionne = row;
			this.employeSelectionne = null;
            this.dialogEmployes = true;
        },
		async ajouterResponsableContrat() {
			this.isLoading = true;

			try {
				await this.$api.contrats.ajouterResponsableContrat({
					idContrat: this.contratSelectionne.idContrat,
					idEmploye: this.employeSelectionne.idEmploye,
					idPoste: 8
				});

				this.$q.notify({ type: "positive", message: "Inspecteur assigné avec succès." });

				this.contrats = this.contrats.filter(x => x.idContrat !== this.contratSelectionne.idContrat);
				if (!this.contrats.length) {
					this.hide();
				}
			} catch (error) {
				this.$q.notify({ type: "negative", message: "Une erreur est survenue lors de l'assignation de l'inspecteur." });
			} finally {
				this.isLoading = false;
			}
		}
    },
    async mounted() {
        try {
            const [contrats, employes] = await Promise.all([
                this.$api.contrats.getContratsSansICQActif(),
                this.$api.employes.getPosteEmployes({ idPoste: 8 })
            ]);

            this.contrats = contrats;
            this.employes = employes;
        } catch (error) {
            this.$q.notify({ type: "negative", message: "Une erreur est survenue lors du chargement des contrats." });
        } finally {
            this.isLoading = false;
        }
    }
};
</script>

<style scoped>
.q-table-sticky-header {
    max-height: 60vh;
}
</style>
