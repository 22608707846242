<template>
    <q-file
        v-if="mode === 'input'"
        :model-value="fichier"
        hint="Cliquez ici pour sélectionner un fichier ou glissez un fichier ici"
        @update:modelValue="fichierChanged"
        :hide-hint="readonly"
        :readonly="readonly"
        :disable="disable"
        hide-bottom-space
    >
        <template v-if="loading || isLoading" v-slot:append>
            <q-btn icon="close" padding="none" :loading="true" flat round />
        </template>
        <template v-else v-slot:append>
            <q-btn v-if="allowDownload && fichier && idFichier" padding="none" icon="download" :disable="disable" @click.stop.prevent="download" flat round />
            <q-btn v-if="!readonly && fichier && idFichier" icon="close" padding="none" :disable="disable" @click.stop.prevent="clear" flat round />
        </template>
    </q-file>
    <div v-else class="row items-center justify-center q-col-gutter-x-xs">
        <div v-if="idFichier && !loading && !isLoading" class="col-auto">
            <q-btn
                v-if="allowDownload"
                :label="nomFichier || 'Nom du fichier inconnu'"
                size="sm"
                color="primary"
                padding="none"
                :disable="disable"
                @click="download"
                flat
                dense
                no-caps
            />
            
            <template v-else>
                {{ nomFichier || "Nom du fichier inconnu" }}
            </template>
            <q-tooltip v-if="descriptionFichier">
                {{ descriptionFichier }}
            </q-tooltip>
        </div>
        <q-btn v-if="loading || isLoading" icon="close" size="sm" padding="none" :loading="true" flat round />
        <div v-if="!readonly && !loading && !isLoading" class="col-auto" :class="[!allowDownload ? 'text-small' : '']">
            <q-btn v-if="idFichier" size="sm" icon="close" padding="none" :disable="disable" @click="clear" flat round />
            <q-btn v-else size="sm" padding="none" :icon="addIcon" :disable="disable" @click="ouvrirDialogAjouterFichier" flat round />
        </div>
    </div>
</template>

<script>
import EDialogInputFichier from "./EDialogInputFichier.vue";

export default {
    name: "e-input-fichier",
    props: {
        mode: {
            type: String,
            default: "input"
        },
        addIcon: {
            type: String,
            default: "attachment"
        },
        avecDescription: Boolean,
        idFichier: Number,
        nomFichier: String,
        descriptionFichier: String,
        allowDownload: Boolean,
        clearConfirmation: Boolean,
        loading: Boolean,
        readonly: Boolean,
        disable: Boolean
    },
    emits: ["update:idFichier", "update:nomFichier", "update:descriptionFichier", "onFichierChanged"],
    data() {
        return {
            fichier: null,
            isLoading: false
        };
    },
    methods: {
        fichierChanged(fichier) {
            if (fichier) {
                this.upload(fichier);
            } else {
                this.$emit("update:idFichier", null);
                this.$emit("update:nomFichier", null);

                this.$emit("onFichierChanged", null);

                this.fichier = null;
            }
        },
        async upload(fichier) {
            this.isLoading = true;

            try {
                const data = await this.$api.fichiers.upload([fichier]);

                this.$emit("update:idFichier", data[0].idFichier);
                this.$emit("update:nomFichier", fichier.name);

                this.$emit("onFichierChanged", {
                    idFichier: data[0].idFichier,
                    nomFichier: fichier.name,
                    idTypeFichier: null,
                    descriptionFichier: null
                });

                this.fichier = fichier;
            } catch (error) {
                this.$q.notify({ type: "negative", message: "Une erreur est survenue lors de l'enregistrement du fichier." });
            } finally {
                this.isLoading = false;
            }
        },
        async download() {
            this.isLoading = true;

            try {
                await this.$api.fichiers.download({ idFichier: this.idFichier });
            } catch (error) {
                this.$q.notify({ type: "negative", message: "Une erreur est survenue lors du téléchargement du fichier." });
            } finally {
                this.isLoading = false;
            }
        },
        clear() {
            if (this.clearConfirmation) {
                this.$q
                    .dialog({
                        title: "Confirmation",
                        message: "Voulez-vous vraiment supprimer ce fichier?",
                        cancel: true
                    })
                    .onOk(() => {
                        if (this.mode === "input") {
                            this.fichierChanged(null);
                        } else {
                            this.$emit("update:idFichier", null);
                            this.$emit("update:nomFichier", null);
                            this.$emit("update:descriptionFichier", null);

                            this.$emit("onFichierChanged", null);
                        }
                    });
            } else {
                if (this.mode === "input") {
                    this.fichierChanged(null);
                } else {
                    this.$emit("update:idFichier", null);
                    this.$emit("update:nomFichier", null);
                    this.$emit("update:descriptionFichier", null);

                    this.$emit("onFichierChanged", null);
                }
            }
        },
        ouvrirDialogAjouterFichier() {
            this.$q
                .dialog({
                    component: EDialogInputFichier,
                    componentProps: {
                        avecDescription: this.avecDescription
                    }
                })
                .onOk((fichier) => {
                    this.$emit("update:idFichier", fichier.idFichier);
                    this.$emit("update:nomFichier", fichier.nomFichier);
                    this.$emit("update:descriptionFichier", fichier.descriptionFichier);

                    this.$emit("onFichierChanged", fichier);
                });
        }
    },
    watch: {
        idFichier(idFichier) {
            if (!idFichier) {
                this.fichier = null;
            }
        }
    },
    mounted() {
        if (this.mode === "input") {
            if (this.idFichier) {
                this.fichier = new File([""], this.nomFichier || "Nom du fichier inconnu");
            }
        }
    }
};
</script>
