<template>
    <e-select-adresse ref="selectAdresse" :error="error" :error-message="errorMessageLocal" :rules="rulesLocal" @selected="selected">
        <template v-slot:append>
            <q-icon name="edit" class="cursor-pointer" title="Personnaliser l'adresse" @click.prevent.stop="ouvrirDialogAdresse" />
        </template>
    </e-select-adresse>
</template>

<script>
import ESelectAdresse from "./ESelectAdresse.vue";

import EDialogAdresse from "./EDialogAdresse.vue";

export default {
    name: "e-input-adresse",
    emits: ["update:isValid"],
    components: {
        ESelectAdresse
    },
    props: {
        isValid: Boolean,
        errorMessage: Boolean,
        rules: Boolean
    },
    data() {
        return {
            isSelected: false,
            adresse: {
                numCivique: "",
                appartement: null,
                rue: "",
                ville: "",
                province: "",
                pays: "",
                codePostal: "",
                complement: null,
                latitude: null,
                longitude: null
            }
        };
    },
    computed: {
        isValidLocal() {
            return !!this.adresse.numCivique && !!this.adresse.rue && !!this.adresse.ville && !!this.adresse.province && !!this.adresse.pays && !!this.adresse.codePostal;
        },
        errorMessageLocal() {
            if (!this.adresse.numCivique) return "Le numéro civique est obligatoire";
            if (!this.adresse.rue) return "La rue est obligatoire";
            if (!this.adresse.ville) return "La ville est obligatoire";
            if (!this.adresse.province) return "La province/état est obligatoire";
            if (!this.adresse.pays) return "Le pays est obligatoire";
            if (!this.adresse.codePostal) return "Le code postal est obligatoire";

            return null;
        },
        error() {
            return this.errorMessage && !!this.errorMessageLocal && this.isSelected;
        },
        rulesLocal() {
            return this.rules ? [(val) => !!val || "L'adresse est obligatoire", () => this.errorMessageLocal || true] : [];
        }
    },
    methods: {
        selected(adresse) {
            this.isSelected = true;

            this.adresse.numCivique = adresse.numCivique;
            this.adresse.appartement = null;
            this.adresse.rue = adresse.rue;
            this.adresse.ville = adresse.ville;
            this.adresse.province = adresse.province;
            this.adresse.pays = adresse.pays;
            this.adresse.codePostal = adresse.codePostal;
            this.adresse.complement = null;
        },
        ouvrirDialogAdresse() {
            this.$q
                .dialog({
                    component: EDialogAdresse,
                    componentProps: {
                        title: "Personnaliser l'adresse",
                        adresse: { ...this.adresse },
                        enregistrer: false
                    }
                })
                .onOk(({ adresse, geocode }) => {
                    this.isSelected = true;
                    this.adresse = adresse;
                    this.$refs.selectAdresse.updateAdresse(adresse, geocode?.place_id || null);
                });
        },
        async getIdAdresse() {
            const adresse = { ...this.adresse };

            const geocode = await this.$api.googleApi.getGeocode({
                adresse: this.$filters.adresse(adresse, { appartement: false, complement: false })
            });

            if (geocode.length) {
                const { lat, lng } = geocode[0].geometry.location;
                adresse.latitude = lat;
                adresse.longitude = lng;
            }

            const id = await this.$api.adresses.ajouterAdresse(adresse);

            return id;
        },
        contientAdresse() {
            return !this.errorMessageLocal;
        }
    },
    watch: {
        isValidLocal: {
            handler(value) {
                this.$emit("update:isValid", value);
            },
            immediate: true
        }
    }
};
</script>
