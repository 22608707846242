const MAX_WIDTH = 1920;
const MAX_HEIGHT = 1080;

const forEachNested = (arr, key, callback, parent) => {
    let breakForEach;
    for (let i = 0; i < arr.length; i++) {
        breakForEach = callback(arr[i], parent);
        if (breakForEach) break;
        if (arr[i][key] && arr[i][key].length) breakForEach = forEachNested(arr[i][key], key, callback, arr[i]);
        if (breakForEach) break;
    }
    return breakForEach;
};

const normalizeString = (value) => {
    if (value === null || value === undefined) value = "";
    if (typeof value !== "string") value = value.toString();
    return value
        .normalize("NFKD")
        .replace(/ /g, "")
        .replace(/\p{Diacritic}/gu, "")
        .toLowerCase();
};

const extraireNombres = (inputString) => {
    if (!inputString) return [];
    const numberPattern = /\d+/g;
    const matches = inputString.match(numberPattern);

    if (matches === null) {
        return [];
    }
    const uniqueNumbers = new Set(matches);
    const uniqueNumberArray = Array.from(uniqueNumbers).map(Number);

    return uniqueNumberArray;
};

const aNombres = (inputString, requiredNumbers) => {
    const patternParts = requiredNumbers
        .map((number, index) => {
            if (index === 0) {
                // Pour le premier nombre, exiger un espace, une parenthèse ouvrante, ou le début de la chaîne avant,
                // et s'assurer qu'il n'est pas précédé d'un autre nombre ou d'un slash
                return `(?<=^|\\s|\\()(?<![\\d/])${number}(?=[^\\d]|$)`;
            } else {
                return `(?<![\\d/])${number}(?=[^\\d]|$)`;
            }
        })
        .join(`[^\\d]*[xX][^\\d]*`);

    const pattern = new RegExp(patternParts, "i");

    return pattern.test(inputString);
};

export const CompagniesLaprise = Object.freeze({
    HOM: 30775,
    LAPRISE: 1,
    LAPRISE_IMMO: 47680,
    EXPERT: 48547,
    LORENDO: 1230
});

export default {
    onKeyPressInt(evt) {
        if (evt.charCode > 31 && (evt.charCode < 48 || evt.charCode > 57)) {
            evt.preventDefault();
        }
    },
    onKeyPressFloat(evt) {
        if (evt.charCode != 46 && evt.charCode > 31 && (evt.charCode < 48 || evt.charCode > 57)) {
            evt.preventDefault();
        } else {
            const parts = evt.srcElement.value.split(".");
            if (parts.length > 1 && evt.charCode == 46) {
                evt.preventDefault();
            }
        }
    },
    forEachNested: (arr, key, callback) => forEachNested(arr, key, callback),
    findNested: (arr, key, fneval) => {
        let find = null;

        forEachNested(arr, key, (item, parent) => {
            if (fneval(item, parent)) {
                find = item;
                return true;
            }
        });

        return find;
    },
    getParamsAncienneErp({ url = "", data = {} } = {}) {
        return {
            date: window.btoa(new Date().toJSON()),
            params: window.btoa(
                JSON.stringify({
                    url,
                    data
                })
            )
        };
    },
    s2ab(s) {
        const buffer = new ArrayBuffer(s.length),
            view = new Uint8Array(buffer);
        for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
        return buffer;
    },
    printTable({ width = 768, height = 1024, title = "", headers = [], rows = [] } = {}) {
        const pw = window.open("", "", `width=${width},height=${height}`);

        pw.document.write(`
        <html>
            <head>
                <title>${title}</title>
                <style type="text/css">
                    table {
                        width: 100%;
                        max-width: 100%;
                        border-collapse: collapse;
                        font-size: 0.9em;
                        font-family: sans-serif;
                        border: 1px solid rgba(0, 0, 0, 0.12);
                    }
                    table th, td {
                        padding: 8px 12px;
                    }
                    table thead tr {
                        text-align: left;
                    }
                    table tbody tr {
                        border-top: 1px solid rgba(0, 0, 0, 0.12);
                    }
                </style>
            </head>
            <body>
                <table>
                    <thead>
                        <tr>
                            ${headers.map((col) => `<th>${col}</th>`).join("\n")}
                        </tr>
                    </thead>
                    <tbody>
                        ${rows.map((row) => `<tr>${row.map((col) => `<td>${col == null ? "" : col}</td>`).join("\n")}</tr>`).join("\n")}
                    </tbody>
                </table>
            </body>
        </html>
        `);

        pw.document.close();
        pw.onafterprint = () => pw.close();
        pw.print();
    },
    printHtmlTable({ width = 768, height = 1024, title = "", html = "", style = "" } = {}) {
        const pw = window.open("", "", `width=${width},height=${height}`);

        pw.document.write(`
        <html>
            <head>
                <title>${title}</title>
                <style type="text/css">
                    .q-icon {
                        display: none;
                    }
                    ${style}
                </style>
            </head>
            <body>
                ${html}
            </body>
        </html>
        `);

        pw.document.close();
        pw.onafterprint = () => pw.close();
        pw.print();
    },
    table: {
        filterMethod(rows, terms, cols, getCellValue) {
            //Normaliser la recherche
            const normalizedTerms = normalizeString(terms);
            const nombres = extraireNombres(terms);

            //Filtrer les colonnes avec valeurs
            cols = cols.filter((x) => x.field);

            //Retourner les rows filtrées
            return rows.filter((row) => {
                return cols.some((column) => {
                    const cellValue = getCellValue(column, row);
                    const hasNumbers = column.name === "nom" && nombres.length > 1 && aNombres(cellValue, nombres);
                    const containsTerm = normalizeString(cellValue).indexOf(normalizedTerms) > -1;
                    return hasNumbers || containsTerm;
                });
            });
        }
    },
    image: {
        async redimensionner(imageFile, maxWidth = MAX_WIDTH, maxHeight = MAX_HEIGHT) {
            const img = document.createElement("img");
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");

            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = (e) => {
                    img.src = e.target.result;
                    img.onload = async () => {
                        if (img.width > maxWidth || img.height > maxHeight) {
                            const ratio = Math.min(maxWidth / img.width, maxHeight / img.height);
                            canvas.width = img.width * ratio;
                            canvas.height = img.height * ratio;
                        } else {
                            canvas.width = img.width;
                            canvas.height = img.height;
                        }

                        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

                        canvas.toBlob((blob) => {
                            resolve(blob);
                        }, imageFile.type);
                    };
                };
                reader.onerror = (e) => reject(e);
                reader.readAsDataURL(imageFile);
            });
        }
    }
};
