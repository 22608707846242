<template>
    <q-dialog ref="dialog" @hide="onDialogHide">
        <q-card>
            <q-card-section>
                <div class="row">
                    <q-field>
                        <template v-slot:control>
                            <div class="self-center full-width no-outline" tabindex="0">{{ this.formatDate(this.value) }}</div>
                        </template>
                    </q-field>
                </div>

                <div class="row">
                    <!-- Heures -->
                    <div class="col-5">
                        <div class="row justify-center">
                            <q-btn round flat v-touch-repeat:0:140.mouse.enter.space="heure_plus"><q-icon name="keyboard_arrow_up" /></q-btn>
                        </div>
                        <div class="row">
                            <q-field label="Heures" stack-label v-model="temps.heures" type="text" readonly>
                                <template v-slot:control>
                                    <div class="text-h3 self-center full-width no-outline" tabindex="0">{{ padTo2Digits(temps.heures) }}</div>
                                </template>
                            </q-field>
                        </div>
                        <div class="row justify-center">
                            <q-btn round flat v-touch-repeat:0:140.mouse.enter.space="heure_moins"><q-icon name="keyboard_arrow_down" /></q-btn>
                        </div>
                    </div>
                    <div class="col-2">
                        <div class="row" style="height: 0"></div>
                        <div class="row items-center" style="height: 100%">
                            <span class="text-h2 q-ml-xs q-mt-xs">:</span>
                        </div>
                        <div class="row" style="height: 0"></div>
                    </div>
                    <!-- Minutes -->
                    <div class="col-5">
                        <div class="row justify-center">
                            <q-btn round flat v-touch-repeat:0:120.mouse.enter.space="minute_plus"><q-icon name="keyboard_arrow_up" /></q-btn>
                        </div>
                        <div class="row">
                            <q-field label="Minutes" stack-label v-model="temps.minutes" type="text" readonly>
                                <template v-slot:control>
                                    <div class="text-h3 self-center full-width no-outline" tabindex="0">{{ padTo2Digits(temps.minutes) }}</div>
                                </template>
                            </q-field>
                        </div>
                        <div class="row justify-center">
                            <q-btn round flat v-touch-repeat:0:120.mouse.enter.space="minute_moins"><q-icon name="keyboard_arrow_down" /></q-btn>
                        </div>
                    </div>
                </div>
            </q-card-section>

            <q-card-actions align="right">
                <q-btn @click="hide" color="primary">Fermer</q-btn>
            </q-card-actions>
        </q-card>
    </q-dialog>
</template>

<script>
export default {
    props: ["modelValue"],
    emits: ["update:modelValue", "ok", "hide"],
    computed: {
        value: {
            get() {
                return new Date(this.modelValue);
            },
            set(value) {
                this.$emit("update:modelValue", value);
            }
        }
    },
    data() {
        return {
            temps: {
                heures: null,
                minutes: null
            }
        };
    },
    methods: {
        show() {
            this.$refs.dialog.show();
        },
        hide() {
            this.$refs.dialog.hide();
        },
        onDialogHide() {
            this.$emit("hide");
        },
        onOKClick() {
            this.$emit("ok");
            this.hide();
        },
        onCancelClick() {
            this.hide();
        },
        heure(mode) {
            var heures = this.value.getHours();

            switch (mode) {
                case "+":
                    this.value.setHours(heures + 1);

                    break;
                case "-":
                    this.value.setHours(heures - 1);

                    break;
            }
            this.temps.heures = this.value.getHours();
            this.temps.minutes = this.value.getMinutes();
            this.value = this.formatDate(this.value);
        },
        minute(mode) {
            var minutes = this.value.getMinutes();

            switch (mode) {
                case "+":
                    this.value.setMinutes(minutes + 1);
                    break;
                case "-":
                    this.value.setMinutes(minutes - 1);
                    break;
            }
            this.temps.heures = this.value.getHours();
            this.temps.minutes = this.value.getMinutes();
            this.value = this.formatDate(this.value);
        },
        padTo2Digits(num) {
            return num.toString().padStart(2, "0");
        },

        formatDate(date) {
            return (
                [date.getFullYear(), this.padTo2Digits(date.getMonth() + 1), this.padTo2Digits(date.getDate())].join("-") +
                " " +
                [this.padTo2Digits(date.getHours()), this.padTo2Digits(date.getMinutes())].join(":")
            );
        },
        heure_plus() {
            this.heure("+");
        },
        heure_moins() {
            this.heure("-");
        },
        minute_plus() {
            this.minute("+");
        },
        minute_moins() {
            this.minute("-");
        }
    },
    mounted() {
        this.temps.heures = this.value.getHours();
        this.temps.minutes = this.value.getMinutes();
    }
};
</script>
