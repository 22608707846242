export default (api) => ({
    getSousTacheContrat: ({ idTacheContrat = null } = {}) =>
        api
            .post("api/generiques/stored-procedure/GetSousTacheContratV2", {
                idTacheContrat
            })
            .then((r) => r.data[0]),
    getTachesContratInspectrices: ({ idContrat = null } = {}) =>
        api
            .post("api/generiques/stored-procedure/GetTachesContratInspectricesV2", {
                idContrat
            })
            .then((r) => r.data[0]),
    getIdContrat: ({ numContrat = null } = {}) =>
        api
            .post("api/generiques/scalar-function/getIdContrat", {
                numContrat
            })
            .then((r) => r.data),
    getInspections: ({ idTacheInspection = null, actif = null } = {}) =>
        api
            .post("api/generiques/stored-procedure/GetInspectionsV2", {
                idTacheInspection,
                actif
            })
            .then((r) => r.data[0]),
    ajouterInspection: ({ idTacheContrat = null, idTypeInspection = null, commentaire = null } = {}) =>
        api
            .post("api/generiques/stored-procedure/AjouterInspectionV2", {
                idTacheContrat,
                idTypeInspection,
                commentaire
            })
            .then((r) => r.data),
    ajouterFichierResultatInspection: ({ idResultatInspection = null, idFichier = null } = {}) =>
        api
            .post("api/generiques/stored-procedure/AjouterFichierResultatInspection", {
                idResultatInspection,
                idFichier
            })
            .then((r) => r.data),
    getFichiersArborescenceInspectionContrat: ({ idContrat = null, idTaskContrat = null, tri = null, filtre = null, idResultatInspection = null } = {}) =>
        api
            .post("api/generiques/stored-procedure/GetFichiersArborescenceInspectionContratV2", {
                idContrat,
                idTaskContrat,
                tri,
                filtre,
                idResultatInspection
            })
            .then((r) => r.data[0]),
    getRemarquesInspection: ({ idResultatInspection = null } = {}) =>
        api
            .post("api/generiques/stored-procedure/GetRemarquesInspection", {
                idResultatInspection
            })
            .then((r) => r.data[0]),
    getTacheContrat: ({ idTacheContrat = null } = {}) =>
        api
            .post("api/generiques/stored-procedure/GetTacheContratV2", {
                idTacheContrat
            })
            .then((r) => r.data[0]),

    getProjetsActifsInspecteur: ({ idResponsable = null } = {}) =>
        api
            .post("api/generiques/stored-procedure/GetProjetsActifsInspecteur", {
                idResponsable
            })
            .then((r) => r.data[0]),

    getRemarquesGenerales: ({ idCategorie = null } = {}) =>
        api
            .post("api/generiques/stored-procedure/GetRemarquesGenerales", {
                idCategorie
            })
            .then((r) => r.data[0]),
    getRemarquesTacheInspection: ({ idTache = null, idTypeRemarqueTache = null } = {}) =>
        api
            .post("api/generiques/stored-procedure/GetRemarquesTacheInspection", {
                idTache,
                idTypeRemarqueTache
            })
            .then((r) => r.data[0]),
    insererRemarqueInspection: ({ idResultatInspection = null, idRemarqueTache } = {}) =>
        api.post("api/generiques/stored-procedure/InsererRemarqueInspection", {
            idResultatInspection,
            idRemarqueTache
        }),
    synchroniserResultatsInspectionsAjoutERP: ({ idTaskContrat = null, idTypeInspection = null, idResultatInspection = null } = {}) =>
        api.post("api/generiques/stored-procedure/SynchroniserResultatsInspectionsAjoutERPV2", {
            idTaskContrat,
            idTypeInspection,
            idResultatInspection
        }),
    getNomsClients: ({ idContrat = null } = {}) =>
        api
            .post("api/generiques/scalar-function/getClientsContrat", {
                idContrat
            })
            .then((r) => r.data),
    getIdResponsableUsager: ({ idCompagnie = null } = {}) =>
        api
            .post("api/generiques/scalar-function/GetIdEmployeUsager", {
                idCompagnie
            })
            .then((r) => r.data),
    definirStatutTache: ({ statut = null, idTaskContrat = null, inclureDescendants = null } = {}) =>
        api.post("api/generiques/stored-procedure/DefinirStatutTache", {
            statut,
            idTaskContrat,
            inclureDescendants
        }),
    getIdArborescenceContrat: ({ idContrat = null } = {}) =>
        api
            .post("api/generiques/scalar-function/GetIdArborescenceContrat", {
                idContrat
            })
            .then((r) => r.data),
    getBranchesInspectionTemplate: ({ idArborescence = null } = {}) =>
        api
            .post("api/generiques/stored-procedure/GetBranchesInspectionTemplate", {
                idArborescence
            })
            .then((r) => r.data[0]),
    getArborescencesInspection: () => api.post("api/generiques/stored-procedure/GetArborescencesInspection").then((r) => r.data[0]),
    changerNomBrancheInspectionContrat: ({ idTaskContrat = null, nouveauNomBranche = null } = {}) =>
        api.post("api/generiques/stored-procedure/ChangerNomBrancheInspectionContrat", {
            idTaskContrat,
            nouveauNomBranche
        }),
    importerBrancheInspectionDansContrat: ({ idContrat = null, idTask = null, nom = null } = {}) =>
        api
            .post("api/generiques/stored-procedure/ImporterBrancheInspectionDansContrat", {
                idContrat,
                idTask,
                nom
            })
            .then((r) => r.data[0]),
    ajusterNbApplicableTachesContrat: ({ idContrat = null } = {}) =>
        api.post("api/generiques/stored-procedure/AjusterNbApplicableTachesContrat", {
            idContrat
        }),
    employePeutAccederContrat: ({ idEmploye = null, numContrat = null } = {}) =>
        api
            .post("api/generiques/scalar-function/EmployePeutAccederContrat", {
                idEmploye,
                numContrat
            })
            .then((r) => r.data),
    modifierOrdreBranches: ({ ordres = [] } = {}) =>
        api.post("api/generiques/stored-procedure/ModifierOrdreBranchesInspection", {
            ordres: {
                type: "OrdresItems",
                rows: ordres
            }
        }),
    desactiverBrancheInspectionContrat: ({ idTaskContrat = null } = {}) =>
        api.post("api/generiques/stored-procedure/DesactiverBrancheInspectionContrat", {
            idTaskContrat
        })
});
