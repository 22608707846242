<template>
    <q-popup-proxy transition-show="scale" transition-hide="scale">
        <div class="q-pa-md">
            <div class="row q-col-gutter-x-sm">
                <div class="col column q-gutter-y-sm">
                    <q-btn icon="expand_less" padding="none" @click="setHeure(1)" flat round dense />
                    <div class="col-auto text-center text-subtitle2">
                        {{ heure }}
                    </div>
                    <q-btn icon="expand_more" padding="none" @click="setHeure(-1)" flat round dense />
                </div>
                <div class="col-auto column q-gutter-y-sm">
                    <div class="col"></div>
                    <div class="col-auto text-subtitle2">:</div>
                    <div class="col"></div>
                </div>
                <div class="col column q-gutter-y-sm">
                    <q-btn icon="expand_less" padding="none" @click="setMinute(false)" flat round dense />
                    <div class="col-auto text-center text-subtitle2">
                        {{ minute }}
                    </div>
                    <q-btn icon="expand_more" padding="none" @click="setMinute(true)" flat round dense />
                </div>
            </div>
        </div>
    </q-popup-proxy>
</template>

<script>
import { date } from "quasar";
const { formatDate } = date;

export default {
    name: "e-popup-time",
    emits: ["update:modelValue"],
    props: {
        modelValue: String,
        minuteBound: {
            type: Number,
            default: 1
        }
    },
    computed: {
        heure() {
            const parts = (this.modelValue || "").split(" ");
            return parts.length > 1 ? parts[1].split(":")[0] : "00";
        },
        minute() {
            const parts = (this.modelValue || "").split(" ");
            return parts.length > 1 ? parts[1].split(":")[1] : "00";
        }
    },
    methods: {
        setHeure(bound) {
            let heure = parseInt(this.heure, 10);

            heure += bound;
            if (heure > 23) heure = 0;
            else if (heure < 0) heure = 23;

            this.$emit("update:modelValue", (this.modelValue || formatDate(new Date(), "YYYY-MM-DD HH:mm")).split(" ")[0] + " " + heure.toString().padStart(2, "0") + ":" + this.minute);
        },
        setMinute(negative) {
            let minute = parseInt(this.minute, 10);

            for (let i = 0; i < this.minuteBound; i++) {
                minute += negative ? -1 : 1;

                if (minute > 59) minute = 0;
                else if (minute < 0) minute = 59;
            }

            this.$emit("update:modelValue", (this.modelValue || formatDate(new Date(), "YYYY-MM-DD HH:mm")).split(" ")[0] + " " + this.heure + ":" + minute.toString().padStart(2, "0"));
        }
    }
};
</script>
