export default (api) => ({
    getStatistiquesDessin: ({ idCategorieFichier = null, dateDebut = null, dateFin = null } = {}) =>
        api
            .post("api/generiques/stored-procedure/GetListeDessinsSortisEntreDates", {
                idCategorieFichier,
                dateDebut,
                dateFin
            })
            .then((r) => r.data[0])
});
