import { createApp } from "vue";
import { createPinia } from "pinia";

import mitt from "mitt";
import App from "./App.vue";
import router from "./router";

import "viewerjs/dist/viewer.css";
import VueViewer from "v-viewer";

import filters from "./filters";
import utils from "./utils/utils.js";

import api from "./api/index.js";

import Quasar from 'quasar/src/vue-plugin.js';;
import quasarUserOptions from "./quasar-user-options";

import EInputFichier from "./components/EInputFichier.vue";
import EPopupDate from "./components/EPopupDate.vue";
import EPopupTime from "./components/EPopupTime.vue";

const emitter = mitt();

const app = createApp(App);
const pinia = createPinia();

app.use(VueViewer);
app.use(Quasar, quasarUserOptions);
app.use(pinia);
app.use(router);

app.config.globalProperties.$filters = filters;
app.config.globalProperties.$utils = utils;

app.config.globalProperties.$api = api;

app.config.globalProperties.$emitter = emitter;

app.component("e-input-fichier", EInputFichier);
app.component("e-popup-date", EPopupDate);
app.component("e-popup-time", EPopupTime);

app.mount("#app");
