export default (api) => ({
    getClassesCategories: () => api.post("api/generiques/stored-procedure/GetClassesCategoriesV2").then((r) => r.data[0]),
    getProduitsMajPrix: () => api.post("api/generiques/stored-procedure/GetProduitsMajPrix").then((r) => r.data[0]),
    modifierPrixProduitAssembleur: ({ idProduitAssembleur = null, prix = null } = {}) =>
        api.post("api/generiques/stored-procedure/ModifierPrixProduitAssembleur", { idProduitAssembleur, prix }),
    modifierQteInventaireProduitAssembleur: ({ idProduitAssembleur = null, qteInventaire = null } = {}) =>
        api.post("api/generiques/stored-procedure/ModifierQteInventaireProduitAssembleur", { idProduitAssembleur, qteInventaire }),
    getLiensFournisseursProduitAssembleur: ({ idProduitAssembleur = null } = {}) =>
        api.post("api/generiques/stored-procedure/GetLiensFournisseursProduitAssembleur", { idProduitAssembleur }).then((r) => ({ disponibles: r.data[0], existants: r.data[1] })),
    ajouterLienFournisseurProduitAssembleur: ({ idProduitAssembleur = null, idCompagnie = null, codeProduit = null } = {}) =>
        api.post("api/generiques/stored-procedure/AjouterLienFournisseurProduitAssembleur", { idProduitAssembleur, idCompagnie, codeProduit }).then((r) => r.data[0][0]),
    supprimerLienFournisseurProduitAssembleur: ({ idMappageProduitAssembleur = null } = {}) =>
        api.post("api/generiques/stored-procedure/SupprimerLienFournisseurProduitAssembleur", { idMappageProduitAssembleur }),
    getAssemblagesTemplatesProduitAssembleur: ({ idProduitAssembleur = null } = {}) =>
        api.post("api/generiques/stored-procedure/GetAssemblagesTemplatesProduitAssembleur", { idProduitAssembleur }).then((r) => r.data[0])
});
