import api from "../axios.js";

import Auth from "./modules/auth";
import Contrats from "./modules/contrats";
import Conception from "./modules/conception";
import Fichiers from "./modules/fichiers";
import Objets from "./modules/objets";
import Prospects from "./modules/prospects";
import Adresses from "./modules/adresses";
import GoogleApi from "./modules/google-api";
import Employes from "./modules/employes";
import Produits from "./modules/produits";
import MargesAssembleur from "./modules/marges-assembleur";
import Misc from "./modules/misc";
import ProjetsInventaires from "./modules/projets-inventaires";
import Compagnies from "./modules/compagnies";
import Taches from "./modules/taches";
import Courriels from "./modules/courriels";
import Sites from "./modules/sites";
import Objectifs from "./modules/objectifs";
import Traductions from "./modules/traductions";
import Finances from "./modules/finances";
import Inspection from "./modules/inspection";
import Planification from "./modules/planification";
import Modeles from "./modules/modeles";
import Plans from "./modules/plans";
import Tcost from "./modules/tcost";
import Evolia from "./modules/evolia";

export default {
    getInstance: () => api,
    auth: Auth(api),
    contrats: Contrats(api),
    conception: Conception(api),
    fichiers: Fichiers(api),
    objets: Objets(api),
    prospects: Prospects(api),
    adresses: Adresses(api),
    googleApi: GoogleApi(api),
    employes: Employes(api),
    produits: Produits(api),
    margesAssembleur: MargesAssembleur(api),
    misc: Misc(api),
    projetsInventaires: ProjetsInventaires(api),
    compagnies: Compagnies(api),
    taches: Taches(api),
    courriels: Courriels(api),
    sites: Sites(api),
    objectifs: Objectifs(api),
    traductions: Traductions(api),
    finances: Finances(api),
    inspection: Inspection(api),
    planification: Planification(api),
    modeles: Modeles(api),
    plans: Plans(api),
    tcost: Tcost(api),
    evolia: Evolia(api)
};
