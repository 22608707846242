<template>
    <span class="row q-gutter-x-sm">
        <q-checkbox
            size="xs"
            v-model="estSelectionne"
            dense
            :label="courriel.Nom + ' ( ' + courriel.Poste + ' )'"
            :disable="!courriel.Courriel"
            :style="!courriel.Courriel ? { color: 'red' } : ''"
            @update:model-value="emitSelected"
        />
        <span v-if="!courriel.Courriel" class="text-italic"> *Aucun courriel n'a été spécifié* </span>
    </span>
</template>
<script>
export default {
    name: "e-checkbox-courriels",
    emits: ["selected"],
    props: {
        courriel: {
            type: Object
        }
    },
    data() {
        return {
            courrielLocale: {},
            estSelectionne: false
        };
    },
    computed: {
        estSelected() {
            if (this.courriel.selected) {
                return true;
            }
            return false;
        }
    },
    methods: {
        emitSelected() {
            this.courrielLocale.selected = this.estSelectionne;
            this.$emit("selected", this.courrielLocale);
        }
    },
    watch: {
        courriel: {
            handler() {
                if (!this.courriel.selected) {
                    this.estSelectionne = false;
                }
            },
            deep: true
        }
    },
    mounted() {
        this.courrielLocale = this.courriel;
        this.estSelectionne = this.estSelected;
    }
};
</script>
