<template>
    <q-dialog ref="dialogCaracteristiquesPlan">
        <q-layout view="lHh lpr lFf" container class="layout-caracteristiques shadow-2 rounded-borders">
            <q-header elevated>
                <q-toolbar>
                    <q-toolbar-title>Sélectionner les caractéristiques désirées dans la recherche</q-toolbar-title>

                    <q-btn flat round dense v-close-popup icon="close" />
                </q-toolbar>
            </q-header>
            <q-page-container>
                <q-page padding>
                    <q-btn @click="toutOuvrir">Tout ouvrir</q-btn>
                    <q-btn @click="toutFermer">Tout fermer</q-btn>

                    <div v-if="categoriesLoaded">
                        <div v-for="categorie in categories" :key="categorie.IdCategoriesTagsCaracteristiquesDessin">
                            <div @click="toggleEnfantsVisibles(categorie)" class="titre-categorie">
                                <q-icon :name="categorie.enfantsVisibles == false ? 'expand_more' : 'expand_less'" />&nbsp;{{ categorie.Nom }}
                            </div>
                            <div :data-enfants-categorie="categorie.IdCategoriesTagsCaracteristiquesDessin" v-show="categorie.enfantsVisibles" class="enfants-categorie">
                                <ul>
                                    <li v-for="enfant in categorie.enfants" :key="enfant.IdTagCaracteristiqueDessin">
                                        <input type="checkbox" :value="enfant" v-model="caracteristiquesPlanChoisies" :id="enfant.IdTagCaracteristiqueDessin" />
                                        <label :for="enfant.IdTagCaracteristiqueDessin">{{ enfant.Nom }}</label>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <q-inner-loading :showing="categoriesLoading" label="Chargement des catégories..." label-class="text-black" label-style="font-size: 1.1em" />
                </q-page>
            </q-page-container>
        </q-layout>
    </q-dialog>
</template>
<script>
import { ref } from "vue";

import { mapState } from "pinia";

import { useAuthStore } from "@/stores";

export default {
    name: "ECaracteristiquesPlan",
    props: { caracteristiquesPlan: Array },
    emits: ["update:caracteristiquesPlan"],
    data() {
        return {
            categories: [],

            categoriesLoaded: false,
            categoriesLoading: false
        };
    },
    methods: {
        async getCategoriesTags() {
            try {
                this.categoriesLoading = true;
                const categs = await this.$api.contrats.getCategoriesCaracteristiquesPlan({
                    idCompagnie: this.idCompagnie,
                    idFichierContrat: null
                });

                var children = ref([]);

                for (var i = 0; i < categs.length; i++) {
                    children.value = await this.getTagsCategorie(categs[i].IdCategoriesTagsCaracteristiquesDessin); // empty the original array

                    categs[i].enfants = children.value;
                    categs[i].enfantsVisibles = false;
                }
                this.categories = categs;
            } catch (error) {
                this.$q.notify({ type: "negative", message: "Une erreur est survenue lors du chargement des catégories de caractéristiques de plan." + error });
            } finally {
                this.categoriesLoading = false;
            }
        },
        async getTagsCategorie(idCategorie) {
            try {
                const children = await this.$api.contrats.getTagsDessin({
                    idCategorie: idCategorie,
                    idFichierContrat: null
                });

                return children;
            } catch (error) {
                this.$q.notify({ type: "negative", message: "Une erreur est survenue lors du chargement des tags de la catégorie de caractéristiques de plan." });
            }
        },
        toggleEnfantsVisibles(categorie) {
            categorie.enfantsVisibles = !categorie.enfantsVisibles;
        },
        toutOuvrir() {
            this.categories.forEach((categorie) => (categorie.enfantsVisibles = true));
        },
        toutFermer() {
            this.categories.forEach((categorie) => (categorie.enfantsVisibles = false));
        }
    },
    watch: {},
    computed: {
        ...mapState(useAuthStore, ["idCompagnie"]),
        caracteristiquesPlanChoisies: {
            get() {
                return this.caracteristiquesPlan;
            },
            set(value) {
                this.$emit("update:caracteristiquesPlan", value);
            }
        }
    },
    async mounted() {
        await this.getCategoriesTags();
        this.categoriesLoaded = true;
    }
};
</script>

<style scoped>
.layout-caracteristiques {
    background-color: rgba(255, 255, 255, 1);
    height: 800px;
    width: 1000px;
    max-width: 1500px;
}
.titre-categorie {
    background-color: rgb(153, 204, 255);
    width: 100%;
    margin: 3px;
    padding: 2px;
    font-family: "Roboto", "-apple-system", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: bold;
    cursor: pointer;
}

.titre-categorie:hover {
    background-color: rgb(153, 197, 255);
}

.enfants-categorie {
    margin-left: 0px;
}
ul li {
    display: inline-block;
    list-style-type: none;
}

li {
    margin: 2px;
}

input[type="checkbox"] {
    appearance: none;
    vertical-align: middle;
}

input[type="checkbox"] ~ label {
    cursor: pointer;
    padding: 2px;
    margin: 2px;
    text-decoration: underline;
}

input[type="checkbox"]:checked ~ label {
    background-color: #1976d2;
    color: white;
    border: thin solid black;
    border-radius: 5%;
    cursor: pointer;
    padding: 2px;
    margin: 1px;
    font-family: "Roboto", "-apple-system", "Helvetica Neue", Helvetica, Arial, sans-serif;
    text-decoration: none;
}
</style>
