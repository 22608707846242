<template>
    <q-dialog ref="dialog" @hide="onDialogHide" :no-backdrop-dismiss="isSubmitting" :no-esc-dismiss="isSubmitting" :maximized="$q.screen.lt.sm">
        <q-card class="q-dialog-plugin" :class="{ 'q-dialog-size-sm': !$q.screen.lt.sm }">
            <q-form @submit="onOKClick">
                <q-card-section>
                    <div class="row items-start no-wrap q-gutter-x-sm">
                        <div class="text-h6">{{ title }}</div>
                        <q-space />
                        <q-btn icon="close" :disable="isSubmitting" @click="hide" flat round dense />
                    </div>
                </q-card-section>
                <q-card-section class="q-gutter-y-md">
                    <q-input
                        v-model="description"
                        type="textarea"
                        label="Description"
                        :rules="[(val) => !!val || 'La description du suivi est obligatoire']"
                        @change="description = description.trim()"
                        lazy-rules
                        hide-bottom-space
                        autogrow
                        dense
                    />
                    <q-file
                        v-model="fichiers"
                        label="Fichier(s)"
                        hint="Cliquez sur le champ ci-dessus pour sélectionner vos fichiers OU glissez-les à l'intérieur de celui-ci."
                        use-chips
                        multiple
                        dense
                        append
                        max-total-size="30000000"
                        @rejected="onRejected"
                    >
                        <template v-slot:append>
                            <q-icon name="attach_file" />
                        </template>
                    </q-file>
                    <q-select
                        v-model="selectedTypeSuivi"
                        label="Type de suivi effectué"
                        :options="typesSuivis"
                        :option-label="(x) => `${x.nom}${!x.dateObligatoire ? ' ¹' : ''}`"
                        option-value="idTypeSuivi"
                        :loading="isLoadingTypesSuivis"
                        :rules="[(val) => !!val || 'Le type de suivi effectué est obligatoire']"
                        hint="¹ Ces types de suivi sont considérés comme passifs. Ceci signifie qu'il n'est pas obligatoire pour ces suivis de spécifier une date de prochain suivi, mais également que les tâches actives ne seront pas fermées par l'ajout du présent suivi."
                        @update:model-value="selectedTypeSuiviChanged"
                        lazy-rules
                        hide-bottom-space
                        map-options
                        options-dense
                        dense
                    />
                    <q-item
                        v-if="selectedTypeSuivi && selectedTypeSuivi.idTypeSuivi === 3"
                        tag="label"
                        :disable="!peutEnvoyerCourrielProspect"
                        :v-ripple="peutEnvoyerCourrielProspect"
                        class="q-px-xs"
                        dense
                    >
                        <q-item-section style="min-width: inherit" avatar>
                            <q-checkbox v-model="envoyerCourrielProspect" :disable="!peutEnvoyerCourrielProspect" dense />
                        </q-item-section>
                        <q-item-section>
                            <q-item-label :class="{ 'text-strike': !peutEnvoyerCourrielProspect }">Envoyer le suivi par courriel au prospect</q-item-label>
                            <q-item-label v-if="!peutEnvoyerCourrielProspect" class="text-red" caption> Aucune adresse courriel a été trouvée pour le prospect </q-item-label>
                        </q-item-section>
                    </q-item>
                    <q-select
                        v-model="selectedEtatGroupe"
                        label="Délai de signature"
                        :options="etatsGroupes"
                        option-label="nom"
                        option-value="idEtatGroupe"
                        :loading="isLoadingEtatsGroupes"
                        :rules="[(val) => !!val || 'Le délai de signature est obligatoire']"
                        @update:model-value="selectedEtatGroupeChanged"
                        lazy-rules
                        hide-bottom-space
                        map-options
                        options-dense
                        dense
                    />

                    <template v-if="selectedTypeSuivi && selectedEtatGroupe && !selectedEtatGroupe.estNonActif">
                        <div class="text-subtitle1 text-bold">Prochain suivi:</div>
                        <q-input
                            :model-value="dateProchainSuivi"
                            label="Date"
                            :rules="[validerDateProchainSuivi]"
                            hint="Pour une durée de 15 minutes."
                            lazy-rules
                            hide-bottom-space
                            readonly
                            dense
                            @update:model-value="setSelectedTaches()"
                        >
                            <template v-slot:append>
                                <q-icon v-if="dateProchainSuivi" name="cancel" class="cursor-pointer q-mr-xs" @click="dateProchainSuivi = ''" />
                                <q-icon name="event" class="cursor-pointer" @click="ouvrirDialogAgendaUsager" />
                            </template>
                        </q-input>
                        <q-select
                            v-model="idTypeProchaineRelance"
                            label="Préférence client"
                            :options="typesProchainesRelances"
                            option-label="nom"
                            option-value="idTypeProchaineRelance"
                            :loading="isLoadingTypesProchainesRelances"
                            map-options
                            emit-value
                            options-dense
                            dense
                        />
                        <div class="q-gutter-x-sm">
                            <q-radio v-model="estAttitrerAutreConseiller" label="Attitrer à moi-même" :val="false" dense />
                            <q-radio v-model="estAttitrerAutreConseiller" :val="true" :disable="!isLoadingAutresConseillers && !autresConseillers.length" dense>
                                <q-select
                                    v-model="selectedAutreConseiller"
                                    :options="autresConseillers"
                                    option-label="nomEmploye"
                                    option-value="idEmploye"
                                    :loading="isLoadingAutresConseillers"
                                    :disable="!isLoadingAutresConseillers && !autresConseillers.length"
                                    :display-value="`Attitrer à ${selectedAutreConseiller ? selectedAutreConseiller.nomEmploye : 'un autre conseiller'}`"
                                    borderless
                                    map-options
                                    options-dense
                                    dense
                                />
                            </q-radio>
                        </div>
                    </template>
                    <template v-if="selectedEtatGroupe && selectedEtatGroupe.estNonActif">
                        <div class="text-subtitle1 text-bold">Conclusion:</div>
                        <q-option-group
                            v-model="idTypeCompetiteur"
                            color="primary"
                            :options="[
                                { label: 'Non vendu', value: 1 },
                                { label: 'Vendu par un autre', value: 2 }
                            ]"
                            @update:model-value="idTypeCompetiteurChanged"
                            inline
                            dense
                        />
                        <q-select
                            v-model="selectedCompetiteur"
                            label="Raison"
                            :options="filteredCompetiteurs"
                            option-label="nom"
                            option-value="idCompetiteur"
                            :loading="isLoadingCompetiteurs"
                            :rules="[(val) => !!val || !!autreRaison || 'Vous devez obligatoirement saisir une raison ou une autre raison. Vous pouvez saisir les deux si nécessaire.']"
                            lazy-rules
                            hide-bottom-space
                            clearable
                            map-options
                            options-dense
                            dense
                        />
                        <q-input v-model="autreRaison" type="textarea" label="Autre raison" @change="autreRaison = autreRaison.trim()" hide-bottom-space autogrow dense />
                    </template>
                    <template v-if="isVisibleTaches">
                        <div class="text-subtitle1 text-bold">Tâches à compléter:</div>
                        <div class="q-gutter-y-xs">
                            <div v-for="t in taches" :key="t.id">
                                <q-checkbox
                                    :model-value="selectedTaches.includes(t.id)"
                                    @update:model-value="selectedTaches.includes(t.id) ? selectedTaches.splice(selectedTaches.indexOf(t.id), 1) : selectedTaches.push(t.id)"
                                    dense
                                >
                                    {{ t.tache }} ({{ t.dateDebut }})
                                </q-checkbox>
                            </div>
                        </div>
                    </template>
                </q-card-section>
                <q-card-actions align="right">
                    <q-btn color="primary" label="Annuler" :disable="isSubmitting" @click="hide" flat />
                    <q-btn color="primary" type="submit" label="Confirmer" :loading="isSubmitting" flat />
                </q-card-actions>
            </q-form>
        </q-card>
    </q-dialog>
</template>

<script>
import { date } from "quasar";
const { formatDate } = date;

import EDialogAgendaUsager from "./EDialogAgendaUsager.vue";

export default {
    name: "e-dialog-ajouter-suivi",
    emits: ["ok", "hide"],
    props: {
        title: {
            type: String,
            default: "Inscrire un suivi"
        },
        idGroupeProjetPropose: {
            type: Number,
            default: null
        },
        estCompagnie: {
            type: Boolean,
            default: false
        },
        idTypeSuivi: {
            type: Number,
            default: null
        },
        idTache: {
            type: Number,
            default: null
        },
        taches: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            isSubmitting: false,
            description: "",
            fichiers: [],
            isLoadingTypesSuivis: false,
            typesSuivis: [],
            selectedTypeSuivi: null,
            isLoadingEtatsGroupes: false,
            etatsGroupes: [],
            selectedEtatGroupe: null,
            peutEnvoyerCourrielProspect: false,
            envoyerCourrielProspect: false,
            dateProchainSuivi: "",
            isLoadingTypesProchainesRelances: false,
            typesProchainesRelances: [],
            idTypeProchaineRelance: null,
            estAttitrerAutreConseiller: false,
            isLoadingAutresConseillers: false,
            autresConseillers: [],
            selectedAutreConseiller: null,
            idTypeCompetiteur: null,
            isLoadingCompetiteurs: false,
            competiteurs: [],
            selectedCompetiteur: null,
            autreRaison: "",
            selectedTaches: []
        };
    },
    computed: {
        filteredCompetiteurs() {
            return this.competiteurs.filter((x) => x.idTypeCompetiteur === this.idTypeCompetiteur);
        },
        isVisibleTaches() {
            return this.selectedTypeSuivi && this.selectedEtatGroupe && !this.selectedEtatGroupe.estNonActif && !this.idTache && this.taches.length;
        }
    },
    methods: {
        show() {
            this.$refs.dialog.show();
        },
        hide() {
            this.$refs.dialog.hide();
        },
        onDialogHide() {
            this.$emit("hide");
        },
        onCancelClick() {
            this.hide();
        },
        async onOKClick() {
            const etatGroupeDefaut = this.etatsGroupes.find((x) => x.estDefaut);
            if (etatGroupeDefaut && !etatGroupeDefaut.estNonActif && this.selectedEtatGroupe.estNonActif) {
                this.$q
                    .dialog({
                        title: "Confirmation",
                        message: "En fermant le dossier du client, toutes vos tâches non complétées liées à celui-ci seront supprimées. Voulez-vous vraiment continuer?",
                        cancel: true
                    })
                    .onOk(() => this.ajouterSuivi(true));
            } else {
                this.ajouterSuivi();
            }
        },
        async ajouterSuivi(fermer = false) {
            this.isSubmitting = true;

            try {
                const fichiers = this.fichiers.length ? await this.$api.fichiers.upload(this.fichiers) : [];

                let idsTachesACompleter = null;
                if (this.idTache) {
                    if (this.selectedTypeSuivi.dateObligatoire) {
                        idsTachesACompleter = this.idTache.toString();
                    }
                } else if (this.isVisibleTaches && this.selectedTaches.length) {
                    idsTachesACompleter = this.selectedTaches.join(",");
                } else if (fermer) {
                    idsTachesACompleter = this.taches.map((x) => x.id).join(",");
                }

                const { idSuiviProspect, suivi, taches } = await this.$api.prospects.ajoutSuiviGroupeProjet({
                    idGroupeProjet: this.idGroupeProjetPropose,
                    idTypeSuivi: this.selectedTypeSuivi.idTypeSuivi,
                    description: this.description,
                    estCompagnie: this.estCompagnie,
                    etat: this.selectedEtatGroupe.idEtatGroupe,
                    dateRappelle: this.dateProchainSuivi || null,
                    idEmployeTransfert: this.selectedAutreConseiller ? this.selectedAutreConseiller.idEmploye : null,
                    idTypeProchaineRelance: this.idTypeProchaineRelance,
                    dureeTacheRappel: 15,
                    idsTachesACompleter,
                    envoyeAuClient: this.envoyerCourrielProspect
                });

                suivi.fichiers = fichiers.map((x) => ({
                    id: x.idFichier,
                    nom: x.nomFichier
                }));

                const etatGroupeDefaut = this.etatsGroupes.find((x) => x.estDefaut);
                if (etatGroupeDefaut && !etatGroupeDefaut.estNonActif && this.selectedEtatGroupe.estNonActif) {
                    await this.$api.prospects.modifierCompetiteurGroupeProjetPropose({
                        idGroupeProjetPropose: this.idGroupeProjetPropose,
                        idCompetiteur: this.selectedCompetiteur ? this.selectedCompetiteur.idCompetiteur : null,
                        autreRaison: this.autreRaison || null,
                        desactiverGroupe: true,
                        idTypeCompetiteur: this.idTypeCompetiteur
                    });
                }

                if (fichiers.length) {
                    await this.$api.prospects.ajoutFichiersSuiviProspect(fichiers.map((x) => ({ idSuiviProspect, idFichier: x.idFichier })));
                }

                if (this.envoyerCourrielProspect) {
                    this.$api.courriels
                        .envoyerSuiviProspectAuClient({
                            idSuiviProspect,
                            idGroupeProjetPropose: this.idGroupeProjetPropose,
                            estCompagnie: this.estCompagnie
                        })
                        .catch(() => this.$q.notify({ type: "negative", message: "Une erreur est survenue lors de l'envoi du suivi au client." }));
                }

                this.$q.notify({ type: "positive", message: "Suivi inscrit avec succès." });

                this.$emit("ok", {
                    idSuiviProspect,
                    tachesCompletees: idsTachesACompleter ? idsTachesACompleter.split(",").map((x) => parseInt(x)) : [],
                    suivi,
                    taches
                });
                this.hide();
            } catch (error) {
                this.$q.notify({ type: "negative", message: "Une erreur est survenue lors de l'inscription du suivi." });
            } finally {
                this.isSubmitting = false;
            }
        },
        async getTypesSuivis() {
            this.isLoadingTypesSuivis = true;

            try {
                this.typesSuivis = await this.$api.prospects.getTypesSuivis({ tout: false });
                this.selectedTypeSuivi = this.typesSuivis.find((x) => x.idTypeSuivi === this.idTypeSuivi) || null;
            } catch (error) {
                this.$q.notify({ type: "negative", message: "Une erreur est survenue lors du chargement des types de suivis." });
            } finally {
                this.isLoadingTypesSuivis = false;
            }
        },
        async getEtatsGroupes() {
            this.isLoadingEtatsGroupes = true;

            try {
                this.etatsGroupes = await this.$api.prospects.getEtatsGroupes({ idGroupeProjetPropose: this.idGroupeProjetPropose });
                this.selectedEtatGroupe = this.etatsGroupes.find((x) => x.estDefaut) || null;
            } catch (error) {
                this.$q.notify({ type: "negative", message: "Une erreur est survenue lors du chargement des délais de signature." });
            } finally {
                this.isLoadingEtatsGroupes = false;
            }
        },
        async verifierCourrielProspect() {
            try {
                const courriel = await this.$api.prospects.getCourrielGroupeProjetPropose({ idGroupeProjetPropose: this.idGroupeProjetPropose, estCompagnie: this.estCompagnie });
                this.peutEnvoyerCourrielProspect = !!courriel;
            } catch (error) {
                this.$q.notify({ type: "negative", message: "Une erreur est survenue lors de la vérification de l'adresse courriel du prospect." });
                this.peutEnvoyerCourrielProspect = false;
            }
        },
        async getTypesProchainesRelances() {
            this.isLoadingTypesProchainesRelances = true;

            try {
                const typesProchainesRelances = await this.$api.prospects.getTypesProchainesRelances();
                this.typesProchainesRelances = [{ idTypeProchaineRelance: null, nom: "Aucune", description: null }, ...typesProchainesRelances];
            } catch (error) {
                this.$q.notify({ type: "negative", message: "Une erreur est survenue lors du chargement des préférences du client." });
            } finally {
                this.isLoadingTypesProchainesRelances = false;
            }
        },
        async getAutresConseillers() {
            this.isLoadingAutresConseillers = true;

            try {
                this.autresConseillers = await this.$api.prospects.getVendeursSite();
            } catch (error) {
                this.$q.notify({ type: "negative", message: "Une erreur est survenue lors du chargement des autres conseillers." });
            } finally {
                this.isLoadingAutresConseillers = false;
            }
        },
        async getCompetiteurs() {
            this.isLoadingCompetiteurs = true;

            try {
                this.competiteurs = await this.$api.prospects.getCompetiteurs();
            } catch (error) {
                this.$q.notify({ type: "negative", message: "Une erreur est survenue lors du chargement des raisons de fermeture de dossier." });
            } finally {
                this.isLoadingCompetiteurs = false;
            }
        },
        selectedTypeSuiviChanged() {
            this.envoyerCourrielProspect = false;
        },
        selectedEtatGroupeChanged() {
            if (this.selectedEtatGroupe.estNonActif) {
                this.envoyerCourrielProspect = false;
                this.dateProchainSuivi = "";
                this.idTypeProchaineRelance = null;
                this.selectedAutreConseiller = null;
                this.estAttitrerAutreConseiller = false;
            } else {
                this.idTypeCompetiteur = null;
                this.selectedCompetiteur = null;
                this.autreRaison = "";
            }
        },
        idTypeCompetiteurChanged() {
            this.selectedCompetiteur = null;
        },
        ouvrirDialogAgendaUsager() {
            this.$q
                .dialog({
                    component: EDialogAgendaUsager,
                    componentProps: {
                        etatGroupe: this.selectedEtatGroupe
                    }
                })
                .onOk((date) => (this.dateProchainSuivi = formatDate(date, "YYYY-MM-DD HH:mm")));
        },
        validerDateProchainSuivi(val) {
            const date = val ? new Date(val) : null;

            if (!date && this.selectedTypeSuivi.dateObligatoire) {
                return "La date du prochain suivi est obligatoire";
            }

            const maxDate = new Date();

            switch (this.selectedEtatGroupe.nom) {
                case "0-3m":
                    maxDate.setMonth(maxDate.getMonth() + 3);
                    break;
                case "3-6m":
                    maxDate.setMonth(maxDate.getMonth() + 6);
                    break;
                case "6-12m":
                    maxDate.setFullYear(maxDate.getFullYear() + 1);
                    break;
                default:
                    maxDate.setFullYear(maxDate.getFullYear() + 2);
                    break;
            }

            if (date >= maxDate) {
                return `Le délai de signature sélectionné exige une date antérieure au ${formatDate(maxDate, "YYYY-MM-DD")}`;
            }

            return true;
        },
        onRejected() {
            this.$q.notify({ type: "warning", message: "La taille total des fichiers ne doit pas dépasser 30 Mo" });
        }
    },
    watch: {
        estAttitrerAutreConseiller(value) {
            this.selectedAutreConseiller = value && this.autresConseillers.length ? this.autresConseillers[0] : null;
        },
        dateProchainSuivi: {
            handler() {
                if (this.taches.length) {
                    if (!this.dateProchainSuivi) {
                        this.selectedTaches = [];
                    } else {
                        for (const tache of this.taches) {
                            this.selectedTaches.push(tache.id);
                        }
                    }
                }
            },
            deep: true
        }
    },
    mounted() {
        this.getTypesSuivis();
        this.verifierCourrielProspect();
        this.getTypesProchainesRelances();
        this.getAutresConseillers();

        Promise.all([this.getEtatsGroupes(), this.getCompetiteurs()]).then(() => {
            /* Après avoir chargé les états de groupes et les compétiteurs,
            si l'état de groupe sélectionné est non actif,
            assigner les valeurs par défaut à la conslusion */
            if (this.selectedEtatGroupe && this.selectedEtatGroupe.estNonActif && (this.selectedEtatGroupe.idCompetiteur || this.selectedEtatGroupe.autreRaison)) {
                this.idTypeCompetiteur = this.selectedEtatGroupe.idTypeCompetiteur;
                this.selectedCompetiteur = this.filteredCompetiteurs.find((x) => x.idCompetiteur === this.selectedEtatGroupe.idCompetiteur) || null;
                this.autreRaison = this.selectedEtatGroupe.autreRaison || "";
            }
        });
    }
};
</script>
