<template>
    <q-select
        ref="select"
        :model-value="selectedAdresse"
        placeholder="Rechercher une adresse..."
        option-id="placeId"
        option-label="description"
        :options="adresses"
        :loading="isLoading"
        :input-debounce="500"
        @filter="filter"
        @update:model-value="setSelectedAdresse"
        use-input
        fill-input
        hide-selected
        hide-dropdown-icon
    >
        <template v-slot:prepend>
            <q-icon name="location_searching" />
        </template>
        <template v-if="aucunResultat" v-slot:no-option>
            <q-item dense>
                <q-item-section class="text-grey"> Aucune adresse trouvée </q-item-section>
            </q-item>
        </template>
        <template v-if="$slots.append" v-slot:append>
            <slot name="append"></slot>
        </template>
    </q-select>
</template>

<script>
export default {
    name: "e-select-adresse",
    emits: ["selected"],
    data() {
        return {
            isLoading: false,
            adresses: [],
            aucunResultat: false,
            selectedAdresse: null
        };
    },
    methods: {
        filter(val, update) {
            update(async () => {
                const input = val.trim();

                if (input.length > 2 && input.toLowerCase() !== (this.selectedAdresse?.description || "").toLowerCase()) {
                    this.aucunResultat = false;

                    this.isLoading = true;

                    try {
                        this.adresses = await this.$api.googleApi
                            .getLocations({ location: input })
                            .then((adresses) => adresses.map((x) => ({ place_id: x.place_id, description: x.description })));

                        this.aucunResultat = !this.adresses.length;

                        this.$refs.select.refresh(0);
                    } catch (error) {
                        this.$utils.catch({ error, message: "Une erreur est survenue lors de la recherche.", isCancel: true });
                    } finally {
                        this.isLoading = false;
                    }
                }
            });
        },
        async setSelectedAdresse(selectedAdresse) {
            this.selectedAdresse = selectedAdresse;

            const placeId = this.selectedAdresse?.place_id;

            if (placeId) {
                this.isLoading = true;

                try {
                    const { address_components } = await this.$api.googleApi.getPlaceDetails({ placeId });

                    this.$emit("selected", {
                        numCivique: address_components.find((x) => x.types[0] === "street_number")?.long_name || null,
                        rue: address_components.find((x) => x.types[0] === "route")?.long_name || null,
                        ville: address_components.find((x) => x.types[0] === "locality")?.long_name || null,
                        province: address_components.find((x) => x.types[0] === "administrative_area_level_1")?.long_name || null,
                        pays: address_components.find((x) => x.types[0] === "country")?.long_name || null,
                        codePostal: address_components.find((x) => x.types[0] === "postal_code")?.long_name || null
                    });
                } catch (error) {
                    this.$q.notify({ type: "negative", message: "Une erreur est survenue lors de la récupération de l'adresse." });
                } finally {
                    this.isLoading = false;
                }
            }
        },
        updateAdresse(adresse, place_id) {
            const description = this.$filters.adresse(adresse, { appartement: false, complement: false }),
                selectedAdresse = { ...adresse, place_id, description };

            this.adresses = [selectedAdresse];

            this.selectedAdresse = selectedAdresse;

            this.$refs.select.updateInputValue(description, true);
            this.$refs.select.refresh(0);
        }
    }
};
</script>
