export default (api) => ({
    getLocations: ({ location = "" } = {}) =>
        api
            .post("api/google-api/get-suggest-locations", {
                location
            })
            .then((r) => r.data),
    getPlaceDetails: ({ placeId = "" } = {}) =>
        api
            .post("api/google-api/get-place-details", {
                placeId
            })
            .then((r) => r.data),
    getGeocode: ({ adresse = "" } = {}) =>
        api
            .post("api/google-api/get-geocode", {
                adresse
            })
            .then((r) => r.data)
});
