<template>
    <div ref="ganttContainer"></div>
</template>

<script>
import { gantt } from "dhtmlx-gantt";
export default {
    props: {
        tasks: {
            type: Object,
            default() {
                return { data: [], links: [] };
            }
        },
        columns: {
            type: Array,
            default() {
                return [{ name: "text", label: "Tâche", width: "*", tree: true }];
            }
        },
        linkValidationFunction: {
            type: Function,
            default: () => {
                return true;
            }
        },
        beforeTaskMoveFunction: {
            type: Function,
            default: () => {
                return true;
            }
        },
        locale: {
            type: String,
            default: "fr"
        },
        dateFormat: {
            type: String,
            default: "%Y-%m-%d"
        },
        readonly: {
            type: Boolean,
            default: false
        },
        dragMove: {
            type: Boolean,
            default: true
        },
        dragLinks: {
            type: Boolean,
            default: true
        },
        dragProgress: {
            type: Boolean,
            default: true
        },
        dragResize: {
            type: Boolean,
            default: true
        },
        orderBranch: {
            type: Boolean,
            default: false
        },
        dureeTotale: {
            type: Number,
            default: 0
        }
    },

    mounted() {
        gantt.config.date_format = this.dateFormat;
        gantt.i18n.setLocale(this.locale);

        gantt.config.readonly = this.readonly;

        gantt.config.drag_move = this.dragMove; //disables the possibility to move tasks by dnd
        gantt.config.drag_links = this.dragLinks; //disables the possibility to create links by dnd
        gantt.config.drag_progress = this.dragProgress; //disables the possibility to change the task //progress by dragging the progress knob
        gantt.config.drag_resize = this.dragResize; //disables the possibility to resize tasks by dnd
        gantt.config.order_branch = this.orderBranch;
        gantt.config.columns = this.columns;

        gantt.config.work_time = true;
        gantt.setWorkTime({ day: 6, hours: ["7:00-18:00"] });
        gantt.setWorkTime({ day: 0, hours: ["7:00-18:00"] });
        gantt.templates.scale_cell_class = function (date) {
            if (date.getDay() == 0 || date.getDay() == 6) {
                return "weekend";
            }
        };
        gantt.templates.timeline_cell_class = function (task, date) {
            if (date.getDay() == 0 || date.getDay() == 6) {
                return "weekend";
            }
        };

        gantt.templates.task_end_date = function (date) {
            return gantt.templates.task_date(new Date(date.valueOf() - 1));
        };

        var gridDateToStr = gantt.date.date_to_str("%Y-%m-%d");
        gantt.templates.grid_date_format = function (date, column) {
            if (column === "end_date") {
                return gridDateToStr(new Date(date.valueOf() - 1));
            } else {
                return gridDateToStr(date);
            }
        };

        gantt.plugins({
            tooltip: true
        });

        gantt.init(this.$refs.ganttContainer);
        gantt.parse(this.$props.tasks);

        gantt.createDataProcessor((entity, action, data, id) => {
            this.$emit(`${entity}-updated`, id, action, data);
        });

        var drag_id = null;
        gantt.attachEvent("onRowDragStart", function (id) {
            drag_id = id;
            return true;
        });
        gantt.attachEvent("onRowDragEnd", function () {
            drag_id = null;
            gantt.render();
        });

        gantt.templates.grid_row_class = function (start, end, task) {
            if (drag_id && task.id != drag_id) {
                if (task.$level != gantt.getTask(drag_id).$level) return "cant-drop";
            }
            return "";
        };

        gantt.attachEvent("onLinkValidation", this.linkValidationFunction);
        gantt.attachEvent("onBeforeTaskMove", this.beforeTaskMoveFunction);

        gantt.attachEvent("onAfterLinkDelete", (id, item) => {
            this.$emit("deleteLink", id, item);
        });

        gantt.attachEvent("onAfterLinkAdd", (id, item) => {
            this.$emit("addLink", id, item);
        });

        var dureeTotale = this.dureeTotale;

        gantt.ext.tooltips.tooltipFor({
            selector: ".gantt_grid_head_duration",
            html: function () {
                return "Durée total : " + dureeTotale;
            }
        });

        gantt.templates.tooltip_text = function (start, end, task) {
            var startDate = new Date(task.start_date).toLocaleDateString("fr-ca", { year: "numeric", month: "numeric", day: "numeric" });
            var endDate = new Date(new Date(task.end_date).setDate(new Date(task.end_date).getDate() - 1));
            endDate = new Date(endDate).toLocaleDateString("fr-ca", { year: "numeric", month: "numeric", day: "numeric" });
            return "<b>Étape:</b> " + task.text + "<br/><b>Date début:</b> " + startDate + "<br/><b>Date fin:</b> " + endDate;
        };
    }
};
</script>

<style>
.weekend {
    background: #ffd1d7 !important;
}
div.gantt_task_content {
    white-space: nowrap !important;
    overflow: visible !important;
    color: black;
}
@import "~dhtmlx-gantt/codebase/dhtmlxgantt.css";
</style>
