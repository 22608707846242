import { createRouter, createWebHistory } from "vue-router";

import { useAuthStore } from "@/stores";

import Login from "../pages/Login";
import HeaderFooter from "../layouts/HeaderFooter";

const beforeEnter = (to) => {
    if (to.meta.droits) {
        const authStore = useAuthStore(),
            droits = authStore.droits;

        if (to.meta.droits.every((d) => !droits[d])) {
            return "/home";
        }
    }
};

const routes = [
    {
        path: "/login",
        name: "Login",
        component: Login,
        meta: {
            title: "Authentification"
        }
    },
    {
        path: "/",
        name: "HeaderFooter",
        component: HeaderFooter,
        redirect: "/home",
        beforeEnter: async () => {
            try {
                const authStore = useAuthStore();

                await authStore.getUsager();

                return true;
            } catch (error) {
                return false;
            }
        },
        children: [
            {
                path: "home",
                name: "Home",
                component: () => import("../pages/Home"),
                meta: {
                    title: "Accueil"
                }
            },
            {
                path: "agenda",
                name: "Agenda",
                component: () => import("../pages/Agenda"),
                beforeEnter,
                meta: {
                    droits: ["Agenda"],
                    title: "Agenda"
                }
            },
            {
                path: "ancienne-erp/:date/:params",
                name: "AncienneErp",
                component: () => import("../pages/AncienneErp"),
                props: (route) => {
                    let params;

                    try {
                        params = JSON.parse(window.atob(route.params.params));
                    } catch (error) {
                        params = {};
                    }

                    return {
                        date: route.params.date || window.btoa(new Date().toJSON()),
                        url: params.url || null,
                        data: params.data || {}
                    };
                },
                beforeEnter
            },
            {
                path: "prospects",
                name: "ProspectListe",
                component: () => import("../pages/ProspectListe"),
                beforeEnter,
                meta: {
                    droits: ["FicheProspect3"],
                    title: "Prospect"
                }
            },
            {
                path: "prospects/:idProspect",
                name: "Prospect",
                component: () => import("../pages/Prospect"),
                beforeEnter,
                meta: {
                    droits: ["FicheProspect3"],
                    title: "Prospect"
                }
            },
            {
                path: "feuille-production-dessin",
                name: "FeuilleProductionDessin",
                component: () => import("../pages/FeuilleProductionDessin"),
                beforeEnter,
                meta: {
                    droits: ["FeuilleProductionDessin"],
                    title: "Feuille de production"
                }
            },
            {
                path: "production-usine",
                name: "ProductionUsine",
                component: () => import("../pages/ProductionUsine"),
                beforeEnter,
                meta: {
                    droits: ["ProductionUsine"],
                    title: "Calendrier de production"
                }
            },
            {
                path: "cedule-livraison",
                name: "CeduleLivraison",
                component: () => import("../pages/CeduleLivraison"),
                beforeEnter,
                meta: {
                    droits: ["CeduleDeLivraison"],
                    title: "Cédule de livraison"
                }
            },
            {
                path: "gestion-droits-db",
                name: "GestionDroitsDB",
                component: () => import("../pages/GestionDroitsDB"),
                beforeEnter,
                meta: {
                    droits: ["GestionDroitsDB"],
                    title: "Gestion des droits DB"
                }
            },
            {
                path: "produits-maj-prix",
                name: "ProduitsMajPrix",
                component: () => import("../pages/ProduitsMajPrix"),
                beforeEnter,
                meta: {
                    droits: ["ProduitsTemplates"],
                    title: "Produits selon m.a.j de prix"
                }
            },
            {
                path: "gestion-marges-tcost",
                name: "GestionMargesTcost",
                component: () => import("../pages/GestionMargesTcost"),
                beforeEnter,
                meta: {
                    droits: ["GestionMargeTCOST"],
                    title: "Gestion des marges TCOST"
                }
            },
            {
                path: "gestion-objectifs-sites",
                name: "GestionObjectifsSites",
                component: () => import("../pages/GestionObjectifsSites"),
                beforeEnter,
                meta: {
                    droits: ["GestionObjectifsSites"],
                    title: "Gestion objectifs sites"
                }
            },
            {
                path: "calendrier-dessin-usinage",
                name: "CalendrierDessinUsinage",
                component: () => import("../pages/CalendrierDessinUsinage"),
                beforeEnter,
                meta: {
                    droits: ["CalendrierDessinUsinage"],
                    title: "Calendrier dessin usinage"
                }
            },
            {
                path: "problemes-non-regles/:idTypeProbleme",
                name: "ProblemesNonRegles",
                component: () => import("../pages/ProblemesNonRegles"),
                beforeEnter,
                props: (route) => ({
                    idTypeProbleme: parseInt(route.params.idTypeProbleme, 10) || null
                }),
                meta: {
                    droits: ["ProblemesChantierNonReglesV2", "SAVNonReglesV2", "ProblemesCNESSTNonRegles", "ProblemesConfidentielNonRegles"],
                    title: "Problèmes non réglés"
                }
            },
            {
                path: "planification-mep",
                name: "PlanificationMEP",
                component: () => import("../pages/PlanificationMEP"),
                beforeEnter,
                meta: {
                    droits: ["PlanificationMEP"],
                    title: "Planification de mise en production"
                }
            },
            {
                path: "stats-dessin",
                name: "StatistiquesDessin",
                component: () => import("../pages/StatistiquesDessin"),
                beforeEnter,
                meta: {
                    droits: ["StatsDessin"],
                    title: "Statistiques de dessin"
                }
            },
            {
                path: "suivi-recevables",
                name: "SuiviRecevables",
                component: () => import("../pages/SuiviRecevables"),
                beforeEnter,
                meta: {
                    droits: ["SuiviRecevables"],
                    title: "Suivi des recevables"
                }
            },
            {
                path: "rapport-paies-conseillers",
                name: "RapportPaiesConseillers",
                component: () => import("../pages/RapportPaiesConseillers"),
                beforeEnter,
                meta: {
                    droits: ["RapportsPaiesConseillers"],
                    title: "Rapports de paies des conseillers"
                }
            },
            {
                path: "rapport-requetes-contrats",
                name: "RapportRequetesContrats",
                component: () => import("../pages/RapportRequetesContrats"),
                beforeEnter,
                meta: {
                    droits: ["RequetesContrats"],
                    title: "Requêtes contrats"
                }
            },
            {
                path: "tableau-bord-charges-projets",
                name: "TableauBordChargesProjets",
                component: () => import("../pages/TableauBordChargesProjets"),
                beforeEnter,
                meta: {
                    droits: ["TableauDeBordChargesProjets"],
                    title: "Tableau de bord des chargés de projets"
                }
            },
            {
                path: "tableau-bord-inspecteurs",
                name: "TableauBordInspecteurs",
                component: () => import("../pages/TableauBordInspecteurs"),
                beforeEnter,
                meta: {
                    droits: ["TableauDeBordICQ"],
                    title: "Tableau de bord des inspecteurs"
                }
            },
            {
                path: "contrats-non-approuves",
                name: "ContratsNonApprouves",
                component: () => import("../pages/ContratsNonApprouves"),
                beforeEnter,
                meta: {
                    droits: ["ContratsNonApprouves"],
                    title: "Contrats non approuvés"
                }
            },
            {
                path: "ouverture-dossiers",
                name: "OuvertureDossiers",
                component: () => import("../pages/OuvertureDossiers"),
                beforeEnter,
                meta: {
                    droits: ["OuvertureDossiers"],
                    title: "Ouverture de dossiers"
                }
            },
            {
                path: "traductions-tcost",
                name: "TraductionsTcost",
                component: () => import("../pages/TraductionsTcost"),
                beforeEnter,
                meta: {
                    droits: ["TraductionsTCOST"],
                    title: "Traductions TCOST"
                }
            },
            {
                path: "commandes-a-comptabiliser",
                name: "CommandesAComptabiliser",
                component: () => import("../pages/CommandesAComptabiliser"),
                beforeEnter,
                meta: {
                    droits: ["CommandesAComptabiliser"],
                    title: "Commandes non comptabilisées dans Maximum"
                }
            },
            {
                path: "inspection-rapide-mobile",
                name: "InspectionRapideMobile",
                component: () => import("../pages/InspectionRapideMobile"),
                beforeEnter,
                meta: {
                    droits: ["InspectionRapideMobile"],
                    title: "Inspection Rapide Mobile"
                }
            },
            {
                path: "comparatif-erp-vs-maximum",
                name: "ComparatifERPvsMaximum",
                component: () => import("../pages/ComparatifERPvsMaximum"),
                beforeEnter,
                meta: {
                    droits: ["ComparatifContratICC"],
                    title: "Comparatif ERP vs Maximum"
                }
            },
            {
                path: "ajouter-invite",
                name: "AjouterInvite",
                component: () => import("../pages/AjouterInvite"),
                beforeEnter,
                meta: {
                    droits: ["AjoutInvite"],
                    title: "Ajout d'un invité"
                }
            },
            {
                path: "banque-prospects",
                name: "BanqueProspects",
                component: () => import("@/pages/BanqueProspects"),
                beforeEnter,
                meta: {
                    droits: ["BanqueProspectConseiller"],
                    title: "Banque prospects"
                }
            },
            {
                path: "calendrier-planification",
                name: "CalendrierPlanification",
                component: () => import("@/pages/CalendrierPlanification"),
                beforeEnter,
                meta: {
                    droits: ["CalendrierPlanification"],
                    title: "Calendrier de planification"
                }
            },
            {
                path: "ListeChargementMaximum",
                name: "ListeChargementMaximum",
                component: () => import("@/pages/ListeChargementMaximum"),
                beforeEnter,
                meta: {
                    droits: ["ListeDeChargementICC"],
                    title: "Liste De Chargement Maximum"
                }
            },
            {
                path: "nouveaux-prospects",
                name: "NouveauxProspects",
                component: () => import("@/pages/NouveauxProspects"),
                beforeEnter,
                meta: {
                    droits: ["NouveauxProspects"],
                    title: "Nouveaux prospects"
                }
            },
            {
                path: "statistiques-inscription-prospect",
                name: "StatistiquesInscriptionProspect",
                component: () => import("@/pages/StatInscriptionProspect"),
                beforeEnter,
                meta: {
                    droits: ["StatistiqueSAC"],
                    title: "Statistiques d'inscription des prospects"
                }
            },
            {
                path: "priorite-des-plans",
                name: "PrioriteDesPlans",
                component: () => import("../pages/PrioriteDesPlans"),
                beforeEnter,
                meta: {
                    droits: ["PrioriteDesPlans"]
                }
            },
            {
                path: "gantt-etapes-construction/:idContrat",
                name: "GanttEtapesConstruction",
                component: () => import("@/pages/GanttEtapesConstruction"),
                beforeEnter,
                props: (route) => ({
                    idContrat: parseInt(route.params.idContrat, 10) || null
                }),
                meta: {
                    droits: ["GanttEtapesConstruction"],
                    title: "Gantt selon les étapes de construction"
                }
            },
            {
                path: "gestion-alertes-courriels",
                name: "GestionAlertesCourriels",
                component: () => import("@/pages/GestionAlertesCourriels"),
                beforeEnter,
                meta: {
                    droits: ["GestionAlertesCourriels"],
                    title: "Gestion des alertes courriels"
                }
            },
            {
                path: "gestion-modeles",
                name: "GestionModeles",
                component: () => import("@/pages/GestionModeles"),
                beforeEnter,
                meta: {
                    droits: ["GestionModelesV2"],
                    title: "Gestion des modèles"
                }
            },
            {
                path: "rapport-vente-map",
                name: "RapportVenteMap",
                component: () => import("@/pages/RapportVenteMap"),
                beforeEnter,
                meta: {
                    droits: ["RapportVentesMap"],
                    title: "Rapport vente map"
                }
            },
            {
                path: "budgets-livraison",
                name: "BudgetsLivraison",
                component: () => import("@/pages/BudgetsLivraison"),
                beforeEnter,
                meta: {
                    title: "Budgets livraison"
                }
            },
            {
                path: "ajout-discussion",
                name: "AjoutDiscussion",
                component: () => import("@/pages/AjoutDiscussion"),
                beforeEnter,
                meta: {
                    title: "Ajout discussion"
                }
            },
            {
                path: "liste-commandes-non-transferees",
                name: "ListeCommandesNonTransferees",
                component: () => import("@/pages/ListeCommandesNonTransferees"),
                beforeEnter,
                meta: {
                    title: "Liste commandes non transférées"
                }
            },
            {
                path: "ajout-probleme",
                name: "AjoutProbleme",
                component: () => import("@/pages/AjoutProbleme"),
                beforeEnter,
                meta: {
                    title: "Ajout Problème"
                }
            },
            {
                path: "requetes-etiquettes",
                name: "RequetesEtiquettes",
                component: () => import("@/pages/RequetesEtiquettes"),
                beforeEnter,
                meta: {
                    title: "Requêtes étiquettes"
                }
            },
            {
                path: "creation-facture",
                name: "CreationFacture",
                component: () => import("@/pages/CreationFacture"),
                beforeEnter,
                meta: {
                    title: "Création facture"
                }
            },
            {
                path: "tableau-bord-marketing",
                name: "TableauBordMarketing",
                component: () => import("@/pages/TableauBordMarketing"),
                beforeEnter,
                meta: {
                    // droits: ["TableauBordMarketing"],
                    title: "Tableau de bord Marketing"
                }
            }
        ]
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

router.beforeEach((to) => {
    if (to.meta.title) {
        document.title = to.meta.title + " | ERP Laprise";
    } else {
        document.title = "ERP Laprise";
    }
});

export default router;
