export default (api) => ({
    getContratCalendrierPlanifLivraison: ({
        dateDebut = null,
        dateFin = null,
        numContrat = null,
        idEmployes = null,
        idSites = null,
        avecPrixContrat = null,
        filtreCompagnie = null
    } = {}) =>
        api
            .post("api/generiques/stored-procedure/getContratCalendrierPlanifLivraisonV2", {
                dateDebut,
                dateFin,
                numContrat,
                idEmployes,
                idSites,
                avecPrixContrat,
                filtreCompagnie
            })
            .then((r) => r.data[0]),
    getContratCalendrierPlanifProduction: ({
        dateDebut = null,
        dateFin = null,
        numContrat = null,
        idEmployes = null,
        idSites = null,
        avecPrixContrat = null,
        filtreCompagnie = null
    } = {}) =>
        api
            .post("api/generiques/stored-procedure/getContratCalendrierPlanifProductionV2", {
                dateDebut,
                dateFin,
                numContrat,
                idEmployes,
                idSites,
                avecPrixContrat,
                filtreCompagnie
            })
            .then((r) => r.data[0]),
    getDetailsContratCalendrierPlanification: ({ idContrat = null } = {}) =>
        api.post("api/generiques/stored-procedure/GetDetailsContratCalendrierPlanifV2", { idContrat }).then((r) => r.data),
    getNotesContrat: ({ idContrat = null, idTypeNoteContrat = null } = {}) =>
        api.post("api/generiques/stored-procedure/getNotesContrat", { idContrat, idTypeNoteContrat }).then((r) => r.data[0]),
    confirmerReceptionPaiementLivraison: ({ idContrat = null, confirmation = null } = {}) =>
        api.post("api/generiques/stored-procedure/AjoutConfirmationMontantLivraisonContrat", { idContrat, confirmation }),
    ajouterNoteMEP: ({ idContrat = null, note = null, idTypeNote = null } = {}) => api.post("api/generiques/stored-procedure/AjoutNoteContrat", { idContrat, note, idTypeNote }),
    getDateContratHistorique: ({ idContrat = null, typeDate = null } = {}) =>
        api.post("api/generiques/stored-procedure/GetDateContratHistoriqueV2", { idContrat, typeDate }).then((r) => r.data[0]),
    setDateLivraisonSouhaitee: ({ idContrat = null, date = null } = {}) => api.post("api/generiques/stored-procedure/SetDateLivraisonSouhaitee", { idContrat, date }),
    ajoutDateApproximativeMEP: ({ idContrat = null, date = null } = {}) => api.post("api/generiques/stored-procedure/AjoutDateApproximativeMEP", { idContrat, date }),
    rechercherContratPlanif: ({ numContrat = null, idCompagnie = null, mode = null } = {}) =>
        api.post("api/generiques/stored-procedure/RechercherContratCalendrierPlanifV2", { numContrat, idCompagnie, mode }).then((r) => r.data[0]),
    getDirecteursSites: ({ idSite = null, codeException = null } = {}) =>
        api.post("api/generiques/stored-procedure/GetDirecteursSitesV2", { idSite, codeException }).then((r) => r.data[0])
});
