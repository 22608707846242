<template>
    <q-layout view="hHh Lpr lff">
        <q-header elevated>
            <q-toolbar class="q-pr-xs q-pl-xs">
                <q-btn icon="menu" :disable="estVendeurBloque" @click="sideMenu = !sideMenu" flat dense round />
                <q-separator vertical dark class="q-ml-xs q-mr-sm" />
                <q-btn v-if="!!selectedUsagerCompagnie" to="/home" @click="sideMenu = false" class="q-mr-sm" flat dense round>
                    <q-avatar>
                        <q-img :src="require(`../../assets/${selectedUsagerCompagnie.logo}`)" />
                    </q-avatar>
                </q-btn>
                <q-space />
                <q-select
                    v-model="selectedUsagerCompagnie"
                    :options="usagerCompagnies"
                    option-label="nomCompagnie"
                    option-value="idCompagnie"
                    borderless
                    dark
                    flat
                    dense
                    options-dense
                    :options-dark="false"
                    @update:model-value="changerUsagerCompagnie"
                    class="gt-xs q-px-xs"
                />
                <q-separator vertical dark />
                <q-btn-dropdown flat stretch :persistent="$q.screen.lt.sm" ref="btnDropDownUsager" class="q-py-none q-px-xs">
                    <template v-slot:label>
                        <div class="row items-center no-wrap">
                            <q-icon left name="account_circle"></q-icon>
                            <div class="text-center">
                                <div v-if="$q.screen.gt.xs">{{ nomComplet }}</div>
                                <div v-else class="row justify-center" style="line-height: 1.4">
                                    <div class="col-12" style="white-space: normal">{{ nomComplet }}</div>
                                    <div class="col-xs-10" style="font-size: 8px; line-height: 1.5; white-space: normal">
                                        {{ selectedUsagerCompagnie ? selectedUsagerCompagnie.nomCompagnie : "" }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                    <q-list dense>
                        <q-item class="lt-sm">
                            <q-select
                                v-model="selectedUsagerCompagnie"
                                :options="usagerCompagnies"
                                :options-dark="false"
                                option-label="nomCompagnie"
                                option-value="idCompagnie"
                                behavior="dialog"
                                borderless
                                flat
                                dense
                                options-dense
                                @update:model-value="changerUsagerCompagnie"
                            />
                        </q-item>
                        <q-separator />
                        <q-item clickable v-close-popup @click="$q.fullscreen.toggle()">
                            <q-item-section avatar>
                                <q-icon :name="$q.fullscreen.isActive ? 'fullscreen_exit' : 'fullscreen'" />
                            </q-item-section>
                            <q-item-section>
                                <q-item-label>{{ $q.fullscreen.isActive ? "Quitter plein écran" : "Plein écran" }}</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-separator />
                        <q-item v-if="!estClient && !estReferenceur" @click="atteindreFonctionsAcceuil" clickable v-close-popup>
                            <q-item-section avatar>
                                <q-icon name="folder_shared" />
                            </q-item-section>
                            <q-item-section>
                                <q-item-label>Mes obj./stats/doss.</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item clickable v-close-popup>
                            <q-item-section avatar>
                                <q-icon name="manage_accounts" />
                            </q-item-section>
                            <q-item-section>
                                <q-item-label>Paramètres</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-separator />
                        <q-item @click="logout" clickable v-close-popup>
                            <q-item-section avatar>
                                <q-icon name="logout" />
                            </q-item-section>
                            <q-item-section>
                                <q-item-label>Déconnexion</q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>
                </q-btn-dropdown>
                <q-separator vertical dark />
                <q-btn
                    icon="search"
                    :disable="estVendeurBloque"
                    @click="(afficherDialogRechercheRapide = true), $refs.dialogRechercheRapide.resetProspect()"
                    class="q-ml-xs"
                    flat
                    round
                    dense
                />
            </q-toolbar>
        </q-header>

        <q-drawer v-model="sideMenu" :width="250" bordered>
            <q-scroll-area class="fit">
                <q-list padding dense>
                    <e-side-menu-item v-for="menu in menus" :key="menu.id" :menu="menu" />
                </q-list>
            </q-scroll-area>
        </q-drawer>

        <q-page-container>
            <router-view :key="$route.fullPath" v-if="!isLoading" />
        </q-page-container>
    </q-layout>

    <e-dialog-recherche-rapide ref="dialogRechercheRapide" v-model="afficherDialogRechercheRapide" />
</template>

<script>
import { mapState, mapWritableState, mapActions } from "pinia";

import { useAuthStore, useHeaderStore, useProspectListeStore } from "@/stores";

import ESideMenuItem from "./components/ESideMenuItem.vue";
import EDialogRechercheRapide from "./components/EDialogRechercheRapide.vue";

function removeTrailingSlash(str) {
    return str.replace(/\/+$/, "");
}

export default {
    name: "HeaderFooter",
    components: {
        ESideMenuItem,
        EDialogRechercheRapide
    },
    data() {
        return {
            usagerCompagnies: [],
            selectedUsagerCompagnie: null,
            menus: [],
            isLoading: true,
            afficherDialogRechercheRapide: false
        };
    },
    computed: {
        ...mapState(useAuthStore, ["estVendeurBloque", "nomComplet", "estClient", "estReferenceur", "idCompagnie"]),
        ...mapWritableState(useHeaderStore, ["sideMenu"])
    },
    methods: {
        ...mapActions(useAuthStore, ["getUsager", "getEstVendeurBloque"]),
        async changerUsagerCompagnie() {
            this.$q.loading.show();

            try {
                this.sideMenu = false;

                this.$refs.btnDropDownUsager.hide();

                await this.$api.auth.changerCompagnie(this.selectedUsagerCompagnie.idUsager);

                const [usagerMenus] = await Promise.all([this.$api.auth.getUsagerMenu(), this.getUsager()]);

                this.menus = usagerMenus;

                this.$refs.dialogRechercheRapide.reset();
                useProspectListeStore().$reset();
            } catch (error) {
                this.$q.notify({ type: "negative", message: "Une erreur est survenue lors du changement de compagnie." });
            } finally {
                this.$q.loading.hide();
                this.$router.push("/home");
            }
        },
        async logout() {
            try {
                this.$q.loading.show({ delay: 500 });
                await this.$api.auth.logout();
                localStorage.idCompagnie = null;
            } catch (error) {
                this.$q.notify({ type: "negative", message: "Une erreur est survenue lors de la déconnexion." });
            } finally {
                this.$q.loading.hide();
                window.location = "/login";
            }
        },
        atteindreFonctionsAcceuil() {
            this.$router.push({
                name: "AncienneErp",
                params: this.$utils.getParamsAncienneErp({
                    url: "FonctionsAccueil.aspx"
                })
            });
        },
        verifierChangementCompagnie() {
            if (localStorage.idCompagnie != this.idCompagnie) {
                window.location = "/home";
            }
        },
        redirectionExterne(e) {
            try {
                if (e.origin === removeTrailingSlash(process.env.VUE_APP_ANCIENNE_ERP_URL)) {
                    const redirection = typeof e.data != "object" ? JSON.parse(e.data) : e.data;

                    const url = redirection.url,
                        data = redirection.data;

                    if (url) {
                        if (url === "PrioriteDesPlans.aspx") {
                            this.$router.push("/priorite-des-plans");
                        } else if (url === "Agenda.aspx") {
                            this.$router.push("/agenda");
                        } else if (url === "SuivisDeChantier.aspx") {
                            this.$router.push({
                                path: "/ajout-discussion",
                                query: {
                                    idContrat: data.idContrat
                                }
                            });
                        } else if (url.includes("AjoutProbleme.aspx")) {
                            this.$router.push({
                                path: "/ajout-probleme",
                                query: {
                                    idContrat: data.idContrat,
                                    idTypeProbleme: data.idTypeProbleme,
                                    estSAV: data.estSAV
                                }
                            });
                        } else if (url.includes("FicheProspect.aspx")) {
                            this.$q.loading.show({ message: "Redirection en cours..." });
                            this.$api.prospects
                                .getIdGroupeProspectSelonIdProspect({
                                    idProspect: data.idProspect
                                })
                                .then((idProspect) =>
                                    this.$router.push({
                                        name: "Prospect",
                                        params: { idProspect }
                                    })
                                )
                                .finally(() => this.$q.loading.hide());
                        } else if (url.includes("FicheProspect3.aspx")) {
                            this.$router.push({
                                name: "Prospect",
                                params: {
                                    idProspect: data.idProspect
                                }
                            });
                        } else if (url.includes("Facturation.aspx")) {
                            this.$router.push({
                                path: "/creation-facture",
                                query: {
                                    idContrat: data.idContrat,
                                    idProjet: data.idProjet
                                }
                            });
                        } else {
                            this.$router.push({
                                name: "AncienneErp",
                                params: this.$utils.getParamsAncienneErp({
                                    url,
                                    data
                                })
                            });
                        }
                    }
                }
            } catch (error) {
                this.$q.notify({ type: "negative", message: "Une erreur est survenue lors de la redirection." });
            }
        }
    },
    async mounted() {
        window.addEventListener("storage", this.verifierChangementCompagnie);
        window.addEventListener("message", this.redirectionExterne, false);

        this.$q.loading.show();

        try {
            const [usagerCompagnies, usagerMenus, nomComponent] = await Promise.all([
                this.$api.auth.getUsagerCompagnies(),
                this.$api.auth.getUsagerMenu(),
                this.$api.auth.getProceduresAccueils()
            ]);

            this.usagerCompagnies = usagerCompagnies;
            this.selectedUsagerCompagnie = this.usagerCompagnies.find((x) => x.idCompagnie === this.idCompagnie);
            this.menus = usagerMenus;

            this.getEstVendeurBloque().catch(() => this.$q.notify({ type: "negative", message: "Une erreur est survenue lors de la vérification des tâches en retard." }));

            if (nomComponent) {
                const component = require(`../../components/${nomComponent}`);
                this.$q.dialog({
                    component: component.default,
                    cancel: false,
                    persistent: true
                });
            }
        } catch (error) {
            this.$q.notify({ type: "negative", message: "Une erreur est survenue lors du chargement de vos informations." });
        } finally {
            this.$q.loading.hide();
            this.isLoading = false;
        }
    },
    beforeUnmount() {
        window.removeEventListener("storage", this.verifierChangementCompagnie);
        window.removeEventListener("message", this.redirectionExterne, false);
    }
};
</script>
