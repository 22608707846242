export default (api) => ({
    getTypesAdresses: () => api.post("api/generiques/stored-procedure/GetTypesAdresses").then((r) => r.data[0]),
    ajouterAdresse: ({
        pays = null,
        province = null,
        ville = null,
        codePostal = null,
        rue = null,
        numCivique = null,
        appartement = null,
        complement = null,
        latitude = null,
        longitude = null
    } = {}) =>
        api
            .post("api/generiques/stored-procedure/AjouterAdresseV2", { pays, province, ville, codePostal, rue, numCivique, appartement, complement, latitude, longitude })
            .then((r) => r.data[0][0].id)
});
