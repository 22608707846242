<template>
    <q-dialog ref="dialog" @hide="onDialogHide">
        <q-card class="q-dialog-plugin" style="width: 70%;max-width: 70vw;">
            <q-card-section>
                <div class="text-h6">
                    {{ title }}
                </div>
            </q-card-section>
            <q-card-section class="q-gutter-y-md">
                <e-input-fichier v-model:id-fichier="idFichier" v-model:nom-fichier="nomFichier" label="Fichier" dense />
                <q-select v-if="avecType" v-model="idTypeFichier" label="Type" :options="types" hide-bottom-space emit-value map-options dense options-dense />
                <q-input v-if="avecDescription" v-model="descriptionFichier" label="Description" hint="Facultatif" hide-bottom-space dense />
            </q-card-section>
            <q-card-actions align="right">
                <q-btn color="primary" label="Annuler" @click="onCancelClick" flat />
                <q-btn color="primary" label="Sélectionner" :disable="!idFichier || (avecType && !idTypeFichier)" @click="onOKClick" flat />
            </q-card-actions>
        </q-card>
    </q-dialog>
</template>

<script>
export default {
    name: "e-dialog-input-fichier",
    props: {
        title: {
            type: String,
            default: "Sélectionner un fichier"
        },
        avecType: Boolean,
        types: Array,
        avecDescription: Boolean
    },
    emits: ["ok", "hide"],
    data() {
        return {
            idFichier: null,
            nomFichier: null,
            idTypeFichier: null,
            descriptionFichier: null
        };
    },
    methods: {
        show() {
            this.$refs.dialog.show();
        },
        hide() {
            this.$refs.dialog.hide();
        },
        onDialogHide() {
            this.$emit("hide");
        },
        onCancelClick() {
            this.hide();
        },
        onOKClick() {
            this.$emit("ok", {
                idFichier: this.idFichier,
                nomFichier: this.nomFichier,
                idTypeFichier: this.idTypeFichier,
                descriptionFichier: (this.descriptionFichier || "").trim() || null
            });
            this.hide();
        }
    }
};
</script>
