export default (api) => ({
    getVersionsMargesAssembleur: () => api.post("api/generiques/stored-procedure/GetVersionsMargesAssembleurV2").then((r) => r.data[0]),
    ajouterVersionMargeAssembleur: () => api.post("api/generiques/stored-procedure/AjouterVersionMargeAssembleurV2").then((r) => r.data[0][0]),
    ajouterDateDebutVersionMargeAssembleur: ({ idVersionMargeAssembleur = null, dateDebut = null } = {}) =>
        api.post("api/generiques/stored-procedure/AjouterDateDebutVersionMargeAssembleurV2", { idVersionMargeAssembleur, dateDebut }),
    getRegionsEstimations: ({ idProjet = null, idGroupeProjetPropose = null } = {}) =>
        api.post("api/generiques/stored-procedure/GetRegionsEstimations", { idProjet, idGroupeProjetPropose }).then((r) => r.data[0]),
    getMargesAssembleur: ({ idVersionMargeAssembleur = null, idRegionEstimation = null } = {}) =>
        api.post("api/generiques/stored-procedure/GetMargesAssembleur", { idVersionMargeAssembleur, idRegionEstimation }).then((r) => r.data[0]),
    copierMargesRegions: ({ idVersionMargeAssembleur = null, idRegionEstimation = null } = {}) =>
        api.post("api/generiques/stored-procedure/CopierMargesRegions", { idVersionMargeAssembleur, idRegionEstimation }),
    modifierMargeAssembleur: ({ marge = null, idRegionEstimation = null, idVersionMargeAssembleur = null, idClasseCategorie = null, idCategorie = null } = {}) =>
        api.post("api/generiques/stored-procedure/ModifierMargeAssembleur", { marge, idRegionEstimation, idVersionMargeAssembleur, idClasseCategorie, idCategorie })
});
