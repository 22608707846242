import { defineStore } from "pinia";

import api from "@/api";

import { useAuthStore } from "./index";

export const useProspectListeStore = defineStore("prospectListe", {
    state: () => ({
        isRechercheEffectuee: false,
        noTelephone: "",
        codePostal: "",
        nom: "",
        prenom: "",
        compagnie: "",
        courriel: "",
        pagination: {
            sortBy: null,
            descending: false,
            page: 1,
            rowsPerPage: 5
        },
        prospects: []
    }),
    getters: {
        //Droits
        peutAjouterProspect: () => useAuthStore().droit("FicheProspect3") === 2
    },
    actions: {
        async getProspects() {
            this.prospects = [];

            this.prospects = await api.prospects.getProspectsPourRechercheRapide({
                noTelephone: this.noTelephone || null,
                nomClient: this.nom || null,
                prenomClient: this.prenom || null,
                codePostal: this.codePostal || null,
                courriel: this.courriel || null,
                nomCompagnie: this.compagnie || null
            });

            this.isRechercheEffectuee = true;
        }
    }
});
