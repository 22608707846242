export default (api) => ({
    getSitesVentesActifs: ({ idCompagnie = null, corporatif = false, verifierDroit = false, idTypeDroitDonnee = null } = {}) =>
        api
            .post("api/generiques/stored-procedure/GetSitesVentesActifs", {
                idCompagnie,
                corporatif,
                verifierDroit,
                idTypeDroitDonnee
            })
            .then((r) => r.data[0])
});
