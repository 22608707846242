<template>
    <div class="row q-pa-md q-col-gutter-md">
        <q-input :model-value="telephone" dense filled label="Numéro de téléphone" class="col-6" @update:model-value="changeTelephone" @keyup.enter="onKeyupEnter">
            <template v-slot:prepend>
                <q-icon name="phone" size="sm"></q-icon>
            </template>
        </q-input>
        <q-input :model-value="codePostal" dense filled label="Code postal" class="col-6" @update:model-value="changeCodePostal" @keyup.enter="onKeyupEnter">
            <template v-slot:prepend>
                <q-icon name="place" size="sm"></q-icon>
            </template>
        </q-input>
        <q-input :model-value="nom" dense filled label="Nom du client" class="col-6" @update:model-value="changeNom" @keyup.enter="onKeyupEnter" />
        <q-input :model-value="compagnie" dense filled label="Compagnie" class="col-6" @update:model-value="changeCompagnie" @keyup.enter="onKeyupEnter" />
        <q-input :model-value="prenom" dense filled label="Prénom du client" class="col-6" @update:model-value="changePrenom" @keyup.enter="onKeyupEnter" />
        <q-input :model-value="courriel" dense filled label="Courriel" class="col-6" @update:model-value="changeCourriel" @keyup.enter="onKeyupEnter">
            <template v-slot:prepend>
                <q-icon name="email" size="sm"></q-icon>
            </template>
        </q-input>
    </div>
</template>

<script>
export default {
    name: "e-recherche-avancee-prospect",
    props: {
        telephone: String,
        codePostal: String,
        nom: String,
        compagnie: String,
        prenom: String,
        courriel: String
    },
    emits: ["update:telephone", "update:codePostal", "update:nom", "update:compagnie", "update:prenom", "update:courriel", "keyup.enter"],
    methods: {
        changeTelephone(telephone) {
            this.$emit("update:telephone", telephone.trim());
        },
        changeCodePostal(codePostal) {
            this.$emit("update:codePostal", codePostal.trim());
        },
        changeNom(nom) {
            this.$emit("update:nom", nom.trim());
        },
        changeCompagnie(compagnie) {
            this.$emit("update:compagnie", compagnie.trim());
        },
        changePrenom(prenom) {
            this.$emit("update:prenom", prenom.trim());
        },
        changeCourriel(courriel) {
            this.$emit("update:courriel", courriel.trim());
        },
        onKeyupEnter() {
            this.$emit("keyup.enter");
        }
    }
};
</script>
