import { defineStore } from "pinia";

import api from "@/api";

export const useAuthStore = defineStore("auth", {
    state: () => ({
        usager: null,
        droits: {},
        estVendeurBloque: false
    }),
    getters: {
        idUsager: (state) => (state.usager ? state.usager.idUsager : null),
        idCompagnie: (state) => (state.usager ? state.usager.idCompagnie : null),
        nomComplet: (state) => (state.usager ? (state.usager.prenom || "") + " " + (state.usager.nom || "") : null),
        nom: (state) => (state.usager ? state.usager.nom || "" : null),
        prenom: (state) => (state.usager ? state.usager.prenom || "" : null),
        pageAccueil: (state) => (state.usager ? state.usager.pageAccueil : null),
        estClient: (state) => (state.usager ? state.usager.estClient : null),
        estReferenceur: (state) => (state.usager ? state.usager.estReferenceur : null),
        droit: (state) => (nom) => (state.droits || {})[nom] || 0
    },
    actions: {
        async getUsager() {
            const [usager, droits] = await Promise.all([api.auth.getInfosUsager(), api.auth.getDroitsUsager()]);

            this.usager = usager;
            this.droits = droits.reduce((o, x) => ({ ...o, [x.nom]: x.droit }), {});

            localStorage.idCompagnie = this.usager.idCompagnie;
        },
        async getEstVendeurBloque() {
            this.estVendeurBloque = await api.taches.estVendeurBloque({
                idCompagnieUsager: this.usager.idCompagnie
            });
        }
    }
});
