import axios from "axios";

const api = axios.create({
	baseURL: process.env.VUE_APP_API_URL
});

api.defaults.withCredentials = true;

api.interceptors.response.use(
	function(response) {
		return response;
	},
	function(error) {
		if (error.response && error.response.status === 401) {
			if (window.location.pathname !== "/login") {
				window.location = "/login";
			}
		}
		return Promise.reject(error);
	}
);

export default api;