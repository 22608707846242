const SECOND = 1000;
const MINUTE = 60 * SECOND;
const HOUR = 60 * MINUTE;
const DAY = 24 * HOUR;
const WEEK = 7 * DAY;
const MONTH = 30 * DAY;
const YEAR = 365 * DAY;
const intervals = [
    { ge: YEAR, divisor: YEAR, unit: "year" },
    { ge: MONTH, divisor: MONTH, unit: "month" },
    { ge: WEEK, divisor: WEEK, unit: "week" },
    { ge: DAY, divisor: DAY, unit: "day" },
    { ge: HOUR, divisor: HOUR, unit: "hour" },
    { ge: MINUTE, divisor: MINUTE, unit: "minute" },
    { ge: 30 * SECOND, divisor: SECOND, unit: "seconds" },
    { ge: 0, divisor: 1, text: "à l'instant" }
];

export default {
    fromNow(date, nowDate = Date.now(), rft = new Intl.RelativeTimeFormat("fr", { numeric: "auto" })) {
        if (!date) return null;
        const now = typeof nowDate === "object" ? nowDate.getTime() : new Date(nowDate).getTime();
        const diff = now - (typeof date === "object" ? date : new Date(date)).getTime();
        const diffAbs = Math.abs(diff);
        for (const interval of intervals) {
            if (diffAbs >= interval.ge) {
                const x = Math.round(Math.abs(diff) / interval.divisor);
                const isFuture = diff < 0;
                return interval.unit ? rft.format(isFuture ? x : -x, interval.unit) : interval.text;
            }
        }
    },
    currency(n, nf = new Intl.NumberFormat("fr", { style: "currency", currency: "CAD", currencyDisplay: "narrowSymbol" })) {
        return nf.format(n);
    },
    decimal(n, nf = new Intl.NumberFormat("fr", { maximumFractionDigits: 2 })) {
        return nf.format(n);
    },
    mask(value, mask) {
        let maskedValue = "",
            valueIndex = 0;

        for (let i = 0; i < mask.length; i++) {
            if (mask[i] === "#" || mask === "A") {
                maskedValue += value[valueIndex];
                valueIndex++;
            } else {
                maskedValue += mask[i];
            }
        }

        return maskedValue;
    },
    adresse(a, { appartement = true, complement = true } = {}) {
        if (!a) return "";

        const parts1 = [],
            parts2 = [];

        if (a.numCivique) parts1.push(a.numCivique);
        if (appartement && a.appartement) parts1.push(`app. ${a.appartement}`);
        if (a.rue) parts1.push(a.rue);

        if (a.ville) parts2.push(a.ville);
        if (a.province) parts2.push(a.province);
        if (a.pays) parts2.push(a.pays);
        if (a.codePostal) parts2.push(a.codePostal);
        if (complement && a.complement) parts2.push(a.complement);

        if (parts1.length) {
            if (parts2.length) {
                return `${parts1.join(" ")}, ${parts2.join(", ")}`;
            }

            return parts1.join(" ");
        }

        if (parts2.length) {
            return parts2.join(", ");
        }

        return "";
    },
    firstUpperCase(s) {
        return typeof s === "string" && s.length > 0 ? `${s[0].toUpperCase()}${s.substring(1)}` : s;
    }
};
