export default (api) => ({
    get: (idFichier) => `${api.defaults.baseURL}api/fichiers/get/${idFichier}`,
    download: ({ idFichier = null, ouvrir = true } = {}) =>
        api
            .get(`api/fichiers/download/${idFichier}`, {
                responseType: "arraybuffer"
            })
            .then((r) => {
                const fileName = /filename="(?<filename>.*)"/.exec(r.headers["content-disposition"])?.groups?.filename ?? null,
                    fileExtention = fileName ? fileName.split(".").pop().toLowerCase() : null,
                    blob = new Blob([r.data], { type: r.headers["content-type"] }),
                    url = URL.createObjectURL(blob),
                    a = document.createElement("a");

                a.style = "display: none";
                a.href = url;
                a.download = fileName;

                document.body.appendChild(a);
                a.click();
                a.remove();

                if (ouvrir && fileExtention && fileExtention !== "xls" && fileExtention !== "xlsx" && fileExtention !== "doc" && fileExtention !== "docx" && fileExtention !== "dwg") {
                    window.open(url, "_blank");
                }

                URL.revokeObjectURL(url);

                return r;
            }),
    upload: (files) => {
        let formData = new FormData();
        if (Array.isArray(files)) {
            for (let i = 0; i < files.length; i++) {
                formData.append(`file${i}`, files[i]);
            }
        } else {
            formData.append("file0", files);
        }

        return api
            .post("api/fichiers/upload", formData, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            })
            .then((r) => r.data);
    },
    view: (idFichier) =>
        api
            .get(`api/fichiers/get/${idFichier}`, {
                responseType: "arraybuffer"
            })
            .then((r) => {
                const blob = new Blob([r.data], { type: r.headers["content-type"] }),
                    url = URL.createObjectURL(blob);

                window.open(url, "_blank");

                URL.revokeObjectURL(url);

                return r;
            })
});
