<template>
    <q-dialog ref="dialog" :no-esc-dismiss="true" :no-backdrop-dismiss="true">
        <q-card class="q-dialog-plugin q-dialog-size-sm">
            <q-card-section class="row items-center q-pb-none justify-between">
                <div class="text-h6">Recherche contrat</div>
                <div class="col-1">
                    <q-btn v-if="dejaRecherche" icon="close" flat round dense v-close-popup class="float-right"></q-btn>
                </div>
            </q-card-section>

            <q-card-section class="row q-gutter-x-sm justify-center">
                <q-input
                    v-model="txtRechercheRapide"
                    ref="txtRechercheRapide"
                    class="col-2"
                    label="# Contrat"
                    @keyup.enter="rechercheRapide"
                    autofocus
                    outlined
                    hide-bottom-space
                    dense
                />
            </q-card-section>

            <q-card-section class="row justify-center">
                <q-btn color="primary" label="Rechercher" @click="rechercheRapide()" />
            </q-card-section>

            <q-card-section>
                <q-table
                    v-if="contrats?.length > 1"
                    :title="`${contrats.length} contrat(s) trouvé(s)`"
                    :rows="contrats"
                    :columns="contratsColumns"
                    row-key="idContrat"
                    @row-click="atteindreBudgetLivraison"
                />
            </q-card-section>
        </q-card>
    </q-dialog>
</template>

<script>
export default {
    name: "e-dialog-recherche-rapide-contrat",
    data() {
        return {
            txtRechercheRapide: "",
            contratsColumns: [
                {
                    name: "numContrat",
                    label: "# Contrat",
                    align: "left",
                    field: "numContrat",
                    sortable: true,
                    classes: this.getCouleurEtatContrat
                },
                {
                    name: "client",
                    label: "Client(s)",
                    align: "left",
                    field: "clients",
                    sortable: true,
                    classes: this.getCouleurEtatContrat
                },
                {
                    name: "villeConstruction",
                    label: "Ville construction",
                    align: "left",
                    field: "villeConstruction",
                    sortable: true,
                    classes: this.getCouleurEtatContrat
                },
                {
                    name: "numModele",
                    label: "# Modèle",
                    align: "left",
                    field: "numeroModele",
                    sortable: true,
                    classes: this.getCouleurEtatContrat
                },
                {
                    name: "vendeur",
                    label: "Vendeur",
                    align: "left",
                    field: "vendeur",
                    sortable: true,
                    classes: this.getCouleurEtatContrat
                },
                {
                    name: "etat",
                    label: "État",
                    align: "left",
                    field: "etatContrat",
                    sortable: true,
                    classes: this.getCouleurEtatContrat
                }
            ],
            contrats: []
        };
    },
    props: {
        dejaRecherche: Boolean
    },
    emits: ["ok"],
    methods: {
        show() {
            this.$refs.dialog.show();
        },
        hide() {
            this.$refs.dialog.hide();
        },
        onDialogHide() {
            this.$emit("hide");
        },
        async rechercheRapide() {
            this.$q.loading.show();

            try {
                this.contrats = await this.$api.contrats.getContratsPourRechercheRapide({
                    noContrat: this.txtRechercheRapide.trim() || null
                });

                if (this.contrats.length === 0) {
                    this.$q.notify({ type: "warning", message: "Aucun contrat trouvé." });
                } else if (this.contrats.length === 1) {
                    this.$emit("ok", { contrat: this.contrats, selection: false });
                    this.hide();
                }
            } catch (error) {
                this.$q.notify({ type: "negative", message: "Une erreur est survenue lors de la recherche." });
            } finally {
                this.$q.loading.hide();
            }
        },
        getCouleurEtatContrat(c) {
            return c.etat == 2 ? "text-red-7" : c.etat == 3 ? "text-orange-7" : c.etat == 4 ? "text-green-7" : "text-blue-7";
        },
        atteindreBudgetLivraison(evt, contrat) {
            this.$emit("ok", { contrat, selection: true });
            this.hide();
        }
    }
};
</script>

<style scoped>
.toggle-types-recherche-rapide {
    border: 1px solid #027be3;
}
</style>
