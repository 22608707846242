export default (api) => ({
    getProspectsPourRechercheRapide: ({ noTelephone = null, nomClient = null, prenomClient = null, codePostal = null, courriel = null, nomCompagnie = null } = {}) =>
        api
            .post("api/generiques/stored-procedure/GetProspectRechercheRapide", {
                noTelephone,
                nomClient,
                prenomClient,
                codePostal,
                courriel,
                nomCompagnie
            })
            .then((r) => r.data[0]),
    getTypesContactsProspects: () => api.post("api/generiques/stored-procedure/GetTypesContactsProspects").then((r) => r.data[0]),
    getProjetsResidentiels: ({ avecDroits = false } = {}) => api.post("api/generiques/stored-procedure/GetProjetsResidentielsV2", { avecDroits }).then((r) => r.data[0]),
    getClientsCherches: ({ idGroupeProjetPropose = null } = {}) => api.post("api/generiques/stored-procedure/GetClientsCherchesV2", { idGroupeProjetPropose }).then((r) => r.data[0]),
    getSitesTableauDeBord: ({ idCompagnie = null } = {}) => api.post("api/generiques/stored-procedure/getSiteTableauDeBord", { idCompagnie }).then((r) => r.data[0]),
    getVendeursSite: ({ idSite = null, codeException = null } = {}) => api.post("api/generiques/stored-procedure/getVendeursSite", { idSite, codeException }).then((r) => r.data[0]),
    getPromotionsVentes: () => api.post("api/generiques/stored-procedure/GetPromotionsVentesV2").then((r) => r.data[0]),
    getModelesPromotionVente: ({ idPromotionVente = null } = {}) => api.post("api/generiques/stored-procedure/GetModelesPromotionVenteV2", { idPromotionVente }).then((r) => r.data[0]),
    ajoutInvite: ({
        nom = null,
        prenom = null,
        telephone = null,
        codePostal = null,
        origineInvite = 4,
        nomCompagnie = null,
        telCellulaire = null,
        idTypeContactProspect = null,
        idClientCherche = null,
        idBureauResponsable = null,
        idEmployeResponsable = null,
        idTypeSuivi = null,
        suivi = null,
        idPromotionVente = null,
        idModelePromotion = null,
        depotPromotion = null,
        dateRappel = null,
        idProjetResidentiel = null,
        idAgence = null,
        idAgent = null,
        sexe = null,
        estCovid = false
    } = {}) =>
        api
            .post("api/generiques/stored-procedure/AjoutInvite", {
                nom,
                prenom,
                telephone,
                codePostal,
                origineInvite,
                nomCompagnie,
                telCellulaire,
                idTypeContactProspect,
                idClientCherche,
                idBureauResponsable,
                idEmployeResponsable,
                idTypeSuivi,
                suivi,
                idPromotionVente,
                idModelePromotion,
                depotPromotion,
                dateRappel,
                idProjetResidentiel,
                idAgence,
                idAgent,
                sexe,
                estCovid
            })
            .then((r) => r.data[0][0]),
    getTypesSuivis: ({ tout = true } = {}) => api.post("api/generiques/stored-procedure/GetTypesSuivisV2", { tout }).then((r) => r.data[0]),
    getEtatsGroupes: ({ idGroupeProjetPropose = null } = {}) => api.post("api/generiques/stored-procedure/GetEtatsGroupes", { idGroupeProjetPropose }).then((r) => r.data[0]),
    getCourrielGroupeProjetPropose: ({ idGroupeProjetPropose = null, estCompagnie = null } = {}) =>
        api.post("api/generiques/scalar-function/GetCourrielGroupeProjetPropose", { idGroupeProjetPropose, estCompagnie }).then((r) => r.data),
    getCompetiteurs: () => api.post("api/generiques/stored-procedure/GetCompetiteursV3").then((r) => r.data[0]),
    getTypesProchainesRelances: () => api.post("api/generiques/stored-procedure/GetTypesProchainesRelancesV2").then((r) => r.data[0]),
    ajoutSuiviGroupeProjet: ({
        idGroupeProjet = null,
        idTypeSuivi = null,
        description = null,
        idGroupeProspect = null,
        estCompagnie = null,
        etat = null,
        dateRappelle = null,
        idEmployeTransfert = null,
        idTypeProchaineRelance = null,
        idTypeContactClient = null,
        dureeTacheRappel = null,
        idsTachesACompleter = null,
        idProjetResidentiel = null,
        estCovid = false,
        envoyeAuClient = false
    } = {}) =>
        api
            .post("api/generiques/stored-procedure/AjoutSuiviGroupeProjet", {
                idGroupeProjet,
                idTypeSuivi,
                description,
                idGroupeProspect,
                estCompagnie,
                etat,
                dateRappelle,
                idEmployeTransfert,
                idTypeProchaineRelance,
                idTypeContactClient,
                dureeTacheRappel,
                idsTachesACompleter,
                idProjetResidentiel,
                estCovid,
                envoyeAuClient
            })
            .then((r) => ({
                idSuiviProspect: r.data[0][0].idSuiviProspect,
                suivi: {
                    ...r.data[1][0],
                    usager: JSON.parse(r.data[1][0].usager),
                    type: JSON.parse(r.data[1][0].type),
                    fichiers: JSON.parse(r.data[1][0].fichiers)
                },
                taches: r.data[2].map((x) => ({
                    ...x,
                    usager: JSON.parse(x.usager),
                    type: JSON.parse(x.type)
                }))
            })),
    ajoutFichiersSuiviProspect: (params = []) =>
        api
            .post("api/generiques/stored-procedure-loop/AjoutFichierSuiviProspect", {
                params
            })
            .then((r) => r.data.map((x) => x[0][0].idFichierSuiviProspect)),
    modifierCompetiteurGroupeProjetPropose: ({ idGroupeProjetPropose = null, idCompetiteur = null, autreRaison = null, desactiverGroupe = null, idTypeCompetiteur = 1 } = {}) =>
        api.post("api/generiques/stored-procedure/ModifierCompetiteurGroupeProjetProposeV2", { idGroupeProjetPropose, idCompetiteur, autreRaison, desactiverGroupe, idTypeCompetiteur }),
    getTableauProspects: ({ inclureProspectsFermes = null, conseillers = null } = {}) =>
        api
            .post("api/generiques/stored-procedure/GetTableauProspectsV2", {
                inclureProspectsFermes,
                conseillers
            })
            .then((r) => r.data[0]),
    estDirecteurSuccursale: () => api.post("api/generiques/stored-procedure/EstDirecteurSuccursale").then((r) => r.data[0]),
    getListeConseillersAvecProspectsSite: ({ inclureVieuxConseillers = null } = {}) =>
        api.post("api/generiques/stored-procedure/GetListeConseillersAvecProspectsSite", { inclureVieuxConseillers }).then((r) => r.data[0]),
    getNouveauxProspects: ({ idCompagnie = null } = {}) => api.post("api/generiques/stored-procedure/GetNouveauxProspects", { idCompagnie }).then((r) => r.data[0]),
    getSitesVentes: ({ idCompagnie = null } = {}) => api.post("api/generiques/stored-procedure/GetSitesVentesV2", { idCompagnie }).then((r) => r.data[0]),
    getVendeursSites: ({ idSite = null, codeException = null } = {}) => api.post("api/generiques/stored-procedure/GetVendeursSitesV2", { idSite, codeException }).then((r) => r.data[0]),
    getAgences: ({ idCompagnie = null } = {}) => api.post("api/generiques/stored-procedure/GetAgencesV2", { idCompagnie }).then((r) => r.data[0]),
    getAgentsAgence: ({ idSite = null } = {}) => api.post("api/generiques/stored-procedure/GetAgentsAgenceV2", { idSite }).then((r) => r.data[0]),
    ajoutResponsableGroupeProjetsPropose: ({
        idsGroupeProjetPropose = null,
        idResponsable = null,
        idTypeResponsable = null,
        changerBureauClient = null,
        dateProchaineRappel = null
    } = {}) =>
        api.post("api/generiques/stored-procedure/AjoutResponsableGroupeProjetsProposeV2", {
            idsGroupeProjetPropose,
            idResponsable,
            idTypeResponsable,
            changerBureauClient,
            dateProchaineRappel
        }),
    modifierSiteProspect: ({ idCompteur = null, idBureauResponsable = null, note = null, typeResponsable = null } = {}) =>
        api.post("api/generiques/stored-procedure/ModifierSiteProspectV2", { idCompteur, idBureauResponsable, note, typeResponsable }),
    modifierSiteCompagnieProspect: ({ idCompagnieProspect = null, idBureauResponsable = null, note = null, typeResponsable = null } = {}) =>
        api.post("api/generiques/stored-procedure/ModifierSiteCompagnieProspectV2", { idCompagnieProspect, idBureauResponsable, note, typeResponsable }),
    modifierEtatGroupeProjetPropose: ({ idGroupeProjetPropose = null, idEtatGroupe = null } = {}) =>
        api.post("api/generiques/stored-procedure/ModifierEtatGroupeProjetProposeV2", { idGroupeProjetPropose, idEtatGroupe }),
    getStatistiquesSAC: ({ idCompagnie = null, dateDebut = null, dateFin = null } = {}) =>
        api
            .post("api/generiques/stored-procedure/GetStatistiquesSAC", {
                idCompagnie,
                dateDebut,
                dateFin
            })
            .then((r) => r.data[0]),
    getInscriptionProspectSelonIntervalDate: ({ idCompagnie = null, annee = null, mois = null } = {}) =>
        api
            .post("api/generiques/stored-procedure/GetInscriptionProspectSelonIntervalDate", {
                idCompagnie,
                annee,
                mois
            })
            .then((r) => r.data),
    getProspectsDansZoneDefinie: ({ idCompagnie = null, idZonesDefinies = null, dateDebut = null, dateFin = null } = {}) =>
        api
            .post("api/generiques/stored-procedure/getProspectsDansZoneDefinie", {
                idCompagnie,
                dateDebut,
                dateFin,
                idZonesDefinies
            })
            .then((r) => r.data[0]),
    modifierLatLong: ({ idAdresse = null, latitude = null, longitude = null } = {}) =>
        api
            .post("api/generiques/stored-procedure/ModifierZonne", {
                idAdresse,
                latitude,
                longitude
            })
            .then((r) => r.data[0]),
    getInfosProspect: ({ idProspect = null, estRechercheParTelephone = false } = {}) =>
        api.post("api/generiques/stored-procedure/GetInfosProspect", { idProspect, estRechercheParTelephone }).then((r) =>
            r.data[0].length
                ? {
                      ...r.data[0][0],
                      compagnie: JSON.parse(r.data[0][0].compagnie),
                      personnes: JSON.parse(r.data[0][0].personnes),
                      questions: JSON.parse(r.data[0][0].questions),
                      conseiller: JSON.parse(r.data[0][0].conseiller),
                      agence: JSON.parse(r.data[0][0].agence),
                      suivis: JSON.parse(r.data[0][0].suivis),
                      taches: JSON.parse(r.data[0][0].taches),
                      projets: JSON.parse(r.data[0][0].projets),
                      typesTelephones: r.data[1]
                  }
                : null
        ),
    modifierClassificationConseiller: ({ idProspect = null, classification = null } = {}) =>
        api.post("api/generiques/stored-procedure/ModifierClassificationConseiller", { idProspect, classification }),
    getConseillers: () =>
        api.post("api/generiques/stored-procedure/GetConseillers").then((r) =>
            r.data[0].map((x) => ({
                ...x,
                employes: JSON.parse(x.employes)
            }))
        ),
    modifierConseillerProspect: ({ idProspect = null, idSite = null, idEmploye = null } = {}) =>
        api.post("api/generiques/stored-procedure/ModifierConseillerProspect", { idProspect, idSite, idEmploye }),
    getAgencesV3: () =>
        api.post("api/generiques/stored-procedure/GetAgencesV3").then((r) =>
            r.data[0].map((x) => ({
                ...x,
                employes: JSON.parse(x.employes)
            }))
        ),
    modifierAgenceProspect: ({ idProspect = null, idSite = null, idEmploye = null } = {}) =>
        api.post("api/generiques/stored-procedure/ModifierAgenceProspect", { idProspect, idSite, idEmploye }),
    getHistoriquesResponsablesProspects: ({ idProspect = null, idTypeResponsableProspect = null } = {}) =>
        api.post("api/generiques/stored-procedure/GetHistoriquesResponsablesProspects", { idProspect, idTypeResponsableProspect }).then((r) => r.data[0]),
    ajouterRappelSuiviProspect: ({ idProspect = null, idEmploye = null, dateRappel = null } = {}) =>
        api.post("api/generiques/stored-procedure/AjouterRappelSuiviProspect", { idProspect, idEmploye, dateRappel }).then((r) =>
            r.data[0].map((x) => ({
                ...x,
                usager: JSON.parse(r.data[0][0].usager),
                type: JSON.parse(r.data[0][0].type)
            }))
        ),
    modifierNomPersonne: ({ idPersonne = null, nom = null, prenom = null } = {}) => api.post("api/generiques/stored-procedure/ModifierNomPersonne", { idPersonne, nom, prenom }),
    modifierSexePersonne: ({ idPersonne = null, sexe = null } = {}) => api.post("api/generiques/stored-procedure/ModifierSexePersonne", { idPersonne, sexe }),
    ajouterTelephonePersonne: ({ idPersonne = null, numero = null, poste = null, idTypeTelephone = null, estPrincipal = null } = {}) =>
        api.post("api/generiques/stored-procedure/AjouterTelephonePersonne", { idPersonne, numero, poste, idTypeTelephone, estPrincipal }).then((r) => r.data[0][0].id),
    modifierTelephonePersonne: ({ idTelephonePersonne = null, numero = null, poste = null, estPrincipal = null, idTypeTelephone = null } = {}) =>
        api.post("api/generiques/stored-procedure/ModifierTelephonePersonneV2", { idTelephonePersonne, numero, poste, estPrincipal, idTypeTelephone }),
    supprimerTelephonePersonne: ({ idTelephonePersonne = null } = {}) => api.post("api/generiques/stored-procedure/SupprimerTelephonePersonneV2", { idTelephonePersonne }),
    ajouterAdressePersonne: ({ idPersonne = null, idTypeAdresse = null, idAdresse = null } = {}) =>
        api.post("api/generiques/stored-procedure/AjouterAdressePersonne", { idPersonne, idTypeAdresse, idAdresse }).then((r) => r.data[0][0].id),
    supprimerAdressePersonne: ({ idAdressePersonne = null } = {}) => api.post("api/generiques/stored-procedure/SupprimerAdressePersonne", { idAdressePersonne }),
    ajouterCourrielPersonne: ({ idPersonne = null, courriel = null } = {}) =>
        api.post("api/generiques/stored-procedure/AjouterCourrielPersonneV2", { idPersonne, courriel }).then((r) => r.data[0][0].id),
    modifierCourrielPersonne: ({ idCourrielPersonne = null, courriel = null } = {}) =>
        api.post("api/generiques/stored-procedure/ModifierCourrielPersonne", { idCourrielPersonne, courriel }),
    supprimerCourrielPersonne: ({ idCourrielPersonne = null } = {}) => api.post("api/generiques/stored-procedure/SupprimerCourrielPersonne", { idCourrielPersonne }),
    modifierNomCompagnie: ({ idCompagnie = null, nom = null } = {}) => api.post("api/generiques/stored-procedure/ModifierNomCompagnie", { idCompagnie, nom }),
    ajouterTelephoneCompagnieProspect: ({ idProspect = null, numero = null, idTypeTelephone = null } = {}) =>
        api.post("api/generiques/stored-procedure/AjouterTelephoneCompagnieProspect", { idProspect, numero, idTypeTelephone }).then((r) => r.data[0][0].id),
    modifierTelephoneCompagnieProspect: ({ idTelephoneCompagnie = null, numero = null, idTypeTelephone = null } = {}) =>
        api.post("api/generiques/stored-procedure/ModifierTelephoneCompagnieProspect", { idTelephoneCompagnie, numero, idTypeTelephone }),
    supprimerTelephoneCompagnieProspect: ({ idTelephoneCompagnie = null } = {}) =>
        api.post("api/generiques/stored-procedure/SupprimerTelephoneCompagnieProspect", { idTelephoneCompagnie }),
    getProspectsParNumeroTelephone: ({ numero = null } = {}) => api.post("api/generiques/stored-procedure/GetProspectsParNumeroTelephone", { numero }).then((r) => r.data[0]),
    modifierAdresseCompagnie: ({ idProspect = null, idAdresse = null } = {}) => api.post("api/generiques/stored-procedure/ModifierAdresseCompagnieV2", { idProspect, idAdresse }),
    ajouterCourrielCompagnieProspect: ({ idProspect = null, courriel = null } = {}) =>
        api.post("api/generiques/stored-procedure/AjouterCourrielCompagnieProspect", { idProspect, courriel }).then((r) => r.data[0][0].id),
    modifierCourrielCompagnieProspect: ({ idCourrielCompagnie = null, courriel = null } = {}) =>
        api.post("api/generiques/stored-procedure/ModifierCourrielCompagnieProspect", { idCourrielCompagnie, courriel }),
    supprimerCourrielCompagnieProspect: ({ idCourrielCompagnie = null } = {}) => api.post("api/generiques/stored-procedure/SupprimerCourrielCompagnieProspect", { idCourrielCompagnie }),
    ajouterPersonneProspect: ({ idProspect = null, telephone = null, nom = null, prenom = null, sexe = null, idAdresse = null } = {}) =>
        api.post("api/generiques/stored-procedure/AjouterPersonneProspect", { idProspect, telephone, nom, prenom, sexe, idAdresse }).then((r) => r.data[0][0].id),
    ajouterPersonneCompagnieProspect: ({ idProspect = null, nom = null, prenom = null, telephone = null, sexe = null } = {}) =>
        api.post("api/generiques/stored-procedure/AjouterPersonneCompagnieProspect", { idProspect, nom, prenom, telephone, sexe }).then((r) => ({
            ...r.data[0][0],
            telephones: JSON.parse(r.data[0][0].telephones),
            courriels: JSON.parse(r.data[0][0].courriels)
        })),
    supprimerPersonneProspectCompagnie: ({ idPersonne = null, idProspect = null } = {}) =>
        api.post("api/generiques/stored-procedure/SupprimerPersonneProspectCompagnie", { idPersonne, idProspect }),
    supprimerPersonneProspect: ({ idProspect = null, idPersonne = null } = {}) =>
        api.post("api/generiques/stored-procedure/SupprimerPersonneProspect", { idProspect, idPersonne }).then((r) => r.data[0][0].id),
    ajouterCompagnieProspect: ({ idProspect = null, nom = null, telephone = null, idAdresse = null } = {}) =>
        api.post("api/generiques/stored-procedure/AjouterCompagnieProspect", { idProspect, nom, telephone, idAdresse }).then((r) => r.data[0][0].id),
    getCompagniesPourTransfererProspect: () => api.post("api/generiques/stored-procedure/GetCompagniesPourTransfererProspectV2").then((r) => r.data[0]),
    modifierProspectCompagnie: ({ idProspect = null, idCompagnie = null } = {}) => api.post("api/generiques/stored-procedure/ModifierProspectCompagnieV2", { idProspect, idCompagnie }),
    ajouterProjetTcost: ({ idGroupeProjetPropose = null, idProjetTcost = null, idModeleTcost = null } = {}) =>
        api.post("api/generiques/stored-procedure/AjouterProjetTcostV2", { idGroupeProjetPropose, idProjetTcost, idModeleTcost }).then((r) => ({
            ...r.data[0][0],
            contrat: JSON.parse(r.data[0][0].contrat),
            notes: JSON.parse(r.data[0][0].notes)
        })),
    supprimerProjet: ({ idProjet = null } = {}) => api.post("api/generiques/stored-procedure/SupprimerProjet", { idProjet }),
    ajouterProjet: ({ idProspect = null, nom = null, idEtatGroupe = null } = {}) =>
        api.post("api/generiques/stored-procedure/AjouterProjet", { idProspect, nom, idEtatGroupe }).then((r) => ({
            ...r.data[0][0],
            delaiSignature: JSON.parse(r.data[0][0].delaiSignature),
            estimations: JSON.parse(r.data[0][0].estimations)
        })),
    modifierProjet: ({ idGroupeProjetPropose = null, nom = null, idEtatGroupe = null, autreRaison = null, idTypeCompetiteur = null, idCompetiteur = null } = {}) =>
        api.post("api/generiques/stored-procedure/ModifierProjet", { idGroupeProjetPropose, nom, idEtatGroupe, autreRaison, idTypeCompetiteur, idCompetiteur }),
    ajouterNoteProjet: ({ idProjet = null, note = null } = {}) => api.post("api/generiques/stored-procedure/AjouterNoteProjet", { idProjet, note }).then((r) => r.data[0][0].id),
    supprimerNoteProjet: ({ idNoteProjet = null } = {}) => api.post("api/generiques/stored-procedure/SupprimerNoteProjet", { idNoteProjet }),
    getTypesClientProspect: () => api.post("api/generiques/stored-procedure/GetTypesClientProspectV2").then((r) => r.data[0]),
    ajouterProspect: ({ compagnie = null, nom = null, prenom = null, sexe = null, telephone = null, idAdresse = null, courriel = null } = {}) =>
        api.post("api/generiques/stored-procedure/AjouterProspect", { compagnie, nom, prenom, sexe, telephone, idAdresse, courriel }).then((r) => ({
            idProspect: r.data[0][0].id,
            idGroupeProjetPropose: r.data[0][0].idGroupeProjetPropose
        })),
    modifierClassificationConseillerBanqueProspect: ({ id = null, estCompagnie = false, classification = null } = {}) =>
        api.post("api/generiques/stored-procedure/ModifierClassificationConseillerBanqueProspect", { id, classification, estCompagnie }),
    getProspectsTableauBordMarketing: ({ dateDebut = null, dateFin = null } = {}) =>
        api
            .post("api/generiques/stored-procedure/GetProspectsTableauBordMarketing", {
                dateDebut,
                dateFin
            })
            .then((r) => r.data[0]),
    modifierReponseQuestionSondageProspect: ({ idProspect = null, idQuestion = null, idsReponses = [] } = {}) =>
        api
            .post("api/generiques/stored-procedure/ModifierReponseQuestionSondageProspect", {
                idProspect,
                idQuestion,
                idsReponses: {
                    type: "Ids",
                    rows: idsReponses
                }
            })
            .then((r) => r.data[0][0].classificationSysteme),
    getIdGroupeProspectSelonIdProspect: ({ idProspect = null } = {}) => api.post("api/generiques/scalar-function/GetIdGroupeProspectSelonIdProspect", { idProspect }).then((r) => r.data)
});
