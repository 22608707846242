<template>
    <q-select
        :model-value="modelValue"
        :loading="isLoading"
        @update:model-value="changeSelectedValue"
    />
</template>

<script>
export default {
    name: "e-select-store",
    props: {
        modelValue: [Number, Object],
        fnGet: Function
    },
    emits: ["update:modelValue"],
    data() {
        return {
            isLoading: true
        };
    },
    methods: {
        changeSelectedValue(selectedValue) {
            this.$emit("update:modelValue", selectedValue);
        }
    },
    async mounted() {
        try {
            await this.fnGet();
        } catch (error) {
            this.$q.notify({ type: "negative", message: "Une erreur est survenue lors du chargement des données." });
        } finally {
            this.isLoading = false;
        }
    }
};
</script>
