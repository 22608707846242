import "quasar/dist/quasar.css";
import lang from "quasar/lang/fr.js";
import "@quasar/extras/roboto-font/roboto-font.css";
import "@quasar/extras/material-icons/material-icons.css";

import AppFullscreen from 'quasar/src/plugins/AppFullscreen.js';import Notify from 'quasar/src/plugins/Notify.js';import Loading from 'quasar/src/plugins/Loading.js';import Dialog from 'quasar/src/plugins/Dialog.js';;

// To be used on app.use(Quasar, { ... })
export default {
	config: {},
	plugins: [AppFullscreen, Notify, Loading, Dialog],
	lang: lang
};
