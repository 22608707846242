<template>
    <q-dialog ref="dialog" @hide="onDialogHide" :no-esc-dismiss="isSubmitting" :no-backdrop-dismiss="isSubmitting">
        <q-card class="q-dialog-plugin q-dialog-size-lg">
            <q-table
                ref="table"
                class="q-table-sticky-header"
                color="primary"
                row-key="id"
                :rows="notes"
                :columns="columns"
                :rows-per-page-options="[0]"
                :hide-bottom="!isVisibleAjouter"
                hide-header
                dense
            >
                <template v-slot:top>
                    <div class="col q-my-sm">
                        <div class="row items-start no-wrap q-gutter-x-sm">
                            <div class="text-h6">Notes</div>
                            <q-space />
                            <q-btn icon="close" :disable="isSubmitting" @click="hide" flat round dense />
                        </div>
                    </div>
                </template>
                <template v-slot:body-cell-note="props">
                    <q-td :props="props">
                        <div class="row items-center">
                            <span class="text-bold q-mr-sm">{{ props.row.usager.prenom }} {{ props.row.usager.nom }}</span>
                            <span :title="props.row.date" class="text-grey text-caption">{{ $filters.fromNow(props.row.date) }}</span>
                            <q-btn
                                v-if="isVisibleSupprimer"
                                icon="delete"
                                class="q-ml-sm"
                                color="negative"
                                size="sm"
                                padding="none"
                                :loading="idNoteSupprimer === props.row.id"
                                @click="supprimer(props.row.id)"
                                flat
                                round
                            />
                        </div>
                        <div class="text-small full-width" style="white-space: pre-line; word-wrap: break-word">
                            {{ props.row.note }}
                        </div>
                    </q-td>
                </template>
                <template v-if="isVisibleAjouter" v-slot:[slotName]>
                    <div class="full-width">
                        <q-input
                            v-model="note"
                            type="textarea"
                            placeholder="Ajouter une note"
                            :input-style="{ 'max-height': '72px' }"
                            @change="note = note.trim()"
                            :disable="isLoadingAjouter"
                            autogrow
                            dense
                            borderless
                        >
                            <template v-slot:append>
                                <q-btn icon="send" :loading="isLoadingAjouter" :disable="!note.trim()" @click="ajouter" flat round dense />
                            </template>
                        </q-input>
                    </div>
                </template>
            </q-table>
        </q-card>
    </q-dialog>
</template>

<script>
export default {
    name: "e-dialog-liste-note",
    emits: ["hide"],
    props: {
        notes: Array,
        ajouterCallback: Function,
        supprimerCallback: Function
    },
    data() {
        return {
            columns: [
                {
                    name: "note",
                    field: "note",
                    align: "left",
                    headerStyle: "width: 100%"
                }
            ],
            note: "",
            isLoadingAjouter: false,
            idNoteSupprimer: null
        };
    },
    computed: {
        slotName() {
            return this.notes.length ? "bottom" : "no-data";
        },
        isSubmitting() {
            return this.isLoadingAjouter || !!this.idNoteSupprimer;
        },
        isVisibleAjouter() {
            return typeof this.ajouterCallback === "function";
        },
        isVisibleSupprimer() {
            return typeof this.supprimerCallback === "function";
        }
    },
    methods: {
        show() {
            this.$refs.dialog.show();
        },
        hide() {
            this.$refs.dialog.hide();
        },
        onDialogHide() {
            this.$emit("hide");
        },
        async ajouter() {
            this.isLoadingAjouter = true;

            try {
                await this.ajouterCallback(this.note);

                this.note = "";
                this.$refs.table.scrollTo(this.notes.length - 1);

                this.$q.notify({ type: "positive", message: "Note ajoutée avec succès." });
            } catch (error) {
                this.$q.notify({ type: "negative", message: "Une erreur est survenue lors de l'ajout de la note." });
            } finally {
                this.isLoadingAjouter = false;
            }
        },
        supprimer(id) {
            this.$q
                .dialog({
                    title: "Confirmation",
                    message: "Voulez-vous vraiment supprimer cette note?",
                    cancel: "Annuler",
                    ok: "Confirmer"
                })
                .onOk(async () => {
                    this.idNoteSupprimer = id;

                    try {
                        await this.supprimerCallback(id);

                        this.$q.notify({ type: "positive", message: "Note supprimée avec succès." });
                    } catch (error) {
                        this.$q.notify({ type: "negative", message: "Une erreur est survenue lors de la suppression de la note." });
                    } finally {
                        this.idNoteSupprimer = null;
                    }
                });
        }
    },
    mounted() {
        setTimeout(() => this.$refs.table.scrollTo(0, "top"), 1);
    }
};
</script>

<style scoped>
.q-table-sticky-header {
    height: 60vh;
}
.q-table__container :deep(table) {
    table-layout: fixed;
}
.q-table__container :deep(.q-table__top) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
</style>
