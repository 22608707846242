export default (api) => ({
    getPosteEmployes: ({ idPoste = null, codeInterface = null } = {}) =>
        api
            .post("api/generiques/stored-procedure/GetPosteEmployesV2", {
                idPoste,
                codeInterface
            })
            .then((r) => r.data[0]),
    getPaiesConseillers: () => api.post("api/generiques/stored-procedure/GetPaiesConseillersV2").then((r) => r.data[0]),
    getEmployesGroupePrioritePlan: ({ idGroupePrioritePlan = null, idCompagnie = null } = {}) =>
        api
            .post("api/generiques/stored-procedure/GetEmployesGroupePrioritePlan", {
                idGroupePrioritePlan,
                idCompagnie
            })
            .then((r) => r.data[0]),
    getEmployesCompagnie: ({ idCompagnie = null } = {}) =>
        api
            .post("api/generiques/stored-procedure/GetEmployesCompagnieV2", {
                idCompagnie
            })
            .then((r) => r.data[0]),
    getVendeurCompagnie: ({ idCompagnie = null } = {}) =>
        api
            .post("api/generiques/stored-procedure/getVendeurCompagnie", {
                idCompagnie
            })
            .then((r) => r.data[0])
});
