export default (api) => ({
    getDbObjets: () => api.post("api/generiques/stored-procedure/GetDbObjets").then((r) => r.data[0].map((x) => ({ ...x, objets: JSON.parse(x.objets) }))),
    ajouterDbObjet: ({ idTypeDbObjet = null, nom = null } = {}) =>
        api
            .post("api/generiques/stored-procedure/AjouterDbObjet", {
                idTypeDbObjet,
                nom
            })
            .then((r) => r.data[0][0].idDbObjet),
    modifierDbObjet: ({ idDbObjet = null, estPublic = null } = {}) =>
        api.post("api/generiques/stored-procedure/ModifierDbObjet", {
            idDbObjet,
            estPublic
        }),
    supprimerDbObjet: ({ idDbObjet = null } = {}) =>
        api.post("api/generiques/stored-procedure/SupprimerDbObjet", {
            idDbObjet
        }),
    getObjetsDbObjet: ({ idDbObjet = null } = {}) => api.post("api/generiques/stored-procedure/GetObjetsDbObjet", { idDbObjet }).then((r) => r.data[0]),
    ajouterObjetDbObjet: ({ idDbObjet = null, idObjet = null, droitRequis = null } = {}) =>
        api
            .post("api/generiques/stored-procedure/AjouterObjetDbObjet", {
                idDbObjet,
                idObjet,
                droitRequis
            })
            .then((r) => r.data[0][0].idObjetDbObjet),
    modifierObjetDbObjet: ({ idObjetDbObjet = null, droitRequis = null } = {}) =>
        api.post("api/generiques/stored-procedure/ModifierObjetDbObjet", {
            idObjetDbObjet,
            droitRequis
        }),
    supprimerObjetDbObjet: ({ idObjetDbObjet = null } = {}) =>
        api.post("api/generiques/stored-procedure/SupprimerObjetDbObjet", {
            idObjetDbObjet
        }),
    getSyncDbObjets: () =>
        api.post("api/generiques/stored-procedure/GetSyncDbObjets").then((r) =>
            r.data[0].map((x) => ({
                ...x,
                rolesAAjouter: JSON.parse(x.rolesAAjouter),
                rolesASupprimer: JSON.parse(x.rolesASupprimer),
                usagersAAjouter: JSON.parse(x.usagersAAjouter),
                usagersASupprimer: JSON.parse(x.usagersASupprimer)
            }))
        ),
    syncDbObjets: () => api.post("api/generiques/stored-procedure/SyncDbObjets")
});
