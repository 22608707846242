<template>
    <q-expansion-item v-if="menu.sousMenu" :disable="estVendeurBloque" expand-separator :label="menu.nom" :content-inset-level="0.5" dense>
        <e-side-menu-item v-for="menu in menu.sousMenu" :key="menu.id" :menu="menu" />
    </q-expansion-item>
    <q-item v-else :disable="estVendeurBloque" clickable @click="link(menu)" dense>
        <q-item-section>
            {{ menu.nom }}
        </q-item-section>
    </q-item>
</template>

<script>
import { mapState, mapWritableState } from "pinia";

import { useAuthStore, useHeaderStore } from "@/stores";

export default {
    name: "e-side-menu-item",
    props: {
        menu: Object
    },
    computed: {
        ...mapState(useAuthStore, ["estVendeurBloque"]),
        ...mapWritableState(useHeaderStore, ["sideMenu"])
    },
    methods: {
        link(menu) {
            if (!menu.sousMenu) {
                if (menu.url2) {
                    this.$router.push(menu.url2);
                } else {
                    this.$router.push({
                        name: "AncienneErp",
                        params: this.$utils.getParamsAncienneErp({
                            url: menu.url
                        })
                    });
                }

                this.sideMenu = false;
            }
        }
    }
};
</script>
