<template>
	<q-layout>
		<q-page-container>
			<q-page class="bg-grey-3 row">
				<q-card class="fixed-center col-xs-11 col-sm-5">
					<q-toolbar class="bg-primary text-white">
						<q-avatar>
							<img src="../../assets/logo.png" />
						</q-avatar>
						<q-toolbar-title>Authentification</q-toolbar-title>
					</q-toolbar>
					<q-card-section>
						<q-form @submit="login">
							<q-input v-model="usager.usager" class="q-mb-md" placeholder="Nom d'utilisateur" autocomplete="off" :disable="isLoading" :error="!!erreur" autofocus outlined dense hide-bottom-space no-error-icon>
								<template v-slot:prepend>
									<q-icon name="person" />
								</template>
							</q-input>
							<q-input v-model="usager.motDePasse" type="password" class="q-mb-md" placeholder="Mot de passe" autocomplete="off" :disable="isLoading" :error="!!erreur" outlined dense hide-bottom-space no-error-icon>
								<template v-slot:prepend>
									<q-icon name="lock" />
								</template>
							</q-input>
							<div v-if="!!erreur" class="q-mb-md text-center text-red">
								{{ erreur }}
							</div>
							<q-btn type="submit" color="primary" label="Connexion" class="full-width" :disable="!usager.usager.trim() || !usager.motDePasse" :loading="isLoading" />

							<q-btn color="primary" label="Ancien ERP" href="https://erplaprise.com?ancien-erp=true" flat padding="none" class="float-right q-mt-sm text-caption text-underline" />
						</q-form>
					</q-card-section>
				</q-card>
			</q-page>
		</q-page-container>
	</q-layout>
</template>

<script>
export default {
	name: "Login",
	data() {
		return {
			usager: {
				usager: "",
				motDePasse: ""
			},
			isLoading: false,
			erreur: null
		};
	},
	methods: {
		async login() {
			this.isLoading = true;
			this.erreur = null;

			try {
				await this.$api.auth.login(this.usager);
				this.$router.push("/home");
			} catch (error) {
				this.erreur = "Nom d'utilisateur ou mot de passe invalide.";
				this.usager.motDePasse = "";
				this.isLoading = false;
			}
		}
	}
};
</script>
