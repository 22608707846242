<template>
    <q-item clickable>
        <q-item-section avatar>
            <q-icon name="file_present" size="sm" />
        </q-item-section>
        <q-item-section>
            <q-item-label>{{ fichier.titre }}</q-item-label>
            <q-item-label v-if="fichier.caption" caption>{{ fichier.caption }}</q-item-label>
        </q-item-section>
        <q-item-section side>
            <div class="row items-center q-col-gutter-x-sm">
                <div v-if="peutSupprimer" class="col-auto">
                    <q-btn
                        icon="delete"
                        color="red"
                        padding="none"
                        :loading="isLoadingSupprimer"
                        :disable="isLoadingTelecharger || isLoadingVisualiser"
                        @click="supprimer"
                        flat
                        round
                        dense
                    />
                </div>
                <div class="col-auto">
                    <q-btn icon="download" padding="none" :loading="isLoadingTelecharger" :disable="isLoadingSupprimer || isLoadingVisualiser" @click="telecharger" flat round dense />
                </div>
                <div class="col-auto">
                    <q-btn icon="visibility" padding="none" :loading="isLoadingVisualiser" :disable="isLoadingSupprimer || isLoadingTelecharger" @click="visualiser" flat round dense />
                </div>
            </div>
        </q-item-section>
    </q-item>
</template>

<script>
export default {
    name: "e-fichier-item",
    props: {
        fichier: Object,
        fctSupprimer: Function
    },
    emits: ["onFichierSupprime"],
    data() {
        return {
            isLoadingSupprimer: false,
            isLoadingTelecharger: false,
            isLoadingVisualiser: false
        };
    },
    computed: {
        peutSupprimer() {
            return typeof this.fctSupprimer === "function";
        }
    },
    methods: {
        async supprimer() {
            this.$q
                .dialog({
                    title: "Confirmation",
                    message: "Voulez-vous vraiment supprimer ce fichier?",
                    cancel: true,
                    persistent: true
                })
                .onOk(async () => {
                    this.isLoadingSupprimer = true;

                    try {
                        await this.fctSupprimer(this.fichier);
                        this.$emit("onFichierSupprime");
                        this.$q.notify({ type: "positive", message: "Fichier supprimé avec succès." });
                    } catch (error) {
                        this.$q.notify({ type: "negative", message: "Une erreur est survenue lors de la suppression du fichier." });
                    } finally {
                        this.isLoadingSupprimer = false;
                    }
                });
        },
        async telecharger() {
            this.isLoadingTelecharger = true;

            try {
                await this.$api.fichiers.download({ idFichier: this.fichier.id });
                this.$q.notify({ type: "positive", message: "Fichier téléchargé avec succès." });
            } catch (error) {
                this.$q.notify({ type: "negative", message: "Une erreur est survenue lors du téléchargement du fichier." });
            } finally {
                this.isLoadingTelecharger = false;
            }
        },
        async visualiser() {
            this.isLoadingVisualiser = true;

            try {
                await this.$api.fichiers.view(this.fichier.id);
            } catch (error) {
                this.$q.notify({ type: "negative", message: "Une erreur est survenue lors de la visualisation du fichier." });
            } finally {
                this.isLoadingVisualiser = false;
            }
        }
    }
};
</script>

<style scoped>
.q-item__label + .q-item__label {
    margin-top: 0;
}
.q-item__section--avatar {
    min-width: inherit;
}
</style>
