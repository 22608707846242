<template>
    <q-popup-proxy ref="popup" transition-show="scale" transition-hide="scale">
        <q-date v-bind="$attrs" :model-value="modelValue" @update:model-value="setModel" />
    </q-popup-proxy>
</template>

<script>
export default {
    name: "e-popup-date",
    props: {
        modelValue: String
    },
    emits: ["update:modelValue"],
    methods: {
        setModel(value) {
            this.$emit("update:modelValue", value);
            this.$refs.popup.hide();
        }
    }
};
</script>
