<template>
    <div class="ec-calendar">
        <q-resize-observer @resize="onCalendarResize" :debounce="0" />
        <div class="row items-center bg-blue-grey-1 ec-header">
            <q-resize-observer @resize="onHeaderResize" />
            <div class="col-auto q-px-xs">
                <q-btn icon="chevron_left" padding="none" :disable="isLoading" @click="previousMonth" flat round />
            </div>
            <div class="col row justify-center q-gutter-x-xs">
                <q-select v-model="selectedMonth" :options="months" :disable="isLoading" @update:model-value="dateRangeChanged" map-options emit-value borderless options-dense dense />
                <q-select v-model="selectedYear" :options="years" :disable="isLoading" @update:model-value="dateRangeChanged" map-options emit-value borderless options-dense dense />
            </div>
            <div class="col-auto q-px-xs">
                <q-btn icon="chevron_right" padding="none" :disable="isLoading" @click="nextMonth" flat round />
            </div>
        </div>
        <div class="row ec-week-header bg-grey-1 text-grey-6 text-bold" style="overflow-y: scroll">
            <q-resize-observer @resize="onWeekResize" />
            <div class="col q-pa-xs text-center ellipsis">Dimanche</div>
            <div class="col q-pa-xs text-center ellipsis">Lundi</div>
            <div class="col q-pa-xs text-center ellipsis">Mardi</div>
            <div class="col q-pa-xs text-center ellipsis">Mercredi</div>
            <div class="col q-pa-xs text-center ellipsis">Jeudi</div>
            <div class="col q-pa-xs text-center ellipsis">Vendredi</div>
            <div class="col q-pa-xs text-center ellipsis">Samedi</div>
            <div v-if="$slots['week-number']" class="col q-pa-xs text-center ellipsis"></div>
        </div>
        <div style="overflow-y: scroll" class="relative-position" :style="{ height: weeksHeight }">
            <div v-if="isLoading" class="absolute-center text-center">
                <q-spinner color="grey-7" size="lg" />
            </div>
            <template v-else>
                <div v-for="(week, w) in weeks" :key="w" class="row ec-week">
                    <div
                        v-for="(day, d) in week"
                        :key="d"
                        :class="{ 'bg-grey-1': day.date.getDay() === 0 || day.date.getDay() === 6 || holidays[day.formattedDate] }"
                        :style="{ 'min-height': weekMinHeight }"
                        class="col q-pa-xs"
                    >
                        <div class="column no-wrap full-height">
                            <div class="col-auto row q-col-gutter-x-sm q-mb-xs" :class="{ 'text-grey': day.date.getMonth() !== selectedMonth }">
                                <div class="col ellipsis" :title="holidays[day.formattedDate]">
                                    {{ holidays[day.formattedDate] }}
                                </div>
                                <div class="col-auto">{{ day.date.getDate() }}</div>
                            </div>
                            <div class="col">
                                <slot name="day" :date="day.date" :formatted-date="day.formattedDate"></slot>
                            </div>
                        </div>
                    </div>
                    <div v-if="$slots['week-number'] && week.length" :style="{ 'min-height': weekMinHeight }" class="col q-pa-xs">
                        <slot v-if="week.length" name="week-number" :week-number="getWeekNumber(week[0].date)" :start="week[0].date" :end="week[week.length - 1].date"></slot>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import { date } from "quasar";

export default {
    name: "e-calendar",
    props: {
        loading: Boolean
    },
    emits: ["dateRangeChanged"],
    data() {
        return {
            heights: {
                calendar: 0,
                header: 0,
                week: 0
            },
            isLoadingHolidays: false,
            holidays: {},
            selectedYear: new Date().getFullYear(),
            months: [
                { value: 0, label: "Janvier" },
                { value: 1, label: "Février" },
                { value: 2, label: "Mars" },
                { value: 3, label: "Avril" },
                { value: 4, label: "Mai" },
                { value: 5, label: "Juin" },
                { value: 6, label: "Juillet" },
                { value: 7, label: "Août" },
                { value: 8, label: "Septembre" },
                { value: 9, label: "Octobre" },
                { value: 10, label: "Novembre" },
                { value: 11, label: "Décembre" }
            ],
            selectedMonth: new Date().getMonth()
        };
    },
    computed: {
        isLoading() {
            return this.isLoadingHolidays || this.loading;
        },
        years() {
            const years = [];
            for (let i = this.selectedYear - 15; i <= this.selectedYear + 15; i++) {
                years.push({ value: i, label: i.toString() });
            }
            return years;
        },
        dateRange() {
            const start = new Date(this.selectedYear, this.selectedMonth, 1),
                end = new Date(this.selectedYear, this.selectedMonth + 1, 0);

            while (start.getDay() > 0) {
                start.setDate(start.getDate() - 1);
            }

            while (end.getDay() < 6) {
                end.setDate(end.getDate() + 1);
            }

            end.setHours(23, 59, 59, 0);

            return {
                start,
                end
            };
        },
        days() {
            const days = [];

            for (let i = new Date(this.dateRange.start); i <= this.dateRange.end; i.setDate(i.getDate() + 1)) {
                const day = new Date(i);

                days.push({
                    date: day,
                    formattedDate: date.formatDate(day, "YYYY-MM-DD")
                });
            }

            return days;
        },
        weeks() {
            return this.days.reduce((all, one, i) => {
                const ch = Math.floor(i / 7);
                all[ch] = [].concat(all[ch] || [], one);
                return all;
            }, []);
        },
        weeksHeight() {
            return `${this.heights.calendar - (this.heights.header + this.heights.week) - 2}px`;
        },
        weekMinHeight() {
            return `${(this.heights.calendar - (this.heights.header + this.heights.week)) / this.weeks.length - 1.25}px`;
        }
    },
    methods: {
        onCalendarResize(size) {
            this.heights.calendar = size.height;
        },
        onHeaderResize(size) {
            this.heights.header = size.height;
        },
        onWeekResize(size) {
            this.heights.week = size.height;
        },
        previousMonth() {
            if (this.selectedMonth > 0) {
                this.selectedMonth--;
            } else {
                this.selectedYear--;
                this.selectedMonth = 11;
            }

            this.dateRangeChanged();
        },
        nextMonth() {
            if (this.selectedMonth < 11) {
                this.selectedMonth++;
            } else {
                this.selectedYear++;
                this.selectedMonth = 0;
            }

            this.dateRangeChanged();
        },
        dateRangeChanged() {
            this.$emit("dateRangeChanged", { range: this.dateRange, month: this.selectedMonth });
        },
        getWeekNumber(date) {
            const yearStart = +new Date(date.getFullYear(), 0, 1),
                today = +new Date(date.getFullYear(), date.getMonth(), date.getDate()),
                dayOfYear = (today - yearStart + 1) / 86400000;

            return Math.ceil(dayOfYear / 7);
        },
        navigate(year, month) {
            const parsedYear = parseInt(year),
                parsedMonth = parseInt(month);

            let raiseEvent = false;

            if (!isNaN(parsedYear) && parsedYear !== this.selectedYear) {
                this.selectedYear = parsedYear;
                raiseEvent = true;
            }

            if (!isNaN(parsedMonth) && parsedMonth >= 0 && parsedMonth <= 11 && parsedMonth !== this.selectedMonth) {
                this.selectedMonth = parsedMonth;
                raiseEvent = true;
            }

            if (raiseEvent) {
                this.dateRangeChanged();
            }

            return raiseEvent;
        }
    },
    async mounted() {
        this.dateRangeChanged();

        this.isLoadingHolidays = true;

        try {
            const data = await this.$api.misc.getJoursFeries();
            this.holidays = data.reduce((o, x) => ({ ...o, [x.jour]: x.nom }), {});
        } catch (error) {
            this.$q.notify({ type: "negative", message: "Une erreur est survenue lors du chargement des jours fériés." });
        } finally {
            this.isLoadingHolidays = false;
        }
    }
};
</script>

<style scoped>
.ec-calendar {
    border: 1px solid rgba(0, 0, 0, 0.12);
}
.ec-header {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.ec-calendar .ec-week-header:not(:last-child),
.ec-calendar .ec-week:not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.ec-calendar .ec-week > .col:not(:last-child) {
    border-right: 1px solid rgba(0, 0, 0, 0.12);
}
</style>
