import axios from "axios";

export default (api) => ({
	login: async ({ usager = null, motDePasse = null } = {}) => {
		const response = await axios.post(`${process.env.VUE_APP_ANCIENNE_ERP_URL}/ajax_v2/WsAuthentification.aspx/Login`, { usager, motDePasse }, { withCredentials: true });
		if (response.data.d.erreur) {
			throw Error(response.data.d.erreur);
		}

		await api.post("api/authentification/login", { usager, motDePasse });
	},
	changerCompagnie: async (idUsager) => {
		await api.post("api/authentification/changer-compagnie", { idUsager });

		const response = await axios.post(`${process.env.VUE_APP_ANCIENNE_ERP_URL}/ajax_v2/WsAuthentification.aspx/ChangerCompagnie`, { idUsager }, { withCredentials: true });
		if (response.data.d.erreur) {
			throw Error(response.data.d.erreur);
		}
	},
	logout: async () => {
		await api.post("api/authentification/logout");

		const response = await axios.post(`${process.env.VUE_APP_ANCIENNE_ERP_URL}/ajax_v2/WsAuthentification.aspx/Logout`, {}, { withCredentials: true });
		if (response.data.d.erreur) {
			throw Error(response.data.d.erreur);
		}
	},
	validerSession: async () => {
		const response = await axios.post(`${process.env.VUE_APP_ANCIENNE_ERP_URL}/ajax_v2/WsAuthentification.aspx/ValiderSession`, {}, { withCredentials: true });
		if (response.data.d.erreur) {
			throw Error(response.data.d.erreur);
		}

		return response.data.d.valide;
	},
	getInfosUsager: () => api.post("api/generiques/stored-procedure/GetInfosUsager").then((response) => response.data[0][0]),
	getUsagerCompagnies: () => api.post("api/generiques/stored-procedure/GetUsagerCompagnies").then((r) => r.data[0]),
	getUsagerMenu: () => api.post("api/generiques/scalar-function/GetMenuUsager").then((r) => JSON.parse(r.data)),
	getDroitsUsager: () => api.post("api/generiques/stored-procedure/GetDroitsUsager").then((r) => r.data[0]),
	getProceduresAccueils: () => api.post("api/generiques/stored-procedure/VerifierProceduresAccueilV2").then((r) => r.data[0][0].component)
});
