<template>
    <q-table
        ref="notes"
        :rows="notes"
        :columns="columns"
        :rows-per-page-options="[0]"
        color="primary"
        class="full-height"
        row-key="id"
        :virtual-scroll-sticky-size-start="28"
        virtual-scroll
        hide-header
        wrap-cells
        dense
        flat
    >
        <template v-slot:top>
            <span class="text-h6">{{ titre }}</span>
            <q-badge v-if="notes.length" class="q-ml-sm" rounded color="red">{{ notes.length }}</q-badge>
            <q-space />
            <div class="col-auto">
                <q-btn icon="close" @click="$emit('close')" flat round dense />
            </div>
            <q-separator />
        </template>
        <template v-slot:body-cell-note="props">
            <q-td :props="props">
                <div class="row items-center">
                    <span class="text-bold q-mr-sm">{{ props.row.usager }}</span>
                    <span class="text-grey text-caption">{{ props.row.date }}</span>
                </div>
                <div class="text-small full-width" style="white-space: pre-line; word-wrap: break-word">
                    {{ props.row.note }}
                </div>
            </q-td>
        </template>
        <template v-if="!disableAjouter" v-slot:[slotName]>
            <div class="full-width">
                <q-input
                    v-model="note"
                    type="textarea"
                    placeholder="Ajouter une note"
                    :input-style="{ 'max-height': '72px' }"
                    @change="note = note.trim()"
                    :disable="isLoadingAjouter"
                    autogrow
                    dense
                    borderless
                >
                    <template v-slot:append>
                        <q-btn icon="send" :loading="isLoadingAjouter" :disable="!note.trim()" @click="ajouterNote" flat round dense />
                    </template>
                </q-input>
            </div>
        </template>
    </q-table>
</template>
<script>
export default {
    name: "e-liste-notes",
    emits: ["close"],
    props: {
        titre: String,
        notes: Array,
        fnAjouter: Function,
        fnSupprimer: Function,
        disableAjouter: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            columns: [
                {
                    name: "note",
                    field: "note",
                    align: "left",
                    headerStyle: "width: 100%"
                }
            ],
            note: "",
            isLoadingAjouter: false,
            idNoteSupprimer: null
        };
    },
    computed: {
        slotName() {
            return this.notes.length ? "bottom" : "no-data";
        }
    },
    methods: {
        async ajouterNote() {
            this.isLoadingAjouter = true;

            try {
                await this.fnAjouter(this.note);

                this.note = "";
                this.$refs.notes.scrollTo(this.notes.length - 1);
            } catch (error) {
                this.$utils.catch({ error, message: "Une erreur est survenue lors de l'ajout de la note." });
            } finally {
                this.isLoadingAjouter = false;
            }
        },
        async supprimerNote(idNote) {
            this.idNoteSupprimer = idNote;

            try {
                await this.fnSupprimer(idNote);
                this.$q.notify({ type: "positive", message: "La note a été supprimée avec succès" });
            } catch (error) {
                this.$utils.catch({ error, message: "Une erreur est survenue lors de la suppression de la note." });
            } finally {
                this.idNoteSupprimer = null;
            }
        }
    },
    mounted() {
        this.$refs.notes.scrollTo(0, "top");
    }
};
</script>
<style scoped>
.q-table__container :deep(table) {
    table-layout: fixed;
}
.q-table__container :deep(.q-table__top) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
</style>
