export default (api) => ({
    getModeles: () => api.post("api/generiques/stored-procedure/GetModelesV2").then((r) => r.data[0]),
    modifierModeleSeriePlan: ({ idModele = null, idSeriePlan = null } = {}) => api.post("api/generiques/stored-procedure/ModifierModeleSeriePlan", { idModele, idSeriePlan }),
    desactiverModele: ({ idModele = null } = {}) => api.post("api/generiques/stored-procedure/DesactiverModele", { idModele }),
    getRegionsEstimationsCompagnieUsager: () => api.post("api/generiques/stored-procedure/GetRegionsEstimationsCompagnieUsager").then((r) => r.data[0]),
    getModeleTableauCouts: ({ idModele = null, idRegionEstimation = null } = {}) =>
        api.post("api/generiques/stored-procedure/GetModeleTableauCouts", { idModele, idRegionEstimation }).then((r) => r.data[0]),
    getModelePlans: ({ idModele = null, idFichierPlanModele = null } = {}) =>
        api.post("api/generiques/stored-procedure/GetModelePlans", { idModele, idFichierPlanModele }).then((r) => r.data[0]),
    getTypesFichiersPlansModeles: () => api.post("api/generiques/stored-procedure/GetTypesFichiersPlansModelesV2").then((r) => r.data[0]),
    supprimerPlanModele: ({ idFichierPlanModele = null } = {}) => api.post("api/generiques/stored-procedure/SupprimerPlanModeleV2", { idFichierPlanModele }),
    ajouterPlanModele: ({ idModele = null, idFichier = null, idTypeFichierPlanModele = null, note = null } = {}) =>
        api.post("api/generiques/stored-procedure/AjouterPlanModele", { idModele, idFichier, idTypeFichierPlanModele, note }).then((r) => r.data[0][0]),
    getModeleContrats: ({ idModele = null } = {}) => api.post("api/generiques/stored-procedure/GetModeleContrats", { idModele }).then((r) => r.data[0]),
    getModeleProblemes: ({ idModele = null } = {}) =>
        api.post("api/generiques/stored-procedure/GetModeleProblemes", { idModele }).then((r) =>
            r.data[0].map((x) => ({
                ...x,
                composantes: JSON.parse(x.composantes),
                fichiers: JSON.parse(x.fichiers),
                notes: JSON.parse(x.notes)
            }))
        ),
    getOriginesPlans: ({ idCompagnie = null } = {}) => api.post("api/generiques/stored-procedure/getOriginesPlans", { idCompagnie }).then((r) => r.data[0]),
    getTypesPlans: ({ idCompagnie = null } = {}) => api.post("api/generiques/stored-procedure/getTypesPlans", { idCompagnie }).then((r) => r.data[0]),
    ajouterModele: ({ idCompagnie = null, numero = null, origine = null, idSeriePlan = null, idTypeContrat = null, typeModele = null, idProjetTcost = null } = {}) =>
        api.post("api/generiques/stored-procedure/AjoutModele", { idCompagnie, numero, origine, idSeriePlan, idTypeContrat, typeModele, idProjetTcost }).then((r) => r.data[0][0].Id)
});
