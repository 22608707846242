<template>
    <q-dialog ref="dialog" @hide="onDialogHide">
        <q-card class="q-dialog-plugin">
            <q-card-section>
                <div class="text-h6">
                    {{ title }}
                </div>
            </q-card-section>
            <q-card-section>
                <q-select 
                    v-model="select.selected"
                    :label="select.label"
                    :options="select.options"
                    :loading="isLoading"
                    :option-label="optionLabel"
                    :option-value="optionValue"
                    :clearable="!required"
                    options-dense
                    dense
                />
            </q-card-section>
            <q-card-actions align="right">
                <q-btn color="primary" label="Annuler" @click="onCancelClick" :disable="isLoading" flat />
                <q-btn color="primary" label="OK" @click="onOKClick" :disable="isLoading || (required && !select.selected)" flat />
            </q-card-actions>
        </q-card>
    </q-dialog>
</template>

<script>
export default {
    name: "e-dialog-select",
    props: {
        title: {
            type: String,
            default: "Sélectionnez une valeur"
        },
        selectedValue: null,
        label: {
            type: String,
            default: ""
        },
        options: Array,
        optionLabel: {
            type: String,
            default: "label"
        },
        optionValue: {
            type: String,
            default: "value"
        },
        required: {
            type: Boolean,
            default: true
        },
        fnGet: Function
    },
    emits: ["ok", "hide"],
    data() {
        return {
            isLoading: false,
            select: {
                selected: null,
                label: "",
                options: []
            }
        };
    },
    methods: {
        show() {
            this.$refs.dialog.show();
        },
        hide() {
            this.$refs.dialog.hide();
        },
        onDialogHide() {
            this.$emit("hide");
        },
        onOKClick() {
            this.$emit("ok", this.select.selected);
            this.hide();
        },
        onCancelClick() {
            this.hide();
        }
    },
    async mounted() {
        this.select.label = this.label;
        this.select.options = this.options || [];

        if (typeof this.fnGet === "function") {
            this.isLoading = true;

			try {
                const options = await this.fnGet();
                this.select.options.push(...options);
			} catch (error) {
				this.$q.notify({ type: "negative", message: "Une erreur est survenue lors du chargement des options." });
			} finally {
				this.isLoading = false;
			}
        }

        this.select.selected = this.select.options.find(x => x[this.optionValue] === this.selectedValue);
    }
};
</script>
